import { FormEvent, useState, useEffect } from "react";

import "flatpickr/dist/flatpickr.min.css"
import DatePicker from "../ui/DatePicker";

import axios from "axios";
import FormList from "./FormList";
import SelectInput from "../ui/SelectInput";
import InputField from "../ui/InputField";
import Checkbox from "../ui/Checkbox";
import { Button } from "../ui/Button";
import TextAreaInput from "../ui/TextAreaInput";
import TimeSelector from "../ui/TimeSelector";

import TimePickerValue from "../ui/TimePickerValue";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';


export const Form = (props) => {
  const [selectedValues, setSelectedValues] = useState({
    StartDate: "",
    StartTime:"",
    EndDate:"",
    EndTime:"",
    FieldReferenceName:"",
    TypeID: "",
    FieldDisplayName: "",
    selectListLabel: "",
    selectListValue:"",
    referenceTableName:"",
    Archive: "N",
  });

  const getCurrentDate = () => {
    const todayDate = new Date();
    const year = todayDate.getFullYear(); // Évszám
    const month = todayDate.getMonth() + 1; // Hónap (0-tól indexelődik, ezért +1)
    const day = todayDate.getDate(); // Nap
    // Formázás: "év-hónap-nap" (pl. "2023-10-06")
    const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${
      day < 10 ? "0" : ""
    }${day}`;
    return formattedDate;
  };


  const [startDate, setStartDate] = useState(getCurrentDate());
  const [endDate, setEndDate] = useState(getCurrentDate());


  const [startTime,setStartTime] = useState(dayjs());
  const [endTime,setEndTime] = useState(dayjs());

  console.log("startDate",startDate)
  const [reportList, setReportList] = useState([]);

  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);

  const sortReports = (reports) => {
    const notArchiveReports = reports
      .filter((report) => report.Archive === "N")
      .sort(
        (a, b) =>
          new Date(a.StartDate).getTime() - new Date(b.StartDate).getTime()
      );

    const ArchiveReports = reports
      .filter((report) => report.Archive === "Y")
      .sort(
        (a, b) =>
          new Date(a.StartDate).getTime() - new Date(b.StartDate).getTime()
      );

    console.log("Teszt lista", [...ArchiveReports, ...notArchiveReports]);
    return [...ArchiveReports, ...notArchiveReports];
  };

  const getReports = async () => {
    try {
      const requestData = {
        tables: [
          "field",
          "field_type",
         
        ],
        joins: [
          "field.TypeID = field_type.TypeID",
        ],
        conditions: ["field.Archive = 'N'"],
        selectedColumns: [
          "field.*",
          "field_type.*",
        ],
      };

  
      console.log("requestData",requestData)
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getJoinedData`,
        requestData
      );
      console.log("extendedResult", response.data); // Itt kezelheti a választ
      // setReportList(response.data);
      setReportList(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const updateObject = async () => {
        const sTime = startTime.hour()+":"+ startTime.minute()
    const eTime = endTime.hour()+":"+ endTime.minute()
    console.log("teszt adatok",startDate,sTime)
    const StartDate = formatDate(startDate, sTime);
    const EndDate = formatDate(endDate, eTime);
    
    const updateData = {
      tableName: "field",
      updates: {
    
        FieldReferenceName: selectedValues.FieldReferenceName,
        FieldDisplayName: selectedValues.FieldDisplayName,
        selectListLabel: selectedValues?.selectListLabel,
        selectListValue:selectedValues?.selectListValue,
        referenceTableName: selectedValues.referenceTableName,
        
      },
      whereClause: {
        condition: `FieldId = ${selectedValues?.FieldId}`,
      },
    };
    console.log("Information data", updateData);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/updateTable`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updateData),
        }
      );

      if (response.ok) {
        console.log("Status change successfully.");
      } else {
        console.error("Error changing status:", response.statusText);
      }
    } catch (error) {
      console.error("Error changing status:", error);
    }
    getReports();
  };

  const handleCopyObject = async () => {
 


    const insertObject = {
     
      FieldReferenceName:selectedValues.FieldReferenceName,
      referenceTableName: selectedValues.referenceTableName,
      selectListLabel: selectedValues.selectListLabel,
      selectListValue: selectedValues.selectListValue,
      FieldDisplayName: selectedValues.FieldDisplayName,
      TypeID: selectedValues.TypeID,
    };

    console.log("insertObject", insertObject);
    console.log("selectedValues", selectedValues);
    try {
      // Az adatok, amiket elküldünk a Node.js szervernek

      const tableName = "field";

      // Fetch hívás a Node.js szerver felé
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/addItem`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ tableName, data: insertObject }), // Az adatok JSON formátumban elküldve
        }
      );

      if (response.ok) {
        const result = await response.json(); // A szerver válaszának feldolgozása
        console.log("result", result);
        //  setData(result);
      } else {
        console.error("Hiba történt a szerverrel való kommunikáció során.");
      }
    } catch (error) {
      console.error("Hiba történt:", error);
    }

    getReports();
  };


  const handleInsertObject = async () => {
 
    const insertObject = {

      FieldReferenceName: selectedValues.FieldReferenceName,
      selectListLabel: selectedValues?.selectListLabel,
      selectListValue: selectedValues?.selectListValue,
      FieldDisplayName: selectedValues.FieldDisplayName,
      referenceTableName: selectedValues.referenceTableName,
      TypeID: selectedValues.TypeID,
     Archive: "N",
   
    };
  
    console.log("new insert object", insertObject);
  
    try {
      const tableName = "field";
      const response = await fetch(`${process.env.REACT_APP_API_URL}/addItem`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ tableName, data: insertObject }),
      });
  
      if (response.ok) {
        const result = await response.json();
        console.log("result", result);
      } else {
        console.error("Hiba történt a szerverrel való kommunikáció során.");
      }
    } catch (error) {
      console.error("Hiba történt:", error);
    }
  
    getReports();
  };

  
  dayjs.extend(utc);

  const formatDate = (date, time) => {
    const [year, month, day] = date.split('-');
    const [hour, minute] = time.split(':');
  
    const formattedDate = dayjs()
      .year(year)
      .month(month - 1)
      .date(day)
      .hour(hour)
      .minute(minute)
      .format(); // Most a helyi időzóna és formátum lesz alkalmazva
  
    return formattedDate;
  };


  const handleRemoveElement = async () => {
    const updateData = {
      tableName: "field",
      updates: {
        Archive: 'Y'
      },
      whereClause: {
        condition: `FieldId = ${selectedValues?.FieldId}`,
      },
    };
    console.log("Information data", updateData);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/updateTable`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updateData),
        }
      );

      if (response.ok) {
        console.log("Status change successfully.");
      } else {
        console.error("Error changing status:", response.statusText);
      }
    } catch (error) {
      console.error("Error changing status:", error);
    }

    getReports();
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getReports().then(() => {
      setIsLoading(false); // Az adatok betöltése befejeződött
    });
  }, []);

 
  const handleSelect = (selectedOption, name) => {
    setSelectedValues({
      ...selectedValues,
      [name]: selectedOption.value,
    });
  };

  console.log("props.setSelectedValues", selectedValues);

  const onSubmit = (e) => {
    e.preventDefault();
  };

  //const [selectedDate, setSelectedDate] = useState(new Date());



  const formFields = [

    {
        Fieldtype: "input",
        id: "FieldDisplayName",
        label: "Megjelenítési név",
        type: "text",
        disabled: viewField,
        value: selectedValues.FieldDisplayName,
       
        onChange: (e) => {
          setSelectedValues({ ...selectedValues, FieldDisplayName: e.target.value });
          //setItemNameValid(e.target.validity.valid)
        },
      },
    
    {
      Fieldtype: "input",
      id: "FieldReferenceName",
      label: "Hivatkozott value",
      type: "text",
      disabled: viewField,
      value: selectedValues.FieldReferenceName,
     
      onChange: (e) => {
        setSelectedValues({ ...selectedValues, FieldReferenceName: e.target.value });
        //setItemNameValid(e.target.validity.valid)
      },
    },

    {
      Fieldtype: "select",
      id: "TypeID",
      label: "Típus",
      value: selectedValues.TypeID,
      disabled: viewField,
      table: "field_type",
      listType: { id: "TypeID", name: "TypaName" },
    },
  
    {
        Fieldtype: "input",
        id: "referenceTableName",
        label: "Referencia tábla neve",
        type: "text",
        min: 1,
        max: 30,
        disabled: viewField,
        value: selectedValues.referenceTableName?selectedValues.referenceTableName:"-",
        onChange: (e) => {
          setSelectedValues({
            ...selectedValues,
            referenceTableName: e.target.value,
          });
          //setItemNameValid(e.target.validity.valid)
        },
      },
      {
        Fieldtype: "input",
        id: "selectListValue",
        label: "SelectList value",
        type: "text",
        min: 1,
        max: 100,
        disabled: viewField,
        value: selectedValues.selectListValue?selectedValues.selectListValue:"-" ,
       
        onChange: (e) => {
          setSelectedValues({ ...selectedValues, selectListValue: e.target.value });
          //setItemNameValid(e.target.validity.valid)
        },
      },
    {
        Fieldtype: "input",
        id: "selectListLabel",
        label: "SelectList label",
        type: "text",
        min: 1,
        max: 100,
        disabled: viewField,
        value: selectedValues.selectListLabel,
       
        onChange: (e) => {
          setSelectedValues({ ...selectedValues, selectListLabel: e.target.value });
          //setItemNameValid(e.target.validity.valid)
        },
      },

   
    
 

 
  ];

  return (
    <div className="fixed top-16 inset-0 grid grid-cols-5 grid-rows-[auto_1fr] gap-4 bg-gray-100 p-4">
    <div className="col-span-2 w-full overflow-x-auto">
      <form className="flex flex-col h-full overflow-x-auto bg-white shadow sm:rounded-lg" onSubmit={onSubmit}>
        <div className="flex px-3 py-3">
          <Button
            type="button"
            children="Hozzáadás"
            buttonType="add"
            disabled={!viewField}
            onClick={() => {
              setViewField(false);
              setAddField(false);
              setSelectedValues({
                StartDate: getCurrentDate(),
                EndDate: getCurrentDate(),
                FieldDisplayName: "",
                FieldReferenceName:"",
                referenceTableName: "",
                selectListLabel: "",
                selectListValue:"",
                TypeID: 0,
            
                Archive: "N",
              });
            }}
          />
          <Button
            type="button"
            children="Szerkesztés"
            buttonType="edit"
            disabled={!viewField}
            onClick={() => {
              setViewField(false);
              setEditButtonvisible(false);
            }}
          />
          <Button
            type="button"
            buttonType="delete"
            children="Törlés"
            disabled={!viewField}
            onClick={() => handleRemoveElement()}
          />
          <Button
            type="button"
            buttonType="copy"
            children="Másolás"
            disabled={!viewField}
            onClick={() => handleCopyObject()}
          />
        </div>

        <div className="flex h-full flex-col gap-6 overflow-hidden border-t border-gray-200 px-3 py-9">
          <div className="grid col-span-1 md:col-span-2 lg:grid-cols-3 gap-4 xl:grid-cols-4">
        
 
  

            {formFields.map((field) => {
              if (field.Fieldtype === "select") {
                return (
                  <SelectInput
                    key={field.id}
                    onChange={(selectedOption) =>
                      handleSelect(selectedOption, field.listType.id)
                    }
                    label={field.label}
                    value={selectedValues[field.listType.id]} // Javítva: selectedValues[field.id]
                    disabled={field.disabled}
                    id={field.id}
                    table={field.table}
                    listType={field.listType}
                  />
                );
              } else if (field.Fieldtype === "checkbox") {
                return (
                  <Checkbox
                    key={field.id}
                    id={field.id}
                    label={field.label}
                    disabled={field.disabled}
                    value={selectedValues[field.id]} // Javítva: selectedValues[field.id]
                    onChange={field.onChange}
                    table={field.table}
                    listType={field.listType}
                  />
                );
              } else if (field.Fieldtype === "input") {
                return (
                  <InputField
                    key={field.id}
                    id={field.id}
                    label={field.label}
                    type={field.type}
                    min={field.min}
                    max={field.max}
                    disabled={field.disabled}
                    value={selectedValues[field.id]} // Javítva: selectedValues[field.id]
                    additionalselectListLabel={field.additionalselectListLabel}
                    onChange={(e) => {
                      setSelectedValues({
                        ...selectedValues,
                        [field.id]: e.target.value, // Javítva: [field.id]
                      });
                    }}
                  />
                );
              }
              else if (field.Fieldtype === "textAreaInput") {
                return (
                  <TextAreaInput
                    key={field.id}
                    id={field.id}
                    label={field.label}
                    type={field.type}
                    min={field.min}
                    max={field.max}
                    disabled={field.disabled}
                    value={selectedValues[field.id]} // Javítva: selectedValues[field.id]
                    additionalselectListLabel={field.additionalselectListLabel}
                    onChange={(e) => {
                      setSelectedValues({
                        ...selectedValues,
                        [field.id]: e.target.value, // Javítva: [field.id]
                      });
                    }}
                  />
                );
              }
              return null; // Kihagyhatod a nem támogatott mezőket
            })}
          </div>

          {!editButtonVisible && (
            <div className="flex justify-center gap-3">
              <Button
                type="button"
                children="Rögzítés"
                buttonType="add"
                onClick={() => {
                  setViewField(true);
                  setEditButtonvisible(true);
                  updateObject();
                }}
              />

              <Button
                type="button"
                children="Mégse"
                buttonType="delete"
                onClick={() => {
                  setViewField(true);
                  setEditButtonvisible(true);
                }}
              />
            </div>
          )}

          {!addField && (
            <div className="flex justify-center gap-3">
              <Button
                type="button"
                children="Rögzítés"
                buttonType="add"
                onClick={() => {
                  setViewField(true);
                  setAddField(true);
                  handleInsertObject();
                }}
              />

              <Button
                type="button"
                children="Mégse"
                buttonType="delete"
                onClick={() => {
                  setViewField(true);
                  setAddField(true);
                }}
              />
            </div>
          )}

          
        </div>
      </form>
    
      </div>
  
  
      {isLoading ? (
        <p>Loading...</p> // Betöltési üzenet, amíg az adatok nem érkeznek meg
      ) : (
        <FormList
          reports={reportList}
          setReportList={setReportList}
          setSelectedValues={setSelectedValues}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setStartTime={setStartTime}
          setEndTime={setEndTime}
          viewField={viewField}
        />
      )}
 
</div>
  );
};
