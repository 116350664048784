import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
export default function Auth() {

  const telephelyList = [
    {id:1,name:"Telephely 1"},
    {id:2,name:"Telephely 2"},
  ]
   
  const [showMessage,setShowMessage]=useState(false);
  const [loginData, setLoginData] = useState({
    userName: "",
    password: "",
  });
      
  useEffect(()=>{
    console.log("useAuth lefut");
    localStorage.setItem("loginStatus",false)

  },[])

  const navigate = useNavigate();
  
  const login = async (event) => {
    event.preventDefault();
    if(loginData.userName.length<1 || loginData.password.length<1){
      console.log("Üres adatok");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/login`,
        loginData
      );
  
      console.log("login information:", response);
      // Frissítsd a projektek állapotát, majd hívd meg az onProjectAdded függvényt
     // props.setCompany([...props.company, companyData]);
    
        if(response.data.Status==='Error'){
          setShowMessage(true);
          localStorage.setItem("loginStatus",true)
         console.log("message",response.data.Error) 
        }
        else if(response.data.Status==='success'){
         console.log("Login successfull",response.data.Data); 
         localStorage.setItem("user",JSON.stringify(response.data.Data))
         setShowMessage(false);
         localStorage.setItem("loginStatus",false)
        navigate("/app");
        }
     
      // Close the dialog
     // props.onHide();
      // Handle success
    } catch (error) {
      console.error("Error adding project:", error);
      // Handle error
    }
  
    // További kód a projekt hozzáadásához
  };


      return(
      
        <div className="flex h-screen">
        <div
          className={
            "flex flex-1 flex-col justify-center overflow-auto px-4 py-12 transition-colors duration-1000 sm:px-6 lg:flex-none lg:px-20 xl:px-24"
          }
        >
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img className="h-12 w-auto" src="/QUANTELLIGEN.png" alt="Logó" />
              <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
                {" "}
                Bejelentkezés
              </h2>
            </div>
            <div className="mt-8">
              <div className="mt-6">
                <form onSubmit={login} className="space-y-6">

                <div>
                  <label
                    htmlFor="telephely"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Telephely
                  </label>
                  <div className="mt-2">
                    <select
                      id="telephely"
                      name="telephely"
                      onChange={(e) =>
                        setLoginData({ ...loginData, telephely: e.target.value })
                      }
                      value={loginData.telephely}
                      className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    >
                      <option value="">Válassz telephelyet</option>
                      {telephelyList.map((telephely) => (
                        <option key={telephely.id} value={telephely.name}>
                          {telephely.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>


                  <div>
                    <label
                      htmlFor="userName"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Felhasználónév
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="userName"
                        id="userName"
                        value={loginData.userName}
                        required
                        onChange={(e) =>
                          setLoginData({ ...loginData, userName: e.target.value })
                        }
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="UserName"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Jelszó
                    </label>
                    <div className="mt-2">
                      <input
                        type="password"
                        name="password"
                        id="password"
                        value={loginData.password}
                        required
                        onChange={(e) =>
                          setLoginData({ ...loginData, password: e.target.value })
                        }
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
  
                  {showMessage && (
                    <p className="text-red-400"> Hibás dolgozó azonosító!</p>
                  )}
                  <button
                    type="submit"
                    className="flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:enabled:bg-indigo-500 disabled:cursor-not-allowed disabled:opacity-50"
                  >
                    Bejelentkezés
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
            alt=""
          />
        </div>
      </div>
      )

  }