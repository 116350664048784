import { useState, useEffect } from "react";
import SelectInput from "../ui/SelectInput";
import Checkbox from "../ui/Checkbox";
import TimePickerValue from "../ui/TimePickerValue";
import DatePicker from "../ui/DatePicker";
import TextAreaInput from "../ui/TextAreaInput";
import dayjs from "dayjs";
import InputField from "../ui/InputField";

export default function StatusReports(props) {

  const [reports, setRepors] = useState(props.reports);
  const [searchInput, setSearchInput] = useState("");
  const [Project, setProject] = useState(0);



  useEffect(() => {
    setRepors(props.reports);
  }, []);

  useEffect(() => {
    setRepors(props.reports);
    handleFieldChange("Project", Project);
  }, [props.reports, Project,searchInput]);
  



  const applyFilters = (reports, filters) => {
    return reports.filter((rep) => {
      for (const filter of filters) {
        const { key, value } = filter;
        const reportValue = parseInt(rep[key]);

        if (value !== 0 && reportValue !== parseInt(value)) {
          return false;
        }
      }
      return true;
    });
  };

  const getFilteredReports = (filters) => {
    const reportList = props.reports.filter((rep) =>
      rep.SelectListTitle.toLowerCase().includes(searchInput.toLowerCase())
    );

    const filteredReports = applyFilters(reportList, filters);
  
 
    setRepors(filteredReports);
   
  };


  const handleFieldChange = (fieldName, value) => {
    let newProject = Project;

    switch (fieldName) {
      case "Project":
        newProject = value;
        break;
      default:
        break;
    }
  
    getFilteredReports([
      { key: "SubMenuId", value: newProject },
    ]);
  
    setProject(newProject);
  };

  const formFields = [
  
    {
        Fieldtype: "input",
        label: "Státusz neve",
        type: "text",
        value: searchInput,
        onChange: (e) => {
          setSearchInput(e.target.value);
        },
      },
     
      {
        Fieldtype: "select",
        id: "Project",
        label: "Form",
        value: Project,
        table: "submenus",
        listType: { id: "SubMenuId", name: "SubMenuName" },
        onChange: (e) => {
            handleFieldChange("Project", e.value);
          },
      },
  ];

  return (
    <>
      <div className="col-span-3 flex flex-col overflow-hidden bg-white shadow sm:rounded-lg ">
        <div className=" col  h-screen flex-col overflow-hidden bg-white p-2 shadow sm:rounded-lg">
          <div className="flex h-full overflow-hidden border-t border-gray-200">
            <div className="w-full overflow-x-auto ">
              <div className=" sticky top-0 z-10 bg-white">
                <div>
                  <div className="grid col-span-1 md:col-span-2 lg:grid-cols-3 gap-4 xl:grid-cols-4 py-3 px-4">
                   
                    {formFields.map((field) => {
                      if (field.Fieldtype === "select") {
                        return (
                          <div className="relative basis-64 col">
                            <SelectInput
                              key={field.id}
                              onChange={field.onChange}
                              label={field.label}
                              value={field.id}
                              disabled={field.disabled}
                              id={field.id}
                              table={field.table}
                              listType={field.listType}
                            />
                          </div>
                        );
                      } else if (field.Fieldtype === "checkbox") {
                        return (
                          <Checkbox
                            key={field.id}
                            id={field.id}
                            label={field.label}
                            disabled={field.disabled}
                            value={field.id}
                            onChange={field.onChange}
                            table={field.table}
                            listType={field.listType}
                          />
                        );
                      } else if (field.Fieldtype === "timePicker") {
                        return (
                          <TimePickerValue
                            label={field.label}
                            value={field.id}
                            onChange={field.onChange}
                          />
                        );
                      } else if (field.Fieldtype === "datePicker") {
                        return (
                          <DatePicker
                            label={field.label}
                            value={field.id}
                            onChange={field.onChange}
                          />
                        );
                      } else if (field.Fieldtype === "input") {
                        return (
                          <InputField
                            key={field.id}
                            id={field.id}
                            label={field.label}
                            type={field.type}
                            min={field.min}
                            max={field.max}
                            disabled={field.disabled}
                            value={field.id}
                            additionalPreparedness={
                              field.additionalPreparedness
                            }
                            onChange={field.onChange}
                          />
                        );
                      } else if (field.Fieldtype === "textAreaInput") {
                        return (
                          <TextAreaInput
                            key={field.id}
                            id={field.id}
                            label={field.label}
                            type={field.type}
                            min={field.min}
                            max={field.max}
                            disabled={field.disabled}
                            value={field.id}
                            additionalPreparedness={
                              field.additionalPreparedness
                            }
                          />
                        );
                      }
                      return null; // Kihagyhatod a nem támogatott mezőket
                    })}
                      
                  </div>
                </div>
              </div>
              <div className="inline-block min-w-full border-b border-gray-200 align-middle">
                {
                  <>
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="sticky top-14  bg-slate-200">
                        <tr>
                       
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                          >
                            Címke
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                          >
                            Form megjelenési helye
                          </th>    
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white ">
                        {reports.map((person) => {
                          return (
                            <tr
                              key={person.email}
                              onClick={() => {
                                if (!props.viewField) return;

                                props.setSelectedValues(person);

                                console.log("person", person);
                              }}
                              className={`
                      ${
                        person.ShiftId === 4 && person !== props.selectedValues
                          ? "bg-red-100 "
                          : ""
                      }
                      ${
                        person?.StatusId ===
                        props.selectedValues?.StatusId
                          ? "cursor-help  bg-gray-200 shadow-xl shadow-gray-300"
                          : "cursor-grab"
                      }
                      ${!props.viewField ? "cursor-not-allowed opacity-50" : ""}
                  `}
                            >
 
                        
                             
                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                {person.SelectListTitle}
                              </td>
                                  
                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                {person.SubMenuName}
                              </td>

                              
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
