
import { useState } from "react"
import SelectListUpgrage from "../ui/SelectListUpgrade";
export default function TodoCard(props) {

  const [showInfo, setShowInfo] = useState(false);

  const [selectedStatusId,setSelectedStatusId]=useState(props.ItemId)

  return (
   
    <div className=" shadow-md  rounded-md">
<div className="sm:flex sm:justify-between">
 
 
</div>
<div className=" space-y-4 ">

    <div key={props.email} className="border border-gray-200 rounded-md p-4 bg-white shadow-md cursor-pointer">
      <div className="flex justify-between pb-6">
        <div className="col">
        <h2 className="text-2xl italic font-bold text-gray-800 ">{props.SimpleTaskName}</h2>
        </div>
        <div className="col">
        <button
type="button"
className="bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded-md transition duration-300 ease-in-out"
>
Enyém
</button>
        </div>
   
      </div>
    
      <p className="mt-1 text-md text-gray-700">{props.SimpleTaskTypeName}</p>
      <p className="mt-1 text-md text-gray-700">{props.ProjectName}</p>
      <div className="pt-2 flex justify-between "> 
     

<SelectListUpgrage
        key={selectedStatusId}
        onChange={(e)=>{setSelectedStatusId(e.value); }}
        label={"Státusz"}
        value={selectedStatusId} 
        conditions={"StatusId=2"}
        disabled={false}
        id={"selectedStatus"}
        table={"statusitems"}
        listType={{ id: "ItemId", name: "ItemName" }}
        />



<button  className="bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-md transition duration-300 ease-in-out" onClick={() => setShowInfo(!showInfo)}>
        {showInfo?"Vissza":"Részletek"}
      </button>
      </div>
   
      {showInfo && (
      <div className="modal py-3">
      <p className="text-gray-700 italic font-semibold "> - {props.ProjectDescription} </p> 
      <p className="text-gray-700 font-bold "> -  Felelős: {props.ResponsiblePersonName}</p>
      <p className="text-gray-800 italic font-bold"> - Határidő: {props.EndDate?.split("T")[0]}</p> 
    
      </div>
    )}
    </div>
</div>
</div>

  )
}
