import { useState,useEffect } from "react";
import { Link } from "react-router-dom";






const NavigationItem = ({ item,setSidebarOpen  }) => {
  const [visible, setVisible] = useState(false);

  const handleToggleVisible = () => {
    setVisible((prevVisible) => !prevVisible);
  };


  
  return (
    <div className="relative my-3 text-white cursor-pointer ml-3">
    <div
      className="flex items-center justify-between hover:bg-gray-800 hover:text-white rounded-md gap-x-3 px-2"
      onClick={handleToggleVisible}
    >
      <p className="font-semibold text-md py-3">{item.name}</p>
      <div className="py-3 px-3">
        {visible === false ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75"
            />
          </svg>
        )}
      </div>
    </div>
    {visible && (
      <ul className="mt-1 space-y-1 px-6">
        {item.items.map((subItem) => (
            <Link
            to={subItem.to === "#" ? "/" : `/app/${subItem.to}`}
            className="block w-full"
          
          >
          <li
          onClick={() => setSidebarOpen(false)}
            key={subItem.name}
            className="text-gray-400 hover:bg-gray-800 hover:text-white group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
          >
        
  {subItem.name}

          </li>
          </Link>
        ))}
      </ul>
    )}
  </div>
  );
};

const NavigationMenu = ({ setSidebarOpen }) => {
  const [menuData, setMenuData] = useState([]);
  const [userSubMenuIds, setUserSubMenuIds] = useState([]);

  const fetchUserPermissions = async (roleId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/getUserPermissions/${roleId}`);
      const data = await response.json();

      if (response.ok) {
        console.log("Role Permissions",data) // Jól megjelenik egy array-ben pl. így : [1,2,3]
        setUserSubMenuIds(data);
      } else {
        console.log("Error response");
      }
    } catch (error) {
      console.error("Error fetching user permissions:", error);
    }
  };

  const fetchMenuData = async () => {
    try {
      let response = await fetch(`${process.env.REACT_APP_API_URL}/getMenuData`);
      const data = await response.json();

      if (response.ok) {
        console.log("menu data", data); // Megjelennek a főmenük és az almenük is.
        setMenuData(data);
      } else {
        console.log("Error response");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const isAuthenticated = localStorage.getItem("user");
    const userObj = JSON.parse(isAuthenticated);
    console.log("Jelenlegi belépő RoleId", userObj.RoleId);
    
    fetchUserPermissions(userObj.RoleId); // Fetch user's permissions based on RoleId
    fetchMenuData();
  }, []);

  const filteredMenuData = menuData.map(item => ({
    ...item,
    items: item.items.filter(subItem => userSubMenuIds.includes(subItem.id))
  }));

  return (
    <nav>
      {filteredMenuData.map((item, index) => (
        <NavigationItem key={index} item={item} setSidebarOpen={setSidebarOpen} />
      ))}
    </nav>
  );
};

export default NavigationMenu;