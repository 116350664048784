
import { useEffect,useState } from "react";

import RoleInformation from "./RoleInformation";
//import { AddRoleForm } from "./AddRoleForm";
import { AddRole } from "./AddRole";

export default function RoleList() {

    const [Role,setRole]=useState([]);
    const [RoleId,setRoleId]=useState(null);

    const [showAddDialog, setShowAddDialog] = useState(false);


    const fetchData = async () => {
        try {
          let response = await fetch(
            `${process.env.REACT_APP_API_URL}/getRole`
          );
          const data = await response.json();
      
          if (response) {
            console.log("Role data", data);
            setRole(data);
          } else {
            console.log("Error response");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
    


      useEffect(() => {
        console.log("API URL:", `${process.env.REACT_APP_API_URL}`);

        fetchData();
      }, [setRole]);

      
      useEffect(() => {
        fetchData();
      }, []);
    
      return (
        <>
        <div className="mx-5 mb-2">
              <button
                type="button"
                onClick={() => {
                  setShowAddDialog(true);
                }}
                className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Hozzáadás
              </button>
        </div>
        <div className="grid grid-cols-4">
            
        <div className="col-span-1 h-screen overflow-y-auto px-3">
  {Role && Role.length > 0 ? (
    Role.map((p) => (
      <div
        key={p.RoleId}
        onClick={() => setRoleId(p.RoleId)}
        className="flex flex-col items-center h-16 justify-center text-center bg-white hover:bg-gray-200 border border-gray-200 rounded-md py-3 cursor-pointer p-4"
      >
        <p className="text-d font-semibold text-gray-800 text-sm">
          {p.RoleName}
        </p>
      </div>
    ))
  ) : (
    <p>Nincs elérhető jogosultság.</p>
  )}
</div>
        <div className="col-span-3">
          <RoleInformation RoleId={RoleId} />
        </div>
{/**
 <AddRoleForm
  show={showAddDialog}
  onHide={() => setShowAddDialog(false)}
  setRole={setRole}
  Role={Role} 
/>

 */}
 <AddRole show={showAddDialog}
  onHide={() => setShowAddDialog(false)}
  fetchData={fetchData}
  Role={Role}
  setRole={setRole}
  />

        
      </div>
      </>
      );
    
}


  