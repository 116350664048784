import { useState, useEffect } from "react";
import SelectInput from "../ui/SelectInput";
import Checkbox from "../ui/Checkbox";
import TimePickerValue from "../ui/TimePickerValue";
import DatePicker from "../ui/DatePicker";
import TextAreaInput from "../ui/TextAreaInput";
import dayjs from "dayjs";
import SelectListUpgrage from "../ui/SelectListUpgrade";
import InputField from "../ui/InputField";

export default function ProjectReports(props) {

  const [reports, setRepors] = useState(props.reports);
  const [searchInput, setSearchInput] = useState("");

  const [worker, setWorker] = useState(0);
  const [Status, setStatus] = useState(0);


  const [shift, setShift] = useState(0);
  const [groupId,setGroupId]=useState(0);
  const [MachineType, setMachineType] = useState(0);
  const [ProjectGroup,setProjectGroup] =  useState(0);
  const [startDate,setStartDate]=useState(null)
  const [endDate,setEndDate]=useState(null)

  useEffect(() => {
    setRepors(props.reports);
  }, []);

  useEffect(() => {
    setRepors(props.reports);
    handleFieldChange("startDate", startDate);
    handleFieldChange("endDate", endDate);
    handleFieldChange("worker", worker);
    handleFieldChange("company", ProjectGroup);
    handleFieldChange("Status", Status);
    handleFieldChange("ProjectGroupId", shift);
  }, [props.reports, startDate, endDate, worker, ProjectGroup, Status, shift,searchInput]);
  


  function displayDate(inputDate) {
    const date = new Date(inputDate);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}.${month}.${day} ${hours}:${minutes}`;
  }

  const applyFilters = (reports, filters) => {
    return reports.filter((rep) => {
      for (const filter of filters) {
        const { key, value } = filter;
        const reportValue = parseInt(rep[key]);

        if (value !== 0 && reportValue !== parseInt(value)) {
          return false;
        }
      }
      return true;
    });
  };

  const getFilteredReports = (filters) => {
    const reportList = props.reports.filter((rep) =>
      rep.ProjectName.toLowerCase().includes(searchInput.toLowerCase())
    );
  
    const startDateObject = startDate ? new Date(startDate) : null;
    let endDateObject = endDate ? new Date(endDate) : null;
  
    // Az endDateObject-et 1 nappal előre és hátra toljuk
    if (endDateObject) {
      endDateObject.setDate(endDateObject.getDate() + 1);
    }
  
    const dateFilteredReports = reportList.filter((rep) => {
      if (startDateObject) {
        const repStartDate = new Date(rep.ProjectStartDate);
        return startDateObject <= repStartDate && (!endDateObject || repStartDate <= endDateObject);
      }
      if (endDateObject) {
        const repStartDate = new Date(rep.ProjectStartDate);
        return endDateObject >= repStartDate 
      }
      return true;
    });
  
    const filteredReports = applyFilters(dateFilteredReports, filters);
  
 
    setRepors(filteredReports);
   
  };


  const handleFieldChange = (fieldName, value) => {
 
    // Alapértelmezett értékek
    let newWorker = worker;
    let newGroup = ProjectGroup;
    let newStatus = Status;
    let newMachineType = MachineType;
    let newShift = shift;
  
    // Itt beállítod a megfelelő értékeket a fieldName alapján
    switch (fieldName) {
      case "worker":
        newWorker = value;
        break;
      case "company":
        newGroup = value;
        break;
      case "Status":
        newStatus = value;
        break;
      case "ProjectGroupId":
        newShift = value;
        break;
      case "MachineType":
        newMachineType = value;
        break;
        case "startDate":
          setStartDate(value);
          getFilteredReports([
            { key: "WorkerId", value: newWorker },
            { key: "StatusId", value: newStatus },
            { key: "CompanyId", value: newGroup },
            { key: "ProjectGroupId", value: newShift },
          
          ]);
          break;
        case "endDate":
          setEndDate(value);
          getFilteredReports([
            { key: "WorkerId", value: newWorker },
            { key: "StatusId", value: newStatus },
            { key: "CompanyId", value: newGroup },
            { key: "ProjectGroupId", value: newShift },
          ]);
          break;
      default:
        break;
    }
  
   
    getFilteredReports([
      { key: "UserId", value: newWorker },
      { key: "StatusId", value: newStatus },
      { key: "CompanyId", value: newGroup },
      { key: "ProjectGroupId", value: newShift },
    
      // Egyéb filterek
    ]);
  
    // Frissítsd az állapotokat
    setWorker(newWorker);
    setProjectGroup(newGroup);
    setStatus(newStatus);
    setShift(newShift);
    setMachineType(newMachineType);
  };

  const formFields = [

    {
      Fieldtype: "datePicker",
      label: "Kezdő dátum",
      value: startDate,
      onChange: (e) => {
        handleFieldChange("startDate", e);
      },
    },
    {
      Fieldtype: "datePicker",
      label: "Befejező dátum",
      value: endDate,
      onChange: (e) => {
        handleFieldChange("endDate", e);
      },
    },
    {
      Fieldtype: "select",
      id: "worker",
      label: "Tulajdonos",
      value: worker,
      table: "web_user",
      listType: { id: "UserId", name: "UserName" },
      onChange: (e) => {
        handleFieldChange("worker", e.value);
      },
    },

    {
      Fieldtype: "select",
      id: "Status",
      label: "Státusz",
      value: Status,
      table: "statusitems",
      conditions:"StatusId=1",
      listType: { id: "ItemId", name: "ItemName" },
      onChange: (e) => {
        handleFieldChange("Status", e.value);
      },
    },

  
    {
      Fieldtype: "select",
      id: "companyId",
      label: "Cég",
      value: ProjectGroup,
      table: "company",
      listType: { id: "companyId", name: "companyName" },
      onChange: (e) => {
        handleFieldChange("company", e.value);
      },
    },

    {
      Fieldtype: "input",
      label: "Projekt neve",
      type: "text",
      value: searchInput,
      onChange: (e) => {
        setSearchInput(e.target.value);
      },
    },

    
    {
      Fieldtype: "select",
      id: "shift",
      label: "Projekt csoport",
      value: shift,
      table: "project_group_listitems",
      conditions:"ProjectGroupStatusId=3",
      listType: { id: "ItemId", name: "ItemName" },
      onChange: (e) => {
        handleFieldChange("ProjectGroupId", e.value);
      },
    },

  ];

  return (
    <>
      <div className="col-span-3 flex flex-col overflow-hidden bg-white shadow sm:rounded-lg ">
        <div className=" col  h-full flex-col overflow-hidden bg-white p-2 shadow sm:rounded-lg">
          <div className="flex h-full overflow-hidden border-t border-gray-200">
            <div className="w-full overflow-x-auto ">
              <div className=" sticky top-0 z-10 bg-white">
                <div>
                  <div className="grid col-span-1 md:col-span-2 lg:grid-cols-3 gap-4 xl:grid-cols-4 py-3 px-4">
                   
                    {formFields.map((field) => {
                      if (field.Fieldtype === "select") {
                        return (
                          <div className="relative basis-64 col">
                            <SelectListUpgrage
                              key={field.id}
                              onChange={field.onChange}
                              label={field.label}
                              value={field.id}
                              disabled={field.disabled}
                              id={field.id}
                              table={field.table}
                              conditions={field.conditions}
                              listType={field.listType}
                            />
                          </div>
                        );
                      } else if (field.Fieldtype === "checkbox") {
                        return (
                          <Checkbox
                            key={field.id}
                            id={field.id}
                            label={field.label}
                            disabled={field.disabled}
                            value={field.id}
                            onChange={field.onChange}
                            table={field.table}
                            listType={field.listType}
                          />
                        );
                      } else if (field.Fieldtype === "timePicker") {
                        return (
                          <TimePickerValue
                            label={field.label}
                            value={field.id}
                            onChange={field.onChange}
                          />
                        );
                      } else if (field.Fieldtype === "datePicker") {
                        return (
                          <DatePicker
                            label={field.label}
                            value={field.id}
                            onChange={field.onChange}
                          />
                        );
                      } else if (field.Fieldtype === "input") {
                        return (
                          <InputField
                            key={field.id}
                            id={field.id}
                            label={field.label}
                            type={field.type}
                            min={field.min}
                            max={field.max}
                            disabled={field.disabled}
                            value={field.id}
                            additionalPreparedness={
                              field.additionalPreparedness
                            }
                            onChange={field.onChange}
                          />
                        );
                      } else if (field.Fieldtype === "textAreaInput") {
                        return (
                          <TextAreaInput
                            key={field.id}
                            id={field.id}
                            label={field.label}
                            type={field.type}
                            min={field.min}
                            max={field.max}
                            disabled={field.disabled}
                            value={field.id}
                            additionalPreparedness={
                              field.additionalPreparedness
                            }
                          />
                        );
                      }
                      return null; // Kihagyhatod a nem támogatott mezőket
                    })}
                      
                  </div>
                </div>
              </div>
              <div className="inline-block min-w-full border-b border-gray-200 align-middle">
                {
                  <>
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="sticky top-28  bg-slate-200">
                        <tr>
                        
                          <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                          >
                           Projekt neve
                          </th>
                          <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                          >
                            Cég
                          </th>

                          <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                          >
                            Státusz
                          </th>

                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                          >
                            Kezdő időpont
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                          >
                            Befejező időpont
                          </th>

                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Projekt csoport
                          </th>

                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Készültség
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Tulajdonos
                          </th>

                        
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white ">
                        {reports.map((person) => {
                          return (
                            <tr
                              key={person.email}
                              onClick={() => {
                                if (!props.viewField) return;

                                props.setSelectedValues({
                                  ...person,
                                  ProjectStartDate: person.ProjectEndDate.split("T")[0],
                                  ProjectEndDate: person.ProjectEndDate.split("T")[0],
                                  //StartTime: dayjs(person.StartDate),
                                  //EndTime: dayjs(person.EndDate),
                                });

                                console.log("person", person);
                              }}
                              className={`
                      ${
                        person.ShiftId === 4 && person !== props.selectedValues
                          ? "bg-red-100 "
                          : ""
                      }
                      ${
                        person?.ProjectId ===
                        props.selectedValues?.ProjectId
                          ? "cursor-help  bg-gray-200 shadow-xl shadow-gray-300"
                          : "cursor-grab"
                      }
                      ${!props.viewField ? "cursor-not-allowed opacity-50" : ""}
                  `}
                            >
                            
                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                {person.ProjectName}
                              </td>
                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                {person.companyName}
                              </td>

                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                {person.StatusName}
                              </td>

                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                {displayDate(person.ProjectStartDate)}
                              </td>
                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                {displayDate(person.ProjectEndDate)}
                              </td>

                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                {person.groupItemName}
                              </td>

                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                {person.ProjectPrepare} %
                              </td>

                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                {person.NickName}
                              </td>
                           

                           
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
