import { useEffect, useState } from "react";
//import SubMenuPermissionModal from "./SubMenuPermissionModal";

import RoleModify from "./RoleModify";

export default function RoleInformation(props) {
  //const [showAddDialog, setShowAddDialog] = useState(false);
  const [Role, setRole] = useState([]);

  const [RoleSubMenuPermissions, setRoleSubMenuPermissions] = useState([]);
  const [allSubMenus, setAllSubMenus] = useState([]);

  const [roleModalShow, setRoleModalShow] = useState(false);
  const [roleHasPermission,setRoleHasPermission] = useState(false);
  const [selectedSubMenu,setSelectedSubMenu]=useState(null);

const [refreshPage, setRefreshPage]=useState(false);  


  const fetchData = async () => {
    try {
      let response = await fetch(
        `${process.env.REACT_APP_API_URL}/getRoleInformation?RoleId=${props.RoleId}`
      );
      const data = await response.json();

      if (response) {
        setRole(data);
        fetchRoleSubMenuPermissions(data[0]?.RoleId);
        fetchAllSubMenus(); // Fetch all sub menus
      } else {
        console.log("Error response");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchRoleSubMenuPermissions = async (roleId) => {
    try {
      let response = await fetch(
        `${process.env.REACT_APP_API_URL}/getUserSubMenuPermissions?RoleId=${roleId}`
      );
      const data = await response.json();

      if (response) {
        console.log("DATA",data)
        setRoleSubMenuPermissions(data);
      } else {
        console.log("Error response");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchAllSubMenus = async () => {
    try {
      let response = await fetch(`${process.env.REACT_APP_API_URL}/getAllSubMenus`);
      const data = await response.json();

      if (response) {
        setAllSubMenus(data);
      } else {
        console.log("Error response");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    console.log("RoleSubMenuPermissions",RoleSubMenuPermissions)
    fetchData();
  }, [props.RoleId]);


  useEffect(()=>{
    fetchData();
  },[])


  useEffect(()=>{
    fetchData();
  },[refreshPage])

  useEffect(() => {
    console.log("RoleSubMenuPermissions",RoleSubMenuPermissions)
    fetchData();
  }, [props]);

  return (
    <>
      {/* ... */}
      <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 h-full overflow-x-auto">
        <div className="mt-4">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Jogosultságok
          </h3>
          <table className="mt-2 min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Menüpont
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Jogosult
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                 
                </th>
              </tr>
            </thead>
            <tbody>
              {allSubMenus.map((subMenu, index) => (
                <tr key={index} className="bg-white">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {subMenu.SubMenuName}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {RoleSubMenuPermissions.some(
                      permission => permission.SubMenuName === subMenu.SubMenuName
                    )
                      ? "Jogosult"
                      : "Nem jogosult"}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <button
                      onClick={() => {setRoleModalShow(true); 
                        setRoleHasPermission(RoleSubMenuPermissions.some(permission => permission.SubMenuName === subMenu.SubMenuName)?true:false);
                      setSelectedSubMenu(subMenu.SubMenuId) 
              
                      }  
                    }
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      Szerkesztés
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

     
      </div>
     
    
      <RoleModify
  show={roleModalShow}
  onHide={() => setRoleModalShow(false)}
  hasPermission={roleHasPermission}
  roleId={props.RoleId}
  selectedSubMenu={selectedSubMenu}
  RoleSubMenuPermissions={RoleSubMenuPermissions}
  refreshPage={refreshPage}
  setRefreshPage={setRefreshPage}
  setRoleSubMenuPermissions={setRoleSubMenuPermissions} // Pass the setter function

/>
    </>
  );
}