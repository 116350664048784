import { useState, useEffect, useRef } from "react";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import { english } from "flatpickr/dist/l10n/de.js";
import { format } from "date-fns";

export default function DatePicker(props) {
  const flatpickrRef = useRef(null);

  useEffect(() => {
    const options = {
      locale: english,
      defaultDate: props.value,
      allowInput: true,
      onChange: (selectedDates, dateStr, instance) => {
        if (dateStr === "") {
          // Ha az input mező értéke üres, állítsd be az értéket null-ra
          props.onChange(null);
        } else {
          const formattedDate = format(selectedDates[0], "yyyy-MM-dd");
          props.onChange(formattedDate);
        }
      },
    };
  
    flatpickr(flatpickrRef.current, options);
  }, [props.value, props.onChange]);

  return (
    <div className="col relative basis-64">
    <label
      htmlFor="pallet-weight"
      className={`absolute -top-2 left-2 z-10 inline-block max-w-full truncate  px-1 text-xs font-extralight ${props.disabled ? 'text-gray-400' : 'text-gray-900 bg-white'}`}
    >
      {props.label}
    </label>
    <div className="relative rounded-md shadow-sm">
      <input
        disabled={props.disabled}
        className="px-3 block w-full h-10 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed disabled:bg-stone-200 disabled:opacity-50 sm:text-sm sm:leading-6"
        ref={flatpickrRef}
        type="text"
        value={props.value} // Itt a props.value-t használjuk közvetlenül
        placeholder="Válassz egy dátumot"
      />
    </div>
  </div>
  );
}