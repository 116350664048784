import { FormEvent, useState, useEffect } from "react";

import "flatpickr/dist/flatpickr.min.css"
import DatePicker from "../ui/DatePicker";

import axios from "axios";
import SimpleTaskReports from "./SimpleTaskReports";
import SelectInput from "../ui/SelectInput";
import InputField from "../ui/InputField";
import Checkbox from "../ui/Checkbox";
import { Button } from "../ui/Button";
import TextAreaInput from "../ui/TextAreaInput";
import TimeSelector from "../ui/TimeSelector";

import TimePickerValue from "../ui/TimePickerValue";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import FormTabs from "./FormTabs";
import FormHeader from "../ui/FormHeader";
//import { PDFViewer } from "@react-pdf/renderer";
import DeleteDialog from "../ui/DeleteDialog";
import SelectListUpgrage from "../ui/SelectListUpgrade";

export const SimpleTaskForm = (props) => {
  const isAuthenticated = localStorage.getItem("user");
  const userObj = JSON.parse(isAuthenticated);
  const [owner,setOwner] = useState(userObj.UserId);
  const [showDeleteDialog, setShowDeleteDialog] =useState(false);
  const [reportList, setReportList] = useState([]);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);


  const [memberList,setMemberList] = useState([]);
  const [fileList,setFileList] = useState([]);
  
  const [newMembers,setNewMembers]=useState([]);
  const [newFiles,setNewFiles] = useState([]);

  const [selectedValues, setSelectedValues] = useState({
    StartDate: "",
    StartTime:"",
    EndDate:"",
    EndTime:"",
    Duration:1,
    SimpleTaskName:"",
    ProjectId: "",
    ResposiblePersonId: "",
    MachineId: "",
    SimpleTaskTypeId:"",
    Preparedness: "",
    StatusId:"",
    UserId: owner,
    SimpleTaskDescription:"",
    Archive: "N",
  });


  const [simpleTaskNameValid,setSimpleTaskNameValid] = useState(false);
  const [intervallValid,setIntervallValid] = useState(true);
  const [machineValid,setMachinevalid] = useState(false);
  const [projectValid,setProjectValid] = useState(false);
  const [taskTypeValid,setTaskTypeValid] = useState(false);
  const [resposiblePersonValid,setResposiblPersonValid] = useState(false);
  const [peraperValid,setPrepareValid] = useState(false);


  const formValid = intervallValid &&machineValid&&projectValid&&taskTypeValid&& resposiblePersonValid &&peraperValid &&simpleTaskNameValid 


  const getCurrentDate = () => {
    const todayDate = new Date();
    const year = todayDate.getFullYear(); // Évszám
    const month = todayDate.getMonth() + 1; // Hónap (0-tól indexelődik, ezért +1)
    const day = todayDate.getDate(); // Nap
    // Formázás: "év-hónap-nap" (pl. "2023-10-06")
    const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${
      day < 10 ? "0" : ""
    }${day}`;
    return formattedDate;
  };

  const sortReports = (reports) => {
    const notArchiveReports = reports.sort(
      (a, b) =>
        new Date(a.StartDate).getTime() - new Date(b.StartDate).getTime()
    );   
    return [...notArchiveReports];
  };

  const getReports = async () => {
    try {
      const requestData = {
        tables: [
          "simple_task",
          "project",
          "web_user",
          "web_user AS responsible_user", // Alias létrehozása itt
          "machines",
          "simple_task_types",
          "statusitems"
          
        ],
        joins: [
          "simple_task.ProjectId = project.ProjectId",
          "simple_task.UserId = web_user.UserId",
          "simple_task.ResposiblePersonId = responsible_user.UserId", // Új feltétel hozzáadva
          "simple_task.MachineId = machines.MachineId",
          "simple_task.SimpleTaskTypeId = simple_task_types.SimpleTaskTypeId",
          "simple_task.StatusId = statusitems.ItemId"
        ],
        conditions: ["simple_task.Archive = 'N'"],
        selectedColumns: [
          "simple_task.*",
          "project.*",
          "web_user.*", // web_user mezői
          "responsible_user.UserName AS ResponsiblePersonName", // responsible_user neve Alias-ként
          "machines.*",
          "simple_task_types.*",
          "statusitems.*"
        ],
      };
  
      console.log("requestData", requestData);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getJoinedData`,
        requestData
      );
      console.log("Result of getReports",response.data)
      setReportList(sortReports(response.data));
    
    } catch (error) {
      console.error(error);
    }
  };


  const getResposiblePersons = async () => {
    try {
      const requestData = {
        tables: [
          "simple_task_resp_personlist",
          "simple_task",
          "web_user",      
        ],
        joins: [
          "simple_task_resp_personlist.SimpleTaskId = simple_task.SimpleTaskId",
          "simple_task_resp_personlist.UserId = web_user.UserId",
        ],
        conditions: [`simple_task_resp_personlist.SimpleTaskId = ${selectedValues.SimpleTaskId}`],
        selectedColumns: [
          "simple_task_resp_personlist.*",
          "simple_task.*",
          "web_user.*"
        ],
      };
  
      console.log("requestData", requestData);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getJoinedData`,
        requestData
      );
      console.log("extendedResult memberList", response.data);
      setMemberList(response.data);
    } catch (error) {
      console.error(error);
    }

  };


  
  const listFilesForSimpleTask = async () => {
    try{
    const requestData = {
      tables: [
        "simple_task_attachment",  
      ],
      joins: [],
      conditions: [`SimpleTaskId = ${selectedValues.SimpleTaskId}`],
      selectedColumns: [
        "simple_task_attachment.*",
      ],
    };

    console.log("Attachments", requestData);
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/getJoinedData`,
      requestData
    );
    setFileList(response.data);
  } catch (error) {
    console.error(error);
  }
  };




 const handleAddTaskMembers = async (taskMembers,insertId) => {
  const tableName = "simple_task_resp_personlist";
  const id= "RespPersonSimpleTaskId"
  try {
    for (const item of taskMembers) {
      const insertObject = {
        UserId: item.UserId,
        SimpleTaskId: item.SimpleTaskId??insertId,
        RoleName: item.RoleName,
      };
      console.log("insertObject", insertObject);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/addItem`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ tableName, id, data: insertObject }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("result", result);
      } else {
        console.error("Hiba történt a szerverrel való kommunikáció során.");
      }
    }
  } catch (error) {
    console.error("Hiba történt:", error);
  }
  setNewMembers([]);
};


const handleFileUpload = async (files, id) => {
  try {
    for (const item of files) {
      item.append("SimpleTaskId",id); 
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/upload-endpoint`, {
          method: "POST",
          body: item,
        });

        if (response.ok) {
          const data = await response.json();
          console.log("Sikeres feltöltés:", data);
        } else {
          console.error("Hiba történt a feltöltés során:", response.statusText);
        }
      } catch (error) {
        console.error("Hiba történt a fetch során:", error);
      }
    }
  } catch (error) {
    console.error("Hiba történt:", error);
  }
  setNewFiles([]);
  //setFileList([]);
};




  const updateObject = async () => {
    console.log("kiválasztott update object",selectedValues)
   const sTime = selectedValues.StartTime.hour()+":"+ selectedValues.StartTime.minute()
    const eTime = selectedValues.EndTime.hour()+":"+ selectedValues.EndTime.minute()
  //  console.log("teszt adatok",startDate,sTime)
    const StartDate = formatDate(selectedValues.StartDate, sTime);
    const EndDate = formatDate(selectedValues.EndDate, eTime);
    
    const updateData = {
      tableName: "simple_task",
      updates: {
        StartDate,
        EndDate,
        SimpleTaskName: selectedValues.SimpleTaskName,
        Duration: selectedValues.Duration,
        Preparedness: selectedValues?.Preparedness,
        ResposiblePersonId: selectedValues.ResposiblePersonId,
        SimpleTaskDescription: selectedValues.SimpleTaskDescription,
        UserId: selectedValues.UserId,
        StatusId: selectedValues.StatusId,
        MachineId: selectedValues.MachineId,
        SimpleTaskTypeId: selectedValues.SimpleTaskTypeId,
        Archive: "N",
      },
      whereClause: {
        condition: `SimpleTaskId = ${selectedValues?.SimpleTaskId}`,
      },
    };
    console.log("Information data", updateData);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/updateTable`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updateData),
        }
      );

      if (response.ok) {
        console.log("Status change successfully.");
      } else {
        console.error("Error changing status:", response.statusText);
      }
    } catch (error) {
      console.error("Error changing status:", error);
    }
    getReports();
  };

  const handleCopyObject = async () => {
    const sTime = selectedValues.StartTime.hour()+":"+ selectedValues.StartTime.minute()
    const eTime = selectedValues.EndTime.hour()+":"+ selectedValues.EndTime.minute()
   // console.log("teszt adatok",startDate,sTime)
    const StartDate = formatDate(selectedValues.StartDate, sTime);
    const EndDate = formatDate(selectedValues.EndDate, eTime);

    const insertObject = {
      StartDate: StartDate,
      EndDate: EndDate,
      SimpleTaskName:selectedValues.SimpleTaskName + "(Másolat)",
      Duration: selectedValues.Duration,
      SimpleTaskDescription: selectedValues.SimpleTaskDescription,
      Preparedness: selectedValues.Preparedness,
      ResposiblePersonId: selectedValues.ResposiblePersonId,
      Archive: "N",
      ProjectId: selectedValues.ProjectId,
      StatusId: selectedValues.StatusId,
      UserId: selectedValues.UserId,
      MachineId: selectedValues.MachineId,
      SimpleTaskTypeId: selectedValues.SimpleTaskTypeId,
    };

    console.log("insertObject", insertObject);
    console.log("selectedValues", selectedValues);
    try {
      // Az adatok, amiket elküldünk a Node.js szervernek

      const tableName = "simple_task";
      const id= "SimpleTaskId"

      // Fetch hívás a Node.js szerver felé
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/addItem`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ tableName,id, data: insertObject }), // Az adatok JSON formátumban elküldve
        }
      );

      if (response.ok) {
        const result = await response.json(); // A szerver válaszának feldolgozása
        console.log("copy result", result.insertedData);
        getReports();
       // setSelectedRow(result.insertedData);
        //setSelectedValues(result.insertedData);

        setSelectedValues({ ...result.insertedData, 
          StartDate: result.insertedData.StartDate.split("T")[0],
           EndDate: result.insertedData.EndDate.split("T")[0], 
           StartTime: dayjs(result.insertedData.StartDate) , 
          EndTime: dayjs(result.insertedData.EndDate)
          })

       
        //  setData(result);
      } else {
        console.error("Hiba történt a szerverrel való kommunikáció során.");
      }
    } catch (error) {
      console.error("Hiba történt:", error);
    }

  
    
  };


  const handleInsertObject = async () => {
    console.log("teszt idők",selectedValues.StartTime,selectedValues.EndTime)

    const sTime = selectedValues.StartTime.hour()+":"+ selectedValues.StartTime.minute()
    const eTime = selectedValues.EndTime.hour()+":"+ selectedValues.EndTime.minute()

   // const sTime = selectedValues.StartTime.hour()+":"+ selectedValues.StartTime.minute()
   // const eTime = selectedValues.EndTime.hour()+":"+ selectedValues.EndTime.minute()
 
    const StartDate = formatDate(selectedValues.StartDate, sTime);
    const EndDate = formatDate(selectedValues.EndDate, eTime);
  
    const insertObject = {
      StartDate,
      EndDate,
   //   StartTime: sTime,
   //   EndTime: eTime,
      SimpleTaskName: selectedValues.SimpleTaskName,
      Duration: selectedValues.Duration,
      Preparedness: selectedValues?.Preparedness,
      ResposiblePersonId: selectedValues.ResposiblePersonId,
      SimpleTaskDescription: selectedValues.SimpleTaskDescription,
      ProjectId: selectedValues.ProjectId,
      StatusId: selectedValues.StatusId,
      UserId: selectedValues.UserId,
      MachineId: selectedValues.MachineId,
      SimpleTaskTypeId:selectedValues.SimpleTaskTypeId,
      Archive: "N",
    };
  
    console.log("new insert object", insertObject);
  
    try {
      const tableName = "simple_task";
      const id= "SimpleTaskId"
      const response = await fetch(`${process.env.REACT_APP_API_URL}/addItem`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ tableName, id, data: insertObject }),
      });
  
      if (response.ok) {
        const result = await response.json();
        console.log("result", result);
        await getReports();
        await handleAddTaskMembers(newMembers,result.insertedId);
        await handleFileUpload(newFiles,result.insertedId)

     
       // setSelectedRow(result.insertedData);
        setSelectedValues({ ...result.insertedData, 
          StartDate: result.insertedData.StartDate.split("T")[0],
           EndDate: result.insertedData.EndDate.split("T")[0], 
           StartTime: dayjs(result.insertedData.StartDate) , 
          EndTime: dayjs(result.insertedData.EndDate)
          })

          setSimpleTaskNameValid(false);
          setIntervallValid(true);
          setMachinevalid(false);
        setProjectValid(false);
         setTaskTypeValid(false);
          setResposiblPersonValid(false);
          setPrepareValid(false);
       
      } else {
        console.error("Hiba történt a szerverrel való kommunikáció során.");
      }
    } catch (error) {
      console.error("Hiba történt:", error);
    }


  };

  
  dayjs.extend(utc);

  const formatDate = (date, time) => {
    const [year, month, day] = date.split('-');
    const [hour, minute] = time.split(':');
  
    const formattedDate = dayjs()
      .year(year)
      .month(month - 1)
      .date(day)
      .hour(hour)
      .minute(minute)
      .format(); // Most a helyi időzóna és formátum lesz alkalmazva
  
    return formattedDate;
  };


  const handleRemoveElement = async () => {
    const updateData = {
      tableName: "simple_task",
      updates: {
        Archive: 'Y'
      },
      whereClause: {
        condition: `SimpleTaskId = ${selectedValues?.SimpleTaskId}`,
      },
    };
    console.log("Information data", updateData);
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/updateTable`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updateData),
        }
      );
  
      if (response.ok) {
        console.log("Status change successfully.");
      } else {
        console.error("Error changing status:", response.statusText);
      }
    } catch (error) {
      console.error("Error changing status:", error);
    }
  
    // Elmentett kijelölés eltávolítása
    setShowDeleteDialog(false);
  
    // Az aktuális kijelölt elem indexének megtalálása
    const currentIndex = reportList.findIndex((rep) => rep.SimpleTaskId === selectedValues?.SimpleTaskId);
  
    if (currentIndex !== -1) {
      if (currentIndex === reportList.length - 1) {
        // Ha az aktuális kijelölt elem az utolsó elem
        if (currentIndex > 0) {
          // Ha van előtte elem, akkor az lesz a kijelölt elem
          //setSelectedRow(reportList[currentIndex - 1]);
          const prevReport = reportList[currentIndex - 1];
          setSelectedValues({ 
            ...prevReport, 
            StartDate: prevReport.StartDate.split("T")[0],
            EndDate: prevReport.EndDate.split("T")[0], 
            StartTime: dayjs(prevReport.StartDate), 
            EndTime: dayjs(prevReport.EndDate)
          });
        } else {
          // Ha nincs előtte elem, akkor a kijelölést és az értékeket null-ra állítjuk
         // setSelectedRow(null);
         // setSelectedValues(null);
        }
      } else {
        const nextReport = reportList[currentIndex + 1];
        setSelectedValues({ 
          ...nextReport, 
          StartDate: nextReport.StartDate.split("T")[0],
          EndDate: nextReport.EndDate.split("T")[0], 
          StartTime: dayjs(nextReport.StartDate), 
          EndTime: dayjs(nextReport.EndDate)
        });
      }
    } else {
      //setSelectedRow(null);
      //setSelectedValues(null);
    }
    getReports();

  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const isAuthenticated = localStorage.getItem("user");
    const userObj = JSON.parse(isAuthenticated);
    setOwner(userObj.UserId)
    getReports().then(() => {
      setIsLoading(false); // Az adatok betöltése befejeződött
    });
    setSimpleTaskNameValid(false);
    setIntervallValid(true);
    setMachinevalid(false);
  setProjectValid(false);
   setTaskTypeValid(false);
    setResposiblPersonValid(false);
    setPrepareValid(false);
  }, []);


  useEffect(() => {
    getResposiblePersons();
    listFilesForSimpleTask();
  }, [selectedValues]);
 
  const handleSelect = (selectedOption, name, selectedValidityName) => {
    setSelectedValues({
      ...selectedValues,
      [name]: selectedOption.value,
    });
    if (selectedOption.value !== 0) {
      selectedValidityName(true);
    } else {
      selectedValidityName(false);
      // Esetleg hibaüzenet megjelenítése vagy más kezelés
    }
  };


  const onSubmit = (e) => {
    e.preventDefault();
  };


  const formFields = [

    {
      Fieldtype: "textAreaInput",
      id: "SimpleTaskName",
      label: "Feladat rövid neve",
      type: "text",
      disabled: viewField,
      value: selectedValues.SimpleTaskName,
     
      onChange: (e) => {
        setSelectedValues({ ...selectedValues, SimpleTaskName: e.target.value });
        setSimpleTaskNameValid(e.target.value)
       // setSimpleTaskNameValid(e.target.value.length > 0);
        
      },
    },

    {
      Fieldtype: "datePicker",
      label: "Kezdő dátum",
      id: "StartDate",
      value: selectedValues.StartDate,
      disabled: viewField,
      onChange: (e) => {setSelectedValues({ ...selectedValues, StartDate: e })},
    },

    {
      Fieldtype: "timePicker",
      label: "Kezdő időpont",
      id: "StartTime",
      value: selectedValues.StartTime,
      disabled: viewField,
      onChange: (e) => {setSelectedValues({ ...selectedValues, StartTime: e })},
    },
/*
    {
      Fieldtype: "datePicker",
      label: "Befejező dátum",
      id: "EndDate",
      value: selectedValues.EndDate,
      disabled: viewField,
      onChange: (e) => {setSelectedValues({ ...selectedValues, EndDate: e })},
    },

    {
      Fieldtype: "timePicker",
      label: "Befejező időpont",
      id: "EndTime",
      value: selectedValues.EndTime,
      disabled: viewField,
      onChange: (e) => {setSelectedValues({ ...selectedValues, EndTime: e })},
    },
*/
    {
      Fieldtype: "input",
      id: "Duration",
      label: "Időintervallum",
      type: "number",
      disabled: viewField,
      value: selectedValues.Duration,
     
      onChange: (e) => {
     
        const startDate = dayjs(selectedValues.StartTime);
        const newEndDate = startDate.add(e.target.value, 'hour');
        console.log("newEndDate",newEndDate)
        setSelectedValues({
          ...selectedValues,
          Duration: e.target.value,
          EndTime: dayjs(newEndDate), // Az EndDate-nek dátum objektumnak kell lennie
        });
        setIntervallValid(e.target.value)
      },
    },
    
    {
      Fieldtype: "select",
      id: "MachineId",
      label: "Erőforrás",
      value: selectedValues.MachineId,
      disabled: viewField,
      table: "machines",
      valid: setMachinevalid,
      listType: { id: "MachineId", name: "MachineName" },
    },
   

    {
      Fieldtype: "select",
      id: "ProjectId",
      label: "Projekt",
      value: selectedValues.ProjectId,
      disabled: viewField,
      table: "project",
      valid: setProjectValid,
      listType: { id: "ProjectId", name: "ProjectName" },
    },

    {
      Fieldtype: "select",
      id: "SimpleTaskTypeId",
      label: "Feladat típusa",
      value: selectedValues.SimpleTaskTypeId,
      disabled: viewField,
      valid: setTaskTypeValid,
      table: "simple_task_types",
      listType: { id: "SimpleTaskTypeId", name: "SimpleTaskTypeName" },
    },
    
    {
      Fieldtype: "select",
      id: "UserId",
      label: "Tulajdonos",
      value: selectedValues.UserId,
      disabled: true,
      table: "web_user",
      listType: { id: "UserId", name: "UserName" },
    },

 

    {
      Fieldtype: "select",
      id: "ResposiblePersonId",
      label: "Felelős",
      disabled: viewField,
      value: selectedValues.ResposiblePersonId,
      valid : setResposiblPersonValid,
      table: "web_user",
      listType: { id: "UserId", name: "UserName" },
    },
   
   
    {
        Fieldtype: "input",
        id: "Preparedness",
        label: "Készültség",
        type: "number",
        min: 1,
        max: 100,
        disabled: viewField,
        value: selectedValues.Preparedness,
       
        onChange: (e) => {
          setSelectedValues({ ...selectedValues, Preparedness: e.target.value });
          //setItemNameValid(e.target.validity.valid)
          setPrepareValid(e.target.value)
        },
      }, 

      {
        Fieldtype: "select",
        id: "StatusId",
        label: "Státusz",
        value: selectedValues.StatusId,
        table: "statusitems",
       // valid: setProjectGroupValid,
        disabled: viewField,
        conditions:"StatusId=2",
        listType: { id: "ItemId", name: "ItemName" },
      },
  ];

  return (
    <div className="fixed top-16 inset-0 grid grid-cols-5 grid-rows-[auto_1fr] gap-4 bg-gray-100 p-4">
    <div className="col-span-2 w-full overflow-x-auto">
      <form className="flex flex-col h-full overflow-x-auto bg-white shadow sm:rounded-lg" onSubmit={onSubmit}>
       
        <FormHeader disabled={!viewField}
          setViewField={setViewField} 
          setAddField={setAddField} 
            setSelectedValues={setSelectedValues}
            setMemberList={setMemberList}
            setFileList={setFileList}
            setEditButtonvisible={setEditButtonvisible} 
            handleCopyObject={handleCopyObject}
            owner={owner}
            getCurrentDate={getCurrentDate}
            setShowDeleteDialog={setShowDeleteDialog}
           
           />
       

        <div className="flex h-full flex-col gap-6 overflow-x-auto border-t border-gray-200 px-3 py-9">
          <div className="grid col-span-1 md:col-span-2 lg:grid-cols-3 gap-4 xl:grid-cols-4">
            {formFields.map((field) => {
              if (field.Fieldtype === "select") {
                return (
              
                  <SelectListUpgrage
                  key={field.id}
                  onChange={(selectedOption) =>
                    handleSelect(selectedOption, field.id,field.valid)
                  }
                  label={field.label}
                  value={selectedValues[field.id]} 
                  conditions={field.conditions}
                  disabled={field.disabled}
                  id={field.id}
                  table={field.table}
                  listType={field.listType}
                />
                );
              } else if (field.Fieldtype === "checkbox") {
                return (
                  <Checkbox
                    key={field.id}
                    id={field.id}
                    label={field.label}
                    disabled={field.disabled}
                    value={selectedValues[field.id]} 
                    onChange={field.onChange}
                    table={field.table}
                    listType={field.listType}
                  />
                );
              }
              else if (field.Fieldtype === "timePicker") {
                return (
                  <TimePickerValue 
                  label={field.label}
                  value={selectedValues[field.id]} 
                  disabled={viewField} 
                  onChange={field.onChange} 
                  />
                );
              } 

              else if (field.Fieldtype === "datePicker") {
                return (
                  <DatePicker
                  label={field.label}
                  value={selectedValues[field.id]}  
                  disabled={viewField} 
                  onChange={field.onChange}
                />
                );
              } 

               else if (field.Fieldtype === "input") {
                return (
                  <InputField
                    key={field.id}
                    id={field.id}
                    label={field.label}
                    type={field.type}
                    min={field.min}
                    max={field.max}
                    disabled={field.disabled}
                    value={selectedValues[field.id]}
                    additionalPreparedness={field.additionalPreparedness}
                    onChange={field.onChange}
                  />
                );
              }
              else if (field.Fieldtype === "textAreaInput") {
                return (
                  <TextAreaInput
                    key={field.id}
                    id={field.id}
                    label={field.label}
                    type={field.type}
                    min={field.min}
                    max={field.max}
                    disabled={field.disabled}
                    value={selectedValues[field.id]} 
                    additionalPreparedness={field.additionalPreparedness}
                    onChange={field.onChange}
                  />
                );
              }
              return null; // Kihagyhatod a nem támogatott mezőket
            })}
          </div>

        
      <FormTabs 
        //Azonosító, hogy melyik form-hoz adjuk hozzá az elemet
        SimpleTaskId={selectedValues?.SimpleTaskId} 
        disabled={viewField}

        //További felelősökhoz tartozó részek
        memberList={memberList}
        setMemberList={setMemberList}
        newMembers = {newMembers}
        setNewMembers={setNewMembers}

        // Drag and Drop tartozó formrészek
        fileList={fileList}
        setFileList={setFileList}
        newFiles={newFiles}
        setNewFiles={setNewFiles} 

        //Leírás formrészhez szükséges mezők
        formId={"SimpleTaskDescription"}
        value={selectedValues.SimpleTaskDescription}
        onChange={(e) => {
          setSelectedValues({
            ...selectedValues,
            SimpleTaskDescription: e.target.value,
          })
          }} 
      />
                

          {!editButtonVisible && (
            <div className="flex justify-center gap-3">
              <Button
                type="button"
                children="Rögzítés"
                buttonType="add"
                onClick={() => {
                  setViewField(true);
                  setEditButtonvisible(true);
                  updateObject();
                  handleAddTaskMembers(newMembers,0);
                  handleFileUpload(newFiles,selectedValues.SimpleTaskId);
                  getReports();
                }}
              />

              <Button
                type="button"
                children="Mégse"
                buttonType="delete"
                onClick={() => {
                  setViewField(true);
                  setEditButtonvisible(true);
                  setNewMembers([]);
                  setNewFiles([]);
                }}
              />
            </div>
          )}

          {!addField && (
            <div className="flex justify-center gap-3">
              <Button
                type="button"
                children="Rögzítés"
                disabled={!formValid}
                buttonType="add"
                onClick={async() => {
                  setViewField(true);
                  setAddField(true);
                  await handleInsertObject();
                  getReports();
             
                }}
              />

              <Button
                type="button"
                children="Mégse"
                buttonType="delete"
                onClick={() => {
                  setViewField(true);
                  setAddField(true);
                  setNewMembers([]);
                  setNewFiles([]);
                }}
              />
            </div>
          )}

          
        </div>
      </form>
    
      </div>
  <DeleteDialog open={showDeleteDialog}  setOpen={()=>setShowDeleteDialog(false)} handleRemoveElement={handleRemoveElement} />
  
      {isLoading ? (
        <p>Betöltés folyamatban...</p> // Betöltési üzenet, amíg az adatok nem érkeznek meg
      ) : (
        <SimpleTaskReports
          reports={reportList}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          viewField={viewField}
        />
      )}
 
</div>
  );
};
