import { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";


export default function SelectListUpgrage(props) {
  const [list, setList] = useState([]);
  const [filter, setFilter] = useState("");

  const fetchData = async () => {
   // console.log("teszt", formattedData);
    try {
      const requestData = {
        tables: [props.table],
        joins: [],
        conditions: props.conditions?[props.conditions]:[],
        selectedColumns: ["*"],
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getJoinedData`,
        requestData
      );
      console.log(response.data); // Itt kezelheti a választ

      const item = {
        [props.listType.id]: 0,
        [props.listType.name]: "Válassz..",
      };
      const formattedData = [item, ...response.data].map((item) => ({
        label: item[props.listType.name],
        value: item[props.listType.id],
      }));
      console.log("ggg lista", formattedData);
      setList(formattedData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: "14px",
      borderColor: state.isFocused ? "grey" : "red",
      cursor: props.disabled ? "not-allowed" : "default",
      height: "10px",
    }),
    control: base => ({
      ...base,
      height: 35,
      minHeight: 35
    })
  };

  return (
    <div className="col relative basis-64">
      <label
        id={props.id}
        className={`absolute z-10 -top-2 left-2  max-w-full truncate  px-1 text-xs font-extralight ${props.disabled ? 'text-gray-400 ' : 'text-gray-900 bg-white'}`}
      >
        {props.label}
      </label>
      <Select
        id={props.id}
        name={props.id}
        value={list.find((p) => p.value === props.value)}
        onChange={props.onChange}
        isDisabled={props.disabled}
        options={list}
        inputValue={filter}
        onInputChange={(inputValue) => setFilter(inputValue)}
        styles={customStyles}
  
      />
    </div>
  );
}