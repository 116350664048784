import { BryntumScheduler } from '@bryntum/scheduler-react';
//import { LocaleHelper } from 'bryntum-scheduler';
import { useEffect,useState,useCallback } from 'react';
import SelectInput from '../ui/SelectInput';

import './App.scss';
export default function GantComponent(){


  const apiUrl = `${process.env.REACT_APP_API_URL}/getDiagrammInfo`;


const schedulerConfig = {
  locale: 'hu', 
  startDate: new Date(2023, 6, 1), // 2023. január 1.
  endDate: new Date(), // Az aktuális dátum (mai nap)
    viewPreset       : 'day', // weekAndDay, minuteAndHour, weekAndMonth
    rowHeight        : 50,
    barMargin        : 5,
    multiEventSelect : true,
    viewPreset: {
      base   : 'weekAndMonth',
      headers: [
          {
              unit      : 'day',
              dateFormat: 'YYYY MMM DD ddd', // Mon 01 Jan 2017
          },
          {
              unit    : 'hour',
              renderer: (start, end, headerConfig, index) => {
                
                  const date = new Date();
                  const options = {  month: 'numeric', day: 'numeric',hour: 'numeric', minute: 'numeric' };
                 return new Intl.DateTimeFormat('hu-HU', options).format(start);
              }
          }
      ]
  },
    columns : [
        { text : 'Machines', field : 'name', width : 120 }
    ],

    

    crudManager : {
        transport : {
            load : {
                url : apiUrl, // Használd az előre definiált apiUrl változót
                method: 'GET', // Módosítsd a kérési módot, ha szükséges
                headers: {
                  'Content-Type': 'application/json',
                },
                credentials: 'include',
                mode: 'cors',
            }
        },
        autoLoad  : true,
        listeners : {
          // Bryntum API listener for the `hasChanges` event, triggered when any store
          // handled by the crud manager has changes
          hasChanges: bryntumEvent => handleChanges(bryntumEvent)
      }
    }
};




const handleChanges = useCallback(({ source }) => {
  const { changes } = source;

  // In a real app you would send the changes to the server here.
  console.log(changes);

  // Then you would call `source.acceptChanges()` to clear local changes
  source.acceptChanges();
}, []);




    const [modifiedTaskData,setModifyTaskData] = useState([]);
    const [resourceList,setResourceList] = useState([]);
 
    const getResourceData = async () => {
       
        try {
            const requestBody = {
              table: "MACHINES", // itt add meg az adatbázis nevet
              filterColName: "Archive" // itt add meg az oszlop nevet
            };
          
            const response = await fetch(`${process.env.REACT_APP_API_URL}/getList`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify(requestBody)
            });
          
            const data = await response.json();
            if (response.ok) {
                const resourceData = data.map((item) => ({
                    id: item.MachineId,         
                    name: item.MachineName,
                    eventColor: item.MachineColor
                  }));

                  console.log("resource data", resourceData);
                  setResourceList(resourceData)
            } else {
              console.log("Error response");
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
      };


    const getEventRows = async () => {
        try {
          const requestBody = {
            table: "simple_task",
            filterColName: "Archive"
          };
      
          const response = await fetch(`${process.env.REACT_APP_API_URL}/getList`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(requestBody)
          });
      
          const data = await response.json();
      
          if (response.ok) {

            const modifiedData = data.map((item) => ({
              id: item.SimpleTaskId,
              resourceId: item.MachineId,
              startDate: item.StartDate,
              endDate: item.EndDate,
              name: item.ResposiblePerson,
              iconCls: "b-fa b-fa-user-doctor",
            
            }));
    
            console.log("row data", modifiedData);
            setModifyTaskData(modifiedData);
          } else {
            console.log("Error response");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      
      
      useEffect(() => {
        getEventRows();
        getResourceData();
      }, []);

      
      const resultJson = {
        success: true,
        resources: {
          rows: resourceList
        },
        events: {
          rows: modifiedTaskData
        }
      };

/*
      const schedulerConfig = {

        startDate        : new Date(2022, 2, 20, 6),
        endDate          : new Date(2022, 2, 20, 20),
        viewPreset       : 'hourAndDay',
        rowHeight        : 50,
        barMargin        : 5,
        multiEventSelect : true,
    
        columns : [
            { text : 'Name', field : 'name', width : 130 }
        ],
    
        crudManager : {
            transport : {
                load : {
                    url : fetch(`${process.env.REACT_APP_API_URL}/getDiagrammInfo`, {
                        method: "GET",
                        headers: {
                          "Content-Type": "application/json",
                          "Credentials": "include"
                        },
                        // ...
                      })
                      
                }
            },
            autoLoad  : true,
            
        }
   
    };
*/
console.log("Test Result Json Data",resultJson)

if (resourceList.length === 0 || modifiedTaskData.length === 0) {
    return null; // Add meg a megfelelő loading vagy üres állapot kezelést
  }
  else{
  return (
    <div className="fixed top-16 inset-0 grid  gap-4 bg-gray-100 p-4">
        
    <div className="h-full w-full overflow-x-auto">
    <BryntumScheduler {...schedulerConfig} />
    </div>
    <link rel="stylesheet" href="node_modules/@bryntum/scheduler/scheduler.stockholm.css" id="bryntum-theme"></link>
    </div>

  );
  }

  
}