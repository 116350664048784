import { Button } from "./Button";


export default function FormHeaderAnother(props){
    return(
        <div className="flex px-3 py-3">
        <Button
          type="button"
          children="Hozzáadás"
          buttonType="add"
          disabled={props.disabled}
          onClick={() => {
            props.resetValue();
            props.setViewField(false);
            props.setAddField(false);
            props.setMemberList([]);
            props.setFileList([]);
          }
        }
        />
        <Button
          type="button"
          children="Szerkesztés"
          buttonType="edit"
          disabled={props.disabled}
          onClick={() => {
            props.setViewField(false);
            props.setEditButtonvisible(false);
          }}
        />
        <Button
          type="button"
          buttonType="delete"
          children="Törlés"
          disabled={props.disabled}
          onClick={() => props.setShowDeleteDialog(true)}
        />
        <Button
          type="button"
          buttonType="copy"
          children="Másolás"
          disabled={props.disabled}
          onClick={() => {props.handleCopyObject();props.setViewField(false); props.setEditButtonvisible(false);}}
        />
      </div>

    )
}