
  
  import { useState } from "react";
  import FormTable from "./FormTable";
  
  
    export default function FormTabs(props) {
      
      const [showJobInfo, setShowJobInfo] = useState(true);
   
  
    const tabs = [
      { id: 0, name: "Lista elemek" },
        ];
  
    const showCurrentPage = (id) => {
   
    };
   
  
      return (
          <div className="my-4 ">
          <div className="sm:hidden">
            <label htmlFor="current-tab" className="sr-only">
              Válassz egy menüpontot
            </label>
            <select
              id="current-tab"
              name="current-tab"
              className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
              onChange={(e)=>showCurrentPage(e.target.value)}
            >
              {tabs.map((tab) => (
                <option key={tab.name} value={tab.id}>{tab.name}</option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <nav className="-mb-px flex space-x-8 py-6">
            <a
               
                className={`${
                  showJobInfo
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                } cursor-pointer whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium`}
             //   onClick={() => handleToggleVisible(index)}
  
                aria-current={showJobInfo ? "page" : undefined}
              >
                Form elemek
              </a>
  

            
            </nav>
  
  {showJobInfo&& <FormTable ItemId={props.ItemId} ProjectGroupStatusId={props.ProjectGroupStatusId} disabled={props.disabled}  memberList={props.memberList}
        setMemberList={props.setMemberList} 
        newMembers = {props.newMembers}
        setNewMembers={props.setNewMembers}
         /> }
      
          </div>
        </div>
  
      )
    }