import { FormEvent, useState, useEffect } from "react";

import "flatpickr/dist/flatpickr.min.css"
import DatePicker from "../ui/DatePicker";

import axios from "axios";
import UserReports from "./UserReports";
import SelectInput from "../ui/SelectInput";
import InputField from "../ui/InputField";
import Checkbox from "../ui/Checkbox";
import { Button } from "../ui/Button";
import TextAreaInput from "../ui/TextAreaInput";
import TimeSelector from "../ui/TimeSelector";
import FormHeaderAnother from "../ui/FormHeaderAnother";
import TimePickerValue from "../ui/TimePickerValue";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';


//import { PDFViewer } from "@react-pdf/renderer";
import DeleteDialog from "../ui/DeleteDialog";

export const UserForm = (props) => {
  const isAuthenticated = localStorage.getItem("user");
  const userObj = JSON.parse(isAuthenticated);

  const [showDeleteDialog, setShowDeleteDialog] =useState(false);
  const [reportList, setReportList] = useState([]);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [owner,setOwner] = useState(userObj.UserId);


  const [selectedValues, setSelectedValues] = useState({
    NickName:"",
    UserName: "",
    Password:"",
    RoleId:"",
    ActiveId:"",
    Archive: "N",
  });


  const [nickNameValid,setNickNameValid]=useState(false);
  const [userNameValid,setUserNameValid]=useState(false);
  const [passwordValid,setPasswordValid] = useState(false);
  const [roleValid,setRoleValid]=useState(false);
  const [activeValid,setActiveValid] = useState(false);

  const formValid = nickNameValid&& userNameValid&& passwordValid&& roleValid&& activeValid

  const sortReports = (reports) => {
    const notArchiveReports = reports.sort(
      (a, b) =>
        new Date(a.StartDate).getTime() - new Date(b.StartDate).getTime()
    );   
    return [...notArchiveReports];
  };

  const getReports = async () => {
    try {
      const requestData = {
        tables: [
          "web_user",
          "roles",
          "web_user_archive"
        ],
        joins: [
          "web_user.RoleId = roles.RoleId",
          "web_user_archive.ArchiveId = web_user.ActiveId"
        ],
        conditions: ["web_user.Archive = 'N'"],
        selectedColumns: [
          "web_user.*",
          "roles.*",
        ],
      };
  
      console.log("requestData", requestData);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getJoinedData`,
        requestData
      );
      console.log("Result requestData ", response.data);
      setReportList(sortReports(response.data));
    
    } catch (error) {
      console.error(error);
    }
  };



  const updateObject = async () => {
    console.log("kiválasztott update object",selectedValues)
  
    
    const updateData = {
      tableName: "web_user",
      updates: {
        NickName:selectedValues.NickName,
        UserName: selectedValues.UserName,
        Password: selectedValues.Password,
        RoleId: selectedValues?.RoleId,
        ActiveId: selectedValues.ActiveId,
        Archive: "N",
      },
      whereClause: {
        condition: `UserId = ${selectedValues?.UserId}`,
      },
    };
    console.log("Information data", updateData);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/updateTable`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updateData),
        }
      );

      if (response.ok) {
        console.log("Status change successfully.");
      } else {
        console.error("Error changing status:", response.statusText);
      }
    } catch (error) {
      console.error("Error changing status:", error);
    }
    getReports();
  };

  const handleCopyObject = async () => {

    const insertObject = {
      NickName:selectedValues.NickName + " (Másolata)",
      UserName: selectedValues.UserName,
        Password: selectedValues.Password,
        RoleId: selectedValues?.RoleId,
        ActiveId: selectedValues?.ActiveId,
        Archive: "N",
    };

    console.log("insertObject", insertObject);
    console.log("selectedValues", selectedValues);
    try {
      // Az adatok, amiket elküldünk a Node.js szervernek

      const tableName = "web_user";
      const id= "UserId"

      // Fetch hívás a Node.js szerver felé
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/addItem`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ tableName,id, data: insertObject }), // Az adatok JSON formátumban elküldve
        }
      );

      if (response.ok) {
        const result = await response.json(); // A szerver válaszának feldolgozása
        console.log("copy result", result.insertedData);
        getReports();

        setSelectedValues(result.insertedData)
       
        //  setData(result);
      } else {
        console.error("Hiba történt a szerverrel való kommunikáció során.");
      }
    } catch (error) {
      console.error("Hiba történt:", error);
    }

  
    
  };


  const handleInsertObject = async () => {

    const insertObject = {
      NickName:selectedValues.NickName,
      UserName: selectedValues.UserName,
      Password: selectedValues.Password,
      RoleId: selectedValues?.RoleId,
      ActiveId: selectedValues?.ActiveId,
      Archive: "N",
    };
  
    console.log("new insert object", insertObject);
  
    try {
      const tableName = "web_user";
      const id= "UserId"
      const response = await fetch(`${process.env.REACT_APP_API_URL}/addItem`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ tableName, id, data: insertObject }),
      });
  
      if (response.ok) {
        const result = await response.json();
        console.log("result", result);
        await getReports();

        setSelectedValues(result.insertedData)
        setNickNameValid(false);
        setUserNameValid(false);
        setPasswordValid(false);
        setRoleValid(false);
        setActiveValid(false);
       
      } else {
        console.error("Hiba történt a szerverrel való kommunikáció során.");
      }
    } catch (error) {
      console.error("Hiba történt:", error);
    }

  };

  
  dayjs.extend(utc);


  const handleRemoveElement = async () => {
    const updateData = {
      tableName: "web_user",
      updates: {
        Archive: 'Y'
      },
      whereClause: {
        condition: `UserId = ${selectedValues?.UserId}`,
      },
    };
    console.log("Information data", updateData);
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/updateTable`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updateData),
        }
      );
  
      if (response.ok) {
        console.log("Status change successfully.");
      } else {
        console.error("Error changing status:", response.statusText);
      }
    } catch (error) {
      console.error("Error changing status:", error);
    }
  
    // Elmentett kijelölés eltávolítása
    setShowDeleteDialog(false);
  
    // Az aktuális kijelölt elem indexének megtalálása
    const currentIndex = reportList.findIndex((rep) => rep.UserId === selectedValues?.UserId);
  
    if (currentIndex !== -1) {
      if (currentIndex === reportList.length - 1) {
        // Ha az aktuális kijelölt elem az utolsó elem
        if (currentIndex > 0) {
          // Ha van előtte elem, akkor az lesz a kijelölt elem
          //setSelectedRow(reportList[currentIndex - 1]);
          const prevReport = reportList[currentIndex - 1];
          setSelectedValues(prevReport);
        } else {
          // Ha nincs előtte elem, akkor a kijelölést és az értékeket null-ra állítjuk
         // setSelectedRow(null);
         // setSelectedValues(null);
        }
      } else {
        const nextReport = reportList[currentIndex + 1];
        setSelectedValues(nextReport);
      }
    } else {
      //setSelectedRow(null);
      //setSelectedValues(null);
    }
    getReports();

  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const isAuthenticated = localStorage.getItem("user");
    const userObj = JSON.parse(isAuthenticated);
    setOwner(userObj.UserId)
    getReports().then(() => {
      setIsLoading(false); // Az adatok betöltése befejeződött
    });
    setNickNameValid(false);
    setUserNameValid(false);
    setPasswordValid(false);
    setRoleValid(false);
    setActiveValid(false);
  }, []);


  useEffect(() => {
   // getResposiblePersons();
   // listFilesForSimpleTask();
  }, [selectedValues]);
 
  const handleSelect = (selectedOption, name, selectedValidityName) => {
    setSelectedValues({
      ...selectedValues,
      [name]: selectedOption.value,
    });
    if (selectedOption.value !== 0) {
      selectedValidityName(true);
    } else {
      selectedValidityName(false);
      // Esetleg hibaüzenet megjelenítése vagy más kezelés
    }
  };



  const onSubmit = (e) => {
    e.preventDefault();
  };


  const formFields = [

    {
        Fieldtype: "textAreaInput",
        id: "NickName",
        label: "Név",
        type: "text",
        disabled: viewField,
        value: selectedValues.NickName,
       
        onChange: (e) => {
          setSelectedValues({ ...selectedValues, NickName: e.target.value });
          //setItemNameValid(e.target.validity.valid)
          setNickNameValid(e.target.value)
        },
      },
      {
        Fieldtype: "input",
        id: "UserName",
        label: "Felhasználónév",
        type: "text",
        disabled: viewField,
        value: selectedValues.UserName,
       
        onChange: (e) => {
          setSelectedValues({ ...selectedValues, UserName: e.target.value });
          setUserNameValid(e.target.value)
          //setItemNameValid(e.target.validity.valid)
        },
      },

      

    {
      Fieldtype: "input",
      id: "Password",
      label: "Jelszó",
      type: "text",
      disabled: viewField,
      value: selectedValues.Password,
      onChange: (e) => {
        setSelectedValues({
          ...selectedValues,
          Password: e.target.value,
        });
        setPasswordValid(e.target.value)
      },
    },
  

    {
        Fieldtype: "select",
        id: "RoleId",
        label: "Jogosultsági csoport",
        value: selectedValues.RoleId,
        disabled: viewField,
        table: "roles",
        valid:setRoleValid,
        listType: { id: "RoleId", name: "RoleName" },
      },

      
   
      {
        Fieldtype: "select",
        id: "ActiveId",
        label: "Aktív",
        value: selectedValues.ActiveId,
        disabled: viewField,
        valid:setActiveValid,
        table: "web_user_archive",
        listType: { id: "ArchiveId", name: "ArhiveName" },
      },
   
  ];

  return (
    <div className="fixed top-16 inset-0 grid grid-cols-5 grid-rows-[auto_1fr] gap-4 bg-gray-100 p-4">
    <div className="col-span-2 w-full overflow-x-auto">
      <form className="flex flex-col h-full overflow-x-auto bg-white shadow sm:rounded-lg" onSubmit={onSubmit}>
       
        <FormHeaderAnother disabled={!viewField}
          setViewField={setViewField} 
          setAddField={setAddField} 
          resetValue={()=>setSelectedValues({
            NickName:"",
            UserName:"",
            Password:"",
            RoleId:0,
            ActiveId:0,
          })}
            setEditButtonvisible={setEditButtonvisible} 
            handleCopyObject={handleCopyObject}
            owner={owner}
       
            setShowDeleteDialog={setShowDeleteDialog}
           />
       

        <div className="flex h-full flex-col gap-6 overflow-x-auto border-t border-gray-200 px-3 py-9">
          <div className="grid col-span-1 md:col-span-2 lg:grid-cols-3 gap-4 xl:grid-cols-4">
            {formFields.map((field) => {
              if (field.Fieldtype === "select") {
                return (
                  <SelectInput
                    key={field.id}
                    onChange={(selectedOption) =>
                      handleSelect(selectedOption, field.id,field.valid)
                    }
                    label={field.label}
                    value={selectedValues[field.id]} 
                    disabled={field.disabled}
                    id={field.id}
                    table={field.table}
                    listType={field.listType}
                  />
                );
              } else if (field.Fieldtype === "checkbox") {
                return (
                  <Checkbox
                    key={field.id}
                    id={field.id}
                    label={field.label}
                    disabled={field.disabled}
                    value={selectedValues[field.id]} 
                    onChange={field.onChange}
                    table={field.table}
                    listType={field.listType}
                  />
                );
              }
              else if (field.Fieldtype === "timePicker") {
                return (
                  <TimePickerValue 
                  label={field.label}
                  value={selectedValues[field.id]} 
                  disabled={viewField} 
                  onChange={field.onChange} 
                  />
                );
              } 

              else if (field.Fieldtype === "datePicker") {
                return (
                  <DatePicker
                  label={field.label}
                  value={selectedValues[field.id]}  
                  disabled={viewField} 
                  onChange={field.onChange}
                />
                );
              } 

               else if (field.Fieldtype === "input") {
                return (
                  <InputField
                    key={field.id}
                    id={field.id}
                    label={field.label}
                    type={field.type}
                    min={field.min}
                    max={field.max}
                    disabled={field.disabled}
                    value={selectedValues[field.id]}
                    additionalPreparedness={field.additionalPreparedness}
                    onChange={field.onChange}
                  />
                );
              }
              else if (field.Fieldtype === "textAreaInput") {
                return (
                  <TextAreaInput
                    key={field.id}
                    id={field.id}
                    label={field.label}
                    type={field.type}
                    min={field.min}
                    max={field.max}
                    disabled={field.disabled}
                    value={selectedValues[field.id]} 
                    additionalPreparedness={field.additionalPreparedness}
                    onChange={field.onChange}
                  />
                );
              }
              return null; // Kihagyhatod a nem támogatott mezőket
            })}
          </div>

    
                

          {!editButtonVisible && (
            <div className="flex justify-center gap-3">
              <Button
                type="button"
                children="Rögzítés"
                buttonType="add"
                onClick={() => {
                  setViewField(true);
                  setEditButtonvisible(true);
                  updateObject();

                  getReports();
                }}
              />

              <Button
                type="button"
                children="Mégse"
                buttonType="delete"
                onClick={() => {
                  setViewField(true);
                  setEditButtonvisible(true);
                 
                }}
              />
            </div>
          )}

          {!addField && (
            <div className="flex justify-center gap-3">
              <Button
                type="button"
                children="Rögzítés"
                buttonType="add"
                disabled={!formValid}
                onClick={async() => {
                  setViewField(true);
                  setAddField(true);
                  await handleInsertObject();
                  getReports();
             
                }}
              />

              <Button
                type="button"
                children="Mégse"
                buttonType="delete"
                onClick={() => {
                  setViewField(true);
                  setAddField(true);
                  
                }}
              />
            </div>
          )}

          
        </div>
      </form>
    
      </div>
  <DeleteDialog open={showDeleteDialog}  setOpen={()=>setShowDeleteDialog(false)} handleRemoveElement={handleRemoveElement} />
  
      {isLoading ? (
        <p>Betöltés folyamatban...</p> // Betöltési üzenet, amíg az adatok nem érkeznek meg
      ) : (
        <UserReports
          reports={reportList}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          viewField={viewField}
        />
      )}
 
</div>
  );
};
