import { useState, useEffect } from "react";

import "flatpickr/dist/flatpickr.min.css"
import DatePicker from "../ui/DatePicker";
import FormTabs from "./FormTabs";
import axios from "axios";
import ProjectGroupReports from "./ProjectGroupReports";
import SelectInput from "../ui/SelectInput";
import InputField from "../ui/InputField";
import Checkbox from "../ui/Checkbox";
import { Button } from "../ui/Button";
import TextAreaInput from "../ui/TextAreaInput";

import TimePickerValue from "../ui/TimePickerValue";



import DeleteDialog from "../ui/DeleteDialog";
import FormHeaderAnother from "../ui/FormHeaderAnother";

export const ProjectGroupForm = (props) => {
  const isAuthenticated = localStorage.getItem("user");
  const userObj = JSON.parse(isAuthenticated);

  const [showDeleteDialog, setShowDeleteDialog] =useState(false);
  const [reportList, setReportList] = useState([]);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [owner,setOwner] = useState(userObj.UserId);


  
  const [memberList,setMemberList] = useState([]);
  const [newMembers,setNewMembers]=useState([]);


  const [selectedValues, setSelectedValues] = useState({
    SubMenuId: "",
    SelectListTitle:"",
    Archive: "N",
  });


  const getReports = async () => {
    try {
      const requestData = {
        tables: [
          "project_group",
          "submenus"
        ],
        joins: [
          "project_group.SubMenuId = submenus.SubMenuId",
        ],
        conditions: ["project_group.Archive = 'N'"],
        selectedColumns: [
          "project_group.*",
          "submenus.*"
        ],
      };
  
      console.log("requestData", requestData);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getJoinedData`,
        requestData
      );
      console.log("requestData result", response.data);
      setReportList(response.data);
    
    } catch (error) {
      console.error(error);
    }
  };


  const updateObject = async () => {
    console.log("kiválasztott update object",selectedValues)

    
    const updateData = {
      tableName: "project_group",
      updates: {
        SelectListTitle: selectedValues.SelectListTitle,
        Archive: "N",
        SubMenuId: selectedValues.SubMenuId,
      },
      whereClause: {
        condition: `ProjectGroupStatusId = ${selectedValues?.ProjectGroupStatusId}`,
      },
    };
    console.log("Information data", updateData);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/updateTable`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updateData),
        }
      );

      if (response.ok) {
        console.log("Status change successfully.");
      } else {
        console.error("Error changing status:", response.statusText);
      }
    } catch (error) {
      console.error("Error changing status:", error);
    }
    getReports();
  };

  const handleCopyObject = async () => {
 
    const insertObject = {
      SelectListTitle: selectedValues.SelectListTitle + " (Másolat) ",
      Archive: "N",
      SubMenuId: selectedValues.SubMenuId,
    };

    console.log("insertObject", insertObject);
    console.log("selectedValues", selectedValues);
    try {
      // Az adatok, amiket elküldünk a Node.js szervernek

      const tableName = "project_group";
      const id= "ProjectGroupStatusId"

      // Fetch hívás a Node.js szerver felé
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/addItem`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ tableName,id, data: insertObject }), // Az adatok JSON formátumban elküldve
        }
      );

      if (response.ok) {
        const result = await response.json(); // A szerver válaszának feldolgozása
        console.log("copy result", result.insertedData);
        getReports();

        setSelectedValues(result.insertedData)

       
        //  setData(result);
      } else {
        console.error("Hiba történt a szerverrel való kommunikáció során.");
      }
    } catch (error) {
      console.error("Hiba történt:", error);
    }

  
    
  };



  
 const handleAddTaskMembers = async (taskMembers,insertId) => {
  const tableName = "project_group_listitems";
  const id= "ItemId"
  try {
    for (const item of taskMembers) {
      const insertObject = {
        ItemName: item.ItemName,
        ProjectGroupStatusId: item.ProjectGroupStatusId??insertId,
        
      };
      console.log("insertObject", insertObject);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/addItem`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ tableName, id, data: insertObject }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("result", result);
      } else {
        console.error("Hiba történt a szerverrel való kommunikáció során.");
      }
    }
  } catch (error) {
    console.error("Hiba történt:", error);
  }
  setNewMembers([]);
};


  const handleInsertObject = async () => {
    console.log("teszt idők",selectedValues.StartTime,selectedValues.EndTime)

  
    const insertObject = {
      SelectListTitle: selectedValues.SelectListTitle,
      Archive: "N",
      SubMenuId: selectedValues.SubMenuId,
    };
  
    console.log("new insert object", insertObject);
  
    try {
      const tableName = "project_group";
      const id= "ProjectGroupStatusId"
      const response = await fetch(`${process.env.REACT_APP_API_URL}/addItem`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ tableName, id, data: insertObject }),
      });
  
      if (response.ok) {
        const result = await response.json();
        console.log("result", result);
        await getReports();
        await handleAddTaskMembers(newMembers,result.insertedId);

     
       // setSelectedRow(result.insertedData);
        setSelectedValues(result.insertedData)
       
      } else {
        console.error("Hiba történt a szerverrel való kommunikáció során.");
      }
    } catch (error) {
      console.error("Hiba történt:", error);
    }

  };






  const handleRemoveElement = async () => {
    const updateData = {
      tableName: "project_group",
      updates: {
        Archive: 'Y'
      },
      whereClause: {
        condition: `ProjectGroupStatusId = ${selectedValues?.ProjectGroupStatusId}`,
      },
    };
    console.log("Information data", updateData);
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/updateTable`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updateData),
        }
      );
  
      if (response.ok) {
        console.log("Status change successfully.");
      } else {
        console.error("Error changing status:", response.statusText);
      }
    } catch (error) {
      console.error("Error changing status:", error);
    }
  
    // Elmentett kijelölés eltávolítása
    setShowDeleteDialog(false);
  
    // Az aktuális kijelölt elem indexének megtalálása
    const currentIndex = reportList.findIndex((rep) => rep.ProjectGroupStatusId === selectedValues?.ProjectGroupStatusId);
  
    if (currentIndex !== -1) {
      if (currentIndex === reportList.length - 1) {
        // Ha az aktuális kijelölt elem az utolsó elem
        if (currentIndex > 0) {
          // Ha van előtte elem, akkor az lesz a kijelölt elem
          //setSelectedRow(reportList[currentIndex - 1]);
          const prevReport = reportList[currentIndex - 1];
          setSelectedValues(...prevReport);
        } else {
          // Ha nincs előtte elem, akkor a kijelölést és az értékeket null-ra állítjuk
         // setSelectedRow(null);
         // setSelectedValues(null);
        }
      } else {
        const nextReport = reportList[currentIndex + 1];
        setSelectedValues({ 
          ...nextReport
        });
      }
    } else {
      //setSelectedRow(null);
      //setSelectedValues(null);
    }
    getReports();

  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const isAuthenticated = localStorage.getItem("user");
    const userObj = JSON.parse(isAuthenticated);
    setOwner(userObj.UserId)
    getReports().then(() => {
      setIsLoading(false); // Az adatok betöltése befejeződött
    });
  }, []);


  useEffect(() => {
    getResposiblePersons();
  }, [selectedValues]);
 
  const handleSelect = (selectedOption, name) => {
    setSelectedValues({
      ...selectedValues,
      [name]: selectedOption.value,
    });
  };


  const onSubmit = (e) => {
    e.preventDefault();
  };


  
  const getResposiblePersons = async () => {
    try {
      const requestData = {
        tables: [
          "project_group_listitems",
          "project_group",
   
        ],
        joins: [
          "project_group_listitems.ProjectGroupStatusId = project_group.ProjectGroupStatusId",
        ],
        conditions: [`project_group_listitems.ProjectGroupStatusId = ${selectedValues.ProjectGroupStatusId}`],
        selectedColumns: [
          "project_group_listitems.*",
          "project_group.*",
        ],
      };
  
      console.log("requestData", requestData);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getJoinedData`,
        requestData
      );
      console.log("extendedResult memberList", response.data);
      setMemberList(response.data);
    } catch (error) {
      console.error(error);
    }

  };



  const formFields = [
  
      {
          Fieldtype: "select",
          id: "SubMenuId",
          label: "Form",
          value: selectedValues.SubMenuId,
          disabled: viewField,
          table: "submenus",
          listType: { id: "SubMenuId", name: "SubMenuName" },
        },
        
        {
          Fieldtype: "input",
          id: "SelectListTitle",
          label: "Címke",
          type: "text",
          disabled: viewField,
          value: selectedValues.SelectListTitle,
         
          onChange: (e) => {
            setSelectedValues({ ...selectedValues, SelectListTitle: e.target.value });
            //setItemNameValid(e.target.validity.valid)
          },
        },

   
  ];

  return (
    <div className="fixed top-16 inset-0 grid grid-cols-5 grid-rows-[auto_1fr] gap-4 bg-gray-100 p-4">
    <div className="col-span-2 w-full overflow-x-auto">
      <form className="flex flex-col h-full overflow-x-auto bg-white shadow sm:rounded-lg" onSubmit={onSubmit}>
       
        <FormHeaderAnother disabled={!viewField}
          setViewField={setViewField} 
          setAddField={setAddField} 
            resetValue={()=>setSelectedValues({
              SelectListTitle:"",
              SubMenuId:0,
              Archive:"N",
            }) }
            setMemberList={setMemberList}
            setEditButtonvisible={setEditButtonvisible} 
            handleCopyObject={handleCopyObject}
            owner={owner}
     
            setShowDeleteDialog={setShowDeleteDialog}
           />

        <div className="flex h-full flex-col gap-6 overflow-x-auto border-t border-gray-200 px-3 py-9">
          <div className="grid col-span-1 md:col-span-2 lg:grid-cols-3 gap-4 xl:grid-cols-4">
            {formFields.map((field) => {
              if (field.Fieldtype === "select") {
                return (
                  <SelectInput
                    key={field.id}
                    onChange={(selectedOption) =>
                      handleSelect(selectedOption, field.id)
                    }
                    label={field.label}
                    value={selectedValues[field.id]} 
                    disabled={field.disabled}
                    id={field.id}
                    table={field.table}
                    listType={field.listType}
                  />
                );
              } else if (field.Fieldtype === "checkbox") {
                return (
                  <Checkbox
                    key={field.id}
                    id={field.id}
                    label={field.label}
                    disabled={field.disabled}
                    value={selectedValues[field.id]} 
                    onChange={field.onChange}
                    table={field.table}
                    listType={field.listType}
                  />
                );
              }
              else if (field.Fieldtype === "timePicker") {
                return (
                  <TimePickerValue 
                  label={field.label}
                  value={selectedValues[field.id]} 
                  disabled={viewField} 
                  onChange={field.onChange} 
                  />
                );
              } 

              else if (field.Fieldtype === "datePicker") {
                return (
                  <DatePicker
                  label={field.label}
                  value={selectedValues[field.id]}  
                  disabled={viewField} 
                  onChange={field.onChange}
                />
                );
              } 

               else if (field.Fieldtype === "input") {
                return (
                  <InputField
                    key={field.id}
                    id={field.id}
                    label={field.label}
                    type={field.type}
                    min={field.min}
                    max={field.max}
                    disabled={field.disabled}
                    value={selectedValues[field.id]}
                    additionalPreparedness={field.additionalPreparedness}
                    onChange={field.onChange}
                  />
                );
              }
              else if (field.Fieldtype === "textAreaInput") {
                return (
                  <TextAreaInput
                    key={field.id}
                    id={field.id}
                    label={field.label}
                    type={field.type}
                    min={field.min}
                    max={field.max}
                    disabled={field.disabled}
                    value={selectedValues[field.id]} 
                    additionalPreparedness={field.additionalPreparedness}
                    onChange={(e) => {
                      setSelectedValues({
                        ...selectedValues,
                        [field.id]: e.target.value, 
                      });
                    }}
                  />
                );
              }
              return null; // Kihagyhatod a nem támogatott mezőket
            })}
          </div>

          <FormTabs 
        //Azonosító, hogy melyik form-hoz adjuk hozzá az elemet
        ProjectGroupStatusId={selectedValues?.ProjectGroupStatusId} 
        disabled={viewField}

        //További felelősökhoz tartozó részek
        memberList={memberList}
        setMemberList={setMemberList}
        newMembers = {newMembers}
        setNewMembers={setNewMembers}
 
      />

    
                

          {!editButtonVisible && (
            <div className="flex justify-center gap-3">
              <Button
                type="button"
                children="Rögzítés"
                buttonType="add"
                onClick={() => {
                  setViewField(true);
                  setEditButtonvisible(true);
                  updateObject();
                  handleAddTaskMembers(newMembers,0);
                  getReports();
                }}
              />

              <Button
                type="button"
                children="Mégse"
                buttonType="delete"
                onClick={() => {
                  setViewField(true);
                  setEditButtonvisible(true);
                  setNewMembers([]);
                
                }}
              />
            </div>
          )}

          {!addField && (
            <div className="flex justify-center gap-3">
              <Button
                type="button"
                children="Rögzítés"
                buttonType="add"
                onClick={async() => {
                  setViewField(true);
                  setAddField(true);
                  await handleInsertObject();
                  getReports();
             
                }}
              />

              <Button
                type="button"
                children="Mégse"
                buttonType="delete"
                onClick={() => {
                  setViewField(true);
                  setAddField(true);
                  setNewMembers([]);
                }}
              />
            </div>
          )}

          
        </div>
      </form>

    
      </div>
  <DeleteDialog open={showDeleteDialog}  setOpen={()=>setShowDeleteDialog(false)} handleRemoveElement={handleRemoveElement} />
  
      {isLoading ? (
        <p>Betöltés folyamatban...</p> // Betöltési üzenet, amíg az adatok nem érkeznek meg
      ) : (
        <ProjectGroupReports
          reports={reportList}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          viewField={viewField}
        />
      )}
 
</div>
  );
};
