//import { Toggle } from "../ui";
import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { PrinterIcon } from "@heroicons/react/20/solid";
//import PrintTable from "./PrintTable";
//import Printing from "./Printing";
import dayjs from "dayjs";
const workers = [
  "Válassz...",
  "Kis Márk",
  "Nagy Andor",
  "Kovács Adél",
  "Vasas József",
  "Keresztes Péter",
  "Rónai Szilárd",
  "Hegedűs Miklós",
  "Erdős Ferenc",
  "Varga Zita",
  "Lovász Kornél",
];

const months = [
  { id: "00", name: "Összes" },
  { id: "01", name: "Január" },
  { id: "02", name: "Február" },
  { id: "03", name: "Március" },
  { id: "04", name: "Április" },
  { id: "05", name: "Május" },
  { id: "06", name: "Június" },
  { id: "07", name: "Július" },
  { id: "08", name: "Augusztus" },
  { id: "09", name: "Szeptember" },
  { id: "10", name: "Október" },
  { id: "11", name: "November" },
  { id: "12", name: "December" },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function FormList(props: any) {
  const [reports, setRepors] = useState(props.reports);
  const [groupByPalletSeqNum, setGroupByPalletSeqNum] = useState(false);

  const [worker, setWorker] = useState("00");
  const [month, setMonth] = useState("00");

  const [Project, setProject] = useState("00");
  const [shift, setShift] = useState("00");
  const [MachineType, setMachineType] = useState("00");

  const [selectedInterval, setSelectedInterval] = useState("Mind");
  const [selectedRow, setSelectedRow] = useState(null);

  const [UserList, setUserList] = useState([]);
  const [ProjectList, setProjectList] = useState([]);
  const [MachineList, setMachineList] = useState([]);
  const [shiftList, setShiftList] = useState([]);

  const [printing, setPrinting] = useState(false);

  const fetchData = async (tables, setStateFunction) => {
    try {
      const requestData = {
        tables: [tables],
        joins: [],
        conditions: [],
        selectedColumns: ["*"],
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getJoinedData`,
        requestData
      );
      console.log(response.data); // Itt kezelheti a választ
      if (setStateFunction === setUserList) {
        console.log("tulajdonos ok");
        const UserListWithAll = [
          { UserId: "00", UserName: "összes tulajdonos" },
          ...response.data,
        ];
        setStateFunction(UserListWithAll);
      } else if (setStateFunction === setProjectList) {
        const UserListWithAll = [
          { ProjectId: "00", ProjectName: "összes projekt" },
          ...response.data,
        ];
        setStateFunction(UserListWithAll);
      } 

      else if (setStateFunction === setMachineList) {
        const UserListWithAll = [
          { MachineId: "00", MachineName: "összes erőforrás" },
          ...response.data,
        ];
        setStateFunction(UserListWithAll);
      } 

      else {
        setStateFunction(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePrint = () => {
    setPrinting(true);
 //   Printing(selectedData[0].WorkerName);
    // printTable();
  };

  useEffect(() => {
    // console.log("props.reports",props.reports)
    fetchData("web_user", setUserList);
    fetchData("project", setProjectList);
    fetchData("machines", setMachineList);
    setRepors(props.reports);
  }, []);


 useEffect(() => {
    console.log("props.reports", props.reports);
    fetchData("web_user", setUserList);
    fetchData("project", setProjectList);
    fetchData("machines", setMachineList);
    setRepors(props.reports);
   // getWorkerFilterReports(worker);
  //  getMonthFilterReports(month);
  //  getProjectFilterReports(Project);
  //  getMachineFilterReports(MachineType);
   // getShiftFilterReports(shift);
  }, [props.reports]);


  const formatDate = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${month}.${day}.`;
  };

  function displayDate(inputDate) {
    const date = new Date(inputDate);
  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    return `${year}.${month}.${day} ${hours}:${minutes}`;
  }

  // Gombok kezelése
  const handleIntervalChange = (interval) => {
    setSelectedInterval(interval);
  };

  function isSameDay(date1, date2) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }


  const selectedData = useMemo(() => {
    const currentDate = new Date();
  
    switch (selectedInterval) {
      case "Ma":
        return reports.filter((person) => {
          const reportDate = new Date(person.StartDate);
          return isSameDay(reportDate, currentDate);
        });
  
      case "Héten":
        const oneWeekAgo = new Date(currentDate);
        oneWeekAgo.setDate(currentDate.getDate() - 7);
  
        return reports.filter((person) => {
          const reportDate = new Date(person.StartDate);
          return reportDate >= oneWeekAgo && reportDate <= currentDate;
        });
  
      case "Hónapban":
        return reports.filter((person) => {
          const reportDate = new Date(person.StartDate);
          return reportDate.getMonth() === currentDate.getMonth();
        });
  
      case "Mind":
        return reports;
  
      default:
        return [];
    }
  }, [selectedInterval, reports]);

  const transformReports = (reports) => {
    return reports.map((report) => {
      return {
        ...report,
        StartDate: report.StartDate.slice(5, 7)
      };
    });
  };
  
  const transformReportsBack = (reports, originalReports) => {
    // Készítsünk egy objektumot a `SimpleTaskId`-kkel az eredeti adatokhoz
    const originalReportMap = {};
    originalReports.forEach(originalReport => {
        originalReportMap[originalReport.SimpleTaskId] = originalReport;
    });

    // Most megyünk végig a módosított adatokon és frissítjük őket az eredeti adatokkal
    reports.forEach(report => {
        const originalReport = originalReportMap[report.SimpleTaskId];
        if (originalReport) {
            report.StartDate = originalReport.StartDate;
            // További mezők frissítése itt...
        }
    });
};
  

  const applyFilters = (reports, filters) => {
    return reports.filter((rep) => {
      for (const filter of filters) {
        const { key, value } = filter;
        const reportValue = parseInt(rep[key]);

        if (value !== "00" && reportValue !== parseInt(value)) {
          return false;
        }
      }
      return true;
    });
  };

  const getFilteredReports = (filters) => {
    const filteredReports = applyFilters(transformReports(props.reports), filters);

    const originalReports = [...props.reports];

    // Állítsd vissza a filterReports-t eredeti formátumra
    transformReportsBack(filteredReports, originalReports);

    
   // transformReportsBack(filteredReports);
    setRepors(filteredReports);
  };

  // ...

  const getMonthFilterReports = (num) => {
    setMonth(num);
    handleIntervalChange("Mind");
   
    getFilteredReports([
      { key: "StartDate", value: num },
      { key: "UserId", value: worker },
      {key: "MachineId", value: MachineType},
      { key: "ProjectId", value: Project } /* ... other filters ... */,
    ]);
  };

  const getWorkerFilterReports = (num) => {
    setWorker(num);
    getFilteredReports([
      { key: "StartDate", value: month },
      { key: "UserId", value: num },
       {key: "MachineId", value: MachineType},
       { key: "ProjectId", value: Project }
      
    ]);
  };

  const getProjectFilterReports = (selectedProject) => {
    setProject(selectedProject);
    getFilteredReports([
      { key: "StartDate", value: month },
      { key: "UserId", value: worker },
      {key: "MachineId", value: MachineType},
      { key: "ProjectId", value: selectedProject } /* ... other filters ... */,
    ]);
  };

  const getMachineFilterReports = (selectedMachineType) => {
    setMachineType(selectedMachineType);
    getFilteredReports([
      { key: "StartDate", value: month },
      { key: "UserId", value: worker },
      {key: "ProjectId", value: Project},
      {key: "MachineId", value: selectedMachineType}
       /* ... other filters ... */,
    ]);
  };

  const getShiftFilterReports = (selectedShift) => {
    setShift(selectedShift);
    getFilteredReports([
      { key: "StartDate", value: month },
      { key: "UserId", value: worker },
      { key: "ShiftId", value: selectedShift } /* ... other filters ... */,
    ]);
  };

  /*
const selectedData = useMemo(() => {
  const currentDate = new Date();
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
  const currentDay = String(currentDate.getDate()).padStart(2, "0");
  const formattedCurrentDate = `${currentMonth}.${currentDay}`;

  switch (selectedInterval) {
    case "Ma":
      return reports.filter((person) => {
        const parts = person.StartDate.split(' ')[0].split('.');
        const reportMonth = parts[0].padStart(2, "0");
        const reportDay = parts[1].padStart(2, "0");
        return formattedCurrentDate === `${reportMonth}.${reportDay}`;
      });
    case "Héten":
      const oneWeekAgo = new Date(currentDate);
      oneWeekAgo.setDate(currentDate.getDate() - 7);
      const formattedOneWeekAgo = formatDate(oneWeekAgo);
      return reports.filter((person) => {
        const parts = person.StartDate.split(' ')[0].split('.');
        const reportMonth = parts[0].padStart(2, "0");
        const reportDay = parts[1].padStart(2, "0");
        const StartDate = `${reportMonth}.${reportDay}`;
        return StartDate >= formattedOneWeekAgo && StartDate <= formattedCurrentDate;
      });
    case "Hónapban":
      const oneMonthAgo = new Date(currentDate);
      oneMonthAgo.setDate(currentDate.getDate() - 30);
      const formattedOneMonthAgo = formatDate(oneMonthAgo);
      return reports.filter((person) => {
        const parts = person.StartDate.split(' ')[0].split('.');
        const reportMonth = parts[0].padStart(
     */

  function sumQuantityAndTime(reports) {
    const result = [];

    const titlesMap = new Map(); // Címke és index párok tárolására

    for (const person of reports) {
      const { WorkerName, Quantity, ReportIntervall } = person;

      if (WorkerName) {
        if (!titlesMap.has(WorkerName)) {
          // Ha még nincs címke az eredmény tömbben, hozzáadjuk
          titlesMap.set(WorkerName, result.length);
          result.push({
            WorkerName,
            Quantity: 0,
            ReportIntervall: 0,
            overTime: 0,
          });
        }

        // Összeadjuk a quantity és time értékeket a megfelelő címke alatt
        const index = titlesMap.get(WorkerName);
        result[index].Quantity += parseInt(Quantity);
        result[index].ReportIntervall += parseFloat(ReportIntervall); // Szükség esetén a tizedesvesszőt ponttal helyettesítsd

        // Számoljuk az "overTime" értéket
        if (result[index].ReportIntervall > 8) {
          result[index].overTime = result[index].ReportIntervall - 8;
        } else {
          result[index].overTime = (8 - result[index].ReportIntervall) * -1;
        }
      }
    }

    // Az eredmény tömbként lesz visszaadva
    return result;
  }

  console.log("Summa", sumQuantityAndTime(reports));
  console.log("selected row", selectedRow);

  let sumTime = 0;
  let sumQuantity = 0;
  return (
    <>
          <div className="col-span-3 flex flex-col overflow-hidden bg-white shadow sm:rounded-lg ">
        <div className=" col h-fit flex-col overflow-hidden bg-white p-2 shadow sm:rounded-lg">
          <div className="flex h-full overflow-hidden border-t border-gray-200">
            <div className="w-full overflow-x-auto ">
              <div className="flex items-center justify-between gap-2 px-3 py-5">
                <span className="isolate inline-flex rounded-md shadow-sm">
                  <button
                    type="button"
                    className={`relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 ${
                      selectedInterval === "Ma"
                        ? "bg-blue-500 text-red-500"
                        : ""
                    }`}
                    onClick={() => handleIntervalChange("Ma")}
                  >
                    Ma
                  </button>
                  <button
                    type="button"
                    className={`relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 ${
                      selectedInterval === "Héten"
                        ? "bg-blue-500 text-red-500"
                        : ""
                    }`}
                    onClick={() => handleIntervalChange("Héten")}
                  >
                    Héten
                  </button>
                  <button
                    type="button"
                    className={`relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 ${
                      selectedInterval === "Hónapban"
                        ? "bg-blue-500 text-red-500"
                        : ""
                    }`}
                    onClick={() => handleIntervalChange("Hónapban")}
                  >
                    Hónapban
                  </button>
                  <button
                    type="button"
                    className={`relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 ${
                      selectedInterval === "Mind"
                        ? "bg-blue-500 text-red-500"
                        : ""
                    }`}
                    onClick={() => handleIntervalChange("Mind")}
                  >
                    Mind
                  </button>
                  <button
                    type="button"
                    className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                  >
                    Időintervallum
                  </button>
                </span>

                <div className="relative basis-64">
                  <label
                    htmlFor="worker"
                    className="absolute -top-2 left-2 inline-block max-w-full truncate bg-white px-1 text-xs font-medium text-gray-900"
                  >
                    Tulajdonos
                  </label>
                  <select
                    id="worker"
                    name="worker"
                    value={worker}
                    onChange={(e) => {
                      setWorker(e.target.value);
                      getWorkerFilterReports(e.target.value);
                    }}
                    className={classNames(
                      "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    )}
                  >
                    {UserList.map((w) => (
                      <option key={w.UserId} value={w.UserId}>
                        {w.UserName}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="relative basis-64">
                  <label
                    htmlFor="worker"
                    className="absolute -top-2 left-2 inline-block max-w-full truncate bg-white px-1 text-xs font-medium text-gray-900"
                  >
                    Hónap
                  </label>
                  <select
                    id="month"
                    name="month"
                    value={month}
                    onChange={(e) => {
                      setMonth(e.target.value);
                      handleIntervalChange("Mind");
                      getMonthFilterReports(e.target.value);
                    }}
                    className={classNames(
                      "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    )}
                  >
                    {months.map((w) => (
                      <option key={w.id} value={w.id}>
                        {w.name}
                      </option>
                    ))}
                  </select>
                </div>

                <button
                  type="button"
                  onClick={handlePrint}
                  disabled={worker == "00"}
                  className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:cursor-not-allowed disabled:opacity-50"
                >
                  Nyomtatás
                  <PrinterIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                </button>

                <span className="ml-auto truncate text-xs font-semibold text-gray-900">
                  Tulajdonos csoportosítás
                </span>
              
              </div>

              <div>
                <div className="grid grid-cols-3">
                  <div className="relative basis-64">
                    <label
                      htmlFor="Project"
                      className="absolute -top-2 left-2 inline-block max-w-full truncate bg-white px-1 text-xs font-medium text-gray-900"
                    >
                      Projekt
                    </label>
                    <select
                      id="Project"
                      name="Project"
                      value={Project}
                      onChange={(e) => {
                        setProject(e.target.value);
                        getProjectFilterReports(e.target.value);
                      }}
                      className={classNames(
                        "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      )}
                    >
                      {ProjectList.map((w) => (
                        <option key={w.ProjectId} value={w.ProjectId}>
                          {w.ProjectName}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="relative basis-64">
                    <label
                      htmlFor="MachineType"
                      className="absolute -top-2 left-2 inline-block max-w-full truncate bg-white px-1 text-xs font-medium text-gray-900"
                    >
                      Erőforrás
                    </label>
                    <select
                      id="MachineType"
                      name="MachineType"
                      value={MachineType}
                      onChange={(e) => {
                        setMachineType(e.target.value);
                        getMachineFilterReports(e.target.value);
                      }}
                      className={classNames(
                        "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      )}
                    >
                      {MachineList.map((w) => (
                        <option key={w.MachineId} value={w.MachineId}>
                          {w.MachineName}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="relative basis-64">
                    <label
                      htmlFor="shift"
                      className="absolute -top-2 left-2 inline-block max-w-full truncate bg-white px-1 text-xs font-medium text-gray-900"
                    >
                      Műszak
                    </label>
                    <select
                      id="shift"
                      name="shift"
                      value={shift}
                      onChange={(e) => {
                        setShift(e.target.value);
                        getShiftFilterReports(e.target.value);
                      }}
                      className={classNames(
                        "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      )}
                    >
                      {shiftList.map((w) => (
                        <option key={w.Id} value={w.Id}>
                          {w.ShiftName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="inline-block min-w-full border-b border-gray-200 align-middle">
                {!groupByPalletSeqNum ? (
                  <>
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                          >
                            Mező neve
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                          >
                            Referencia neve
                          </th>
                        
                          <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                          >
                            Megjelenítési típusa
                          </th>

                          <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                          >
                            Hivatkozott táblázat
                          </th>

                          <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                          >
                            Hivatkozott selectList value
                          </th>

                          <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                          >
                            Hivatkozott label név
                          </th>
                          
                          
                          <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                          >
                            Validáció
                          </th>

                   

                      
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white ">
                        {props.reports.map((person) => {
                          sumQuantity += person.Quantity;
                          sumTime += person.ReportIntervall;
                          return (
                            <tr
                              key={person.FieldId}
                              onClick={() => {
                                if (!props.viewField) return;
                                console.log("person.EndDate",person)
                                setSelectedRow(person);
                    
                              //  props.setStartDate(person.StartDate.split("T")[0])
                              //  props.setEndDate(person.EndDate.split("T")[0])
                               // props.setStartTime(dayjs(person.StartDate));
                              //  props.setEndTime(dayjs(person.EndDate));
                                props.setSelectedValues(person);
                                console.log("person", person);
                              }}
                              className={`
                      ${
                        person.ShiftId === 4 && person !== selectedRow
                          ? "bg-red-100 "
                          : ""
                      }
                      ${
                        person === selectedRow
                          ? "cursor-help  bg-gray-200 shadow-xl shadow-gray-300"
                          : "cursor-grab"
                      }
                      ${!props.viewField ? "cursor-not-allowed opacity-50" : ""}
                  `}
                            >
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                {person.FieldDisplayName}
                              </td>
                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                {person.FieldReferenceName}
                              </td>
                           
                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                {person.TypaName}
                              </td>

                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                {person.referenceTableName? person.referenceTableName:"-"}
                              </td>

                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                {person.selectListValue?person.selectListValue:"-" }
                              </td>
                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                {person.selectListLabel?person.selectListLabel:"-"}
                              </td>

                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                TODO
                              </td>


                           
                            </tr>
                          );
                        })}
                        {worker !== "00" && (
                          <tr className="bg-yellow-100">
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                              Össz.:
                            </td>
                            <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell"></td>
                            <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell"></td>
                            <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell"></td>

                            <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell"></td>

                            <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell"></td>

                            <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell"></td>

                            <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                              {sumTime} óra
                            </td>

                            <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                              {sumQuantity}
                            </td>

                            <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell"></td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                        >
                          Dolgozó
                        </th>

                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                        >
                          Törzsidő
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Lejelentett idő
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Tulóra
                        </th>

                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white ">
                      {sumQuantityAndTime(selectedData).map((person) => (
                        <tr key={person.WorkerName}>
                          <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                            {person.WorkerName}
                          </td>
                          <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                            8 óra
                          </td>
                          <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                            {person.ReportIntervall.toFixed(2)} óra
                          </td>
                          <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                            {person.overTime.toFixed(2)} óra
                          </td>

                          <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0"></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*printing && <PrintTable selectedData={selectedData} />*/}
    </>
  );
}
