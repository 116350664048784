import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import './CustomTimePicker.css';

dayjs.extend(require('dayjs/plugin/utc'));
dayjs.extend(require('dayjs/plugin/timezone'));
dayjs.tz.setDefault('Europe/Budapest');

export default function TimePickerValue(props) {
  const [value, setValue] = useState(props.value ? props.value : dayjs());

  useEffect(() => {
    // Amikor a props.value megváltozik, beállítjuk az értéket
    if (props.value) {
      setValue(dayjs(props.value));
    }
  }, [props.value]);

  useEffect(() => {
    // Amikor a props.value megváltozik, beállítjuk az értéket
    if (props.value) {
      setValue(dayjs(props.value));
    }
  }, []);


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="col relative basis-64">
        <TimePicker
          label={props.label}
          value={value ? dayjs(value) : dayjs()}
          disabled={props.disabled}
          onChange={(e) => {
            console.log("Selected time:", e);
            if (e && e.isValid()) {
              props.onChange(e);
              setValue(e);
            }
          }}
          ampm={false}
          slotProps={{
            textField: {
              size: 'small',
              className: props.disabled ? 'bg-stone-100' : '',
              //style={{opacity:'0.4', marginLeft:'92px'}},
            }
          }}
       
        />
      </div>
    </LocalizationProvider>
  );
}
