import { Button } from "./Button";
import dayjs from "dayjs";

export default function FormHeader(props){
    return(
        <div className="flex px-3 py-3">
        <Button
          type="button"
          children="Hozzáadás"
          buttonType="add"
          disabled={props.disabled}
          onClick={() => {
            props.setViewField(false);
            props.setAddField(false);
            props.setSelectedValues({
              StartDate: props.getCurrentDate(),
              EndDate: props.getCurrentDate(),
              StartTime:dayjs(),
              EndTime: dayjs().add(1, 'hour'),
              ResposiblePersonId: 0,
              SimpleTaskName:"",
              Duration:1,
              SimpleTaskDescription: "",
              Preparedness: "",
              ProjectId: 0,
              UserId: props.owner,
              MachineId: 0,
              SimpleTaskTypeId:0,
              Archive: "N",
            });
            props.setMemberList([]);
            props.setFileList([]);
          }
        }
        />
        <Button
          type="button"
          children="Szerkesztés"
          buttonType="edit"
          disabled={props.disabled}
          onClick={() => {
            props.setViewField(false);
            props.setEditButtonvisible(false);
          }}
        />
        <Button
          type="button"
          buttonType="delete"
          children="Törlés"
          disabled={props.disabled}
          onClick={() => props.setShowDeleteDialog(true)}
        />
        <Button
          type="button"
          buttonType="copy"
          children="Másolás"
          disabled={props.disabled}
          onClick={() => {props.handleCopyObject();props.setViewField(false); props.setEditButtonvisible(false);}}
        />
      </div>

    )
}