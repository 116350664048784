import { useState, useEffect } from "react";
import SelectInput from "../ui/SelectInput";
import Checkbox from "../ui/Checkbox";
import TimePickerValue from "../ui/TimePickerValue";
import DatePicker from "../ui/DatePicker";
import TextAreaInput from "../ui/TextAreaInput";
import dayjs from "dayjs";
import InputField from "../ui/InputField";

export default function SimpleTaskReports(props) {

  const [reports, setRepors] = useState(props.reports);
  const [searchInput, setSearchInput] = useState("");
  const [worker, setWorker] = useState(0);
  const [Project, setProject] = useState(0);
  const [shift, setShift] = useState(0);
  const [MachineType, setMachineType] = useState(0);
  const [resposiblePerson,setResposiblePerson] =  useState(0);
  const [startDate,setStartDate]=useState(null)
  const [endDate,setEndDate]=useState(null)

  useEffect(() => {
    setRepors(props.reports);
  }, []);

  useEffect(() => {
    setRepors(props.reports);
    handleFieldChange("startDate", startDate);
    handleFieldChange("endDate", endDate);
    handleFieldChange("worker", worker);
    handleFieldChange("resposiblePerson", resposiblePerson);
    handleFieldChange("Project", Project);
    handleFieldChange("shift", shift);
    handleFieldChange("MachineType", MachineType);
  }, [props.reports, startDate, endDate, worker, resposiblePerson, Project, shift, MachineType,searchInput]);
  


  function displayDate(inputDate) {
    const date = new Date(inputDate);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}.${month}.${day} ${hours}:${minutes}`;
  }

  const applyFilters = (reports, filters) => {
    return reports.filter((rep) => {
      for (const filter of filters) {
        const { key, value } = filter;
        const reportValue = parseInt(rep[key]);

        if (value !== 0 && reportValue !== parseInt(value)) {
          return false;
        }
      }
      return true;
    });
  };

  const getFilteredReports = (filters) => {
    const reportList = props.reports.filter((rep) =>
      rep.SimpleTaskName.toLowerCase().includes(searchInput.toLowerCase())
    );
  
    const startDateObject = startDate ? new Date(startDate) : null;
    let endDateObject = endDate ? new Date(endDate) : null;
  
    // Az endDateObject-et 1 nappal előre és hátra toljuk
    if (endDateObject) {
      endDateObject.setDate(endDateObject.getDate() + 1);
    }
  
    const dateFilteredReports = reportList.filter((rep) => {
      if (startDateObject) {
        const repStartDate = new Date(rep.StartDate);
        return startDateObject <= repStartDate && (!endDateObject || repStartDate <= endDateObject);
      }
      if (endDateObject) {
        const repStartDate = new Date(rep.StartDate);
        return endDateObject >= repStartDate 
      }
      return true;
    });
  
    const filteredReports = applyFilters(dateFilteredReports, filters);
  
 
    setRepors(filteredReports);
   
  };


  const handleFieldChange = (fieldName, value) => {
 
    // Alapértelmezett értékek
    let newWorker = worker;
    let newResposiblePerson = resposiblePerson;
    let newProject = Project;
    let newMachineType = MachineType;
    let newShift = shift;
  
    // Itt beállítod a megfelelő értékeket a fieldName alapján
    switch (fieldName) {
      case "worker":
        newWorker = value;
        break;
      case "resposiblePerson":
        newResposiblePerson = value;
        break;
      case "Project":
        newProject = value;
        break;
      case "shift":
        newShift = value;
        break;
      case "MachineType":
        newMachineType = value;
        break;
        case "startDate":
          setStartDate(value);
          getFilteredReports([
            { key: "UserId", value: newWorker },
            { key: "MachineId", value: newMachineType },
            { key: "ProjectId", value: newProject },
            { key: "SimpleTaskTypeId", value: newShift },
          ]);
          break;
        case "endDate":
          setEndDate(value);
          getFilteredReports([
            { key: "UserId", value: newWorker },
            { key: "MachineId", value: newMachineType },
            { key: "ProjectId", value: newProject },
            { key: "SimpleTaskTypeId", value: newShift },
          ]);
          break;
      default:
        break;
    }
  
   
    getFilteredReports([
      { key: "UserId", value: newWorker },
      { key: "ResposiblePersonId", value: newResposiblePerson },
      { key: "ProjectId", value: newProject },
      { key: "SimpleTaskTypeId", value: newShift },
      { key: "MachineId", value: newMachineType },
      // Egyéb filterek
    ]);
  
    // Frissítsd az állapotokat
    setWorker(newWorker);
    setResposiblePerson(newResposiblePerson);
    setProject(newProject);
    setShift(newShift);
    setMachineType(newMachineType);
  };

  const formFields = [

    {
      Fieldtype: "datePicker",
      label: "Kezdő dátum",
      value: startDate,
      onChange: (e) => {
        handleFieldChange("startDate", e);
      },
    },
    {
      Fieldtype: "datePicker",
      label: "Befejező dátum",
      value: endDate,
      onChange: (e) => {
        handleFieldChange("endDate", e);
      },
    },
    {
      Fieldtype: "select",
      id: "worker",
      label: "Tulajdonos",
      value: worker,
      table: "web_user",
      listType: { id: "UserId", name: "UserName" },
      onChange: (e) => {
        handleFieldChange("worker", e.value);
      },
    },

    {
      Fieldtype: "select",
      id: "resposiblePerson",
      label: "Felelős",
      value: resposiblePerson,
      table: "web_user",
      listType: { id: "UserId", name: "UserName" },
      onChange: (e) => {
        handleFieldChange("resposiblePerson", e.value);
      },
    },

    {
      Fieldtype: "select",
      id: "Project",
      label: "Projekt",
      value: Project,
      table: "project",
      listType: { id: "ProjectId", name: "ProjectName" },
      onChange: (e) => {
        handleFieldChange("Project", e.value);
      },
    },

    {
      Fieldtype: "select",
      id: "shift",
      label: "Feladat típusa",
      value: shift,
      table: "simple_task_types",
      listType: { id: "SimpleTaskTypeId", name: "SimpleTaskTypeName" },
      onChange: (e) => {
        handleFieldChange("shift", e.value);
      },
    },
    {
      Fieldtype: "select",
      id: "MachineType",
      label: "Erőforrás",
      value: MachineType,
      table: "machines",
      listType: { id: "MachineId", name: "MachineName" },
      onChange: (e) => {
        handleFieldChange("MachineType", e.value);
      },
    },



    {
      Fieldtype: "input",
      label: "Feladat neve",
      type: "text",
      value: searchInput,
      onChange: (e) => {
        setSearchInput(e.target.value);
      },
    },
  ];

  return (
    <>
      <div className="col-span-3 flex flex-col overflow-hidden bg-white shadow sm:rounded-lg ">
        <div className=" col  h-full flex-col overflow-hidden bg-white p-2 shadow sm:rounded-lg">
          <div className="flex h-full overflow-hidden border-t border-gray-200">
            <div className="w-full overflow-x-auto ">
              <div className=" sticky top-0 z-10 bg-white">
                <div>
                  <div className="grid col-span-1 md:col-span-2 lg:grid-cols-3 gap-4 xl:grid-cols-4 py-3 px-4">
                   
                    {formFields.map((field) => {
                      if (field.Fieldtype === "select") {
                        return (
                          <div className="relative basis-64 col">
                            <SelectInput
                              key={field.id}
                              onChange={field.onChange}
                              label={field.label}
                              value={field.id}
                              disabled={field.disabled}
                              id={field.id}
                              table={field.table}
                              listType={field.listType}
                            />
                          </div>
                        );
                      } else if (field.Fieldtype === "checkbox") {
                        return (
                          <Checkbox
                            key={field.id}
                            id={field.id}
                            label={field.label}
                            disabled={field.disabled}
                            value={field.id}
                            onChange={field.onChange}
                            table={field.table}
                            listType={field.listType}
                          />
                        );
                      } else if (field.Fieldtype === "timePicker") {
                        return (
                          <TimePickerValue
                            label={field.label}
                            value={field.id}
                            onChange={field.onChange}
                          />
                        );
                      } else if (field.Fieldtype === "datePicker") {
                        return (
                          <DatePicker
                            label={field.label}
                            value={field.id}
                            onChange={field.onChange}
                          />
                        );
                      } else if (field.Fieldtype === "input") {
                        return (
                          <InputField
                            key={field.id}
                            id={field.id}
                            label={field.label}
                            type={field.type}
                            min={field.min}
                            max={field.max}
                            disabled={field.disabled}
                            value={field.id}
                            additionalPreparedness={
                              field.additionalPreparedness
                            }
                            onChange={field.onChange}
                          />
                        );
                      } else if (field.Fieldtype === "textAreaInput") {
                        return (
                          <TextAreaInput
                            key={field.id}
                            id={field.id}
                            label={field.label}
                            type={field.type}
                            min={field.min}
                            max={field.max}
                            disabled={field.disabled}
                            value={field.id}
                            additionalPreparedness={
                              field.additionalPreparedness
                            }
                          />
                        );
                      }
                      return null; // Kihagyhatod a nem támogatott mezőket
                    })}
                      
                  </div>
                </div>
              </div>
              <div className="inline-block min-w-full border-b border-gray-200 align-middle">
                {
                  <>
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="sticky top-28  bg-slate-200">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                          >
                            Kezdő időpont
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                          >
                            Befejező időpont
                          </th>
                          <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                          >
                            Feladat
                          </th>
                          <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                          >
                            Projekt
                          </th>

                          <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                          >
                            Tulajdonos
                          </th>

                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Felelős
                          </th>

                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Készültség
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Gép
                          </th>

                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Feladat típusa
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white ">
                        {reports.map((person) => {
                          console.log("selected person",person)
                          return (
                            <tr
                              key={person.email}
                              onClick={() => {
                                if (!props.viewField) return;

                                props.setSelectedValues({
                                  ...person,
                                  StartDate: person.StartDate.split("T")[0],
                                  EndDate: person.EndDate.split("T")[0],
                                  StartTime: dayjs(person.StartDate),
                                  EndTime: dayjs(person.EndDate),
                                  StatusId: person.ItemId
                                });

                                console.log("person", person);
                              }}
                              className={`
                      ${
                        person.ShiftId === 4 && person !== props.selectedValues
                          ? "bg-red-100 "
                          : ""
                      }
                      ${
                        person?.SimpleTaskId ===
                        props.selectedValues?.SimpleTaskId
                          ? "cursor-help  bg-gray-200 shadow-xl shadow-gray-300"
                          : "cursor-grab"
                      }
                      ${!props.viewField ? "cursor-not-allowed opacity-50" : ""}
                  `}
                            >
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                {displayDate(person.StartDate)}
                              </td>
                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                {displayDate(person.EndDate)}
                              </td>
                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                {person.SimpleTaskName}
                              </td>
                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                {person.ProjectName}
                              </td>

                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                {person.UserName}
                              </td>

                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                {person.ResponsiblePersonName}
                              </td>

                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                {person.Preparedness}%
                              </td>
                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                {person.MachineName}
                              </td>

                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                {person.SimpleTaskTypeName}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
