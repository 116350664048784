
import { FormEvent, useState, useEffect } from "react";

import "flatpickr/dist/flatpickr.min.css"
import DatePicker from "../ui/DatePicker";

import axios from "axios";
import ProjectReports from "./ProjectReports";
import SelectInput from "../ui/SelectInput";
import InputField from "../ui/InputField";
import Checkbox from "../ui/Checkbox";
import { Button } from "../ui/Button";
import TextAreaInput from "../ui/TextAreaInput";

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import FormTabs from "./FormTabs";
import FormHeader from "../ui/FormHeader";
//import { PDFViewer } from "@react-pdf/renderer";
import DeleteDialog from "../ui/DeleteDialog";
import SelectListUpgrage from "../ui/SelectListUpgrade";
import FormHeaderAnother from "../ui/FormHeaderAnother";

export const ProjectForm = (props) => {
  const isAuthenticated = localStorage.getItem("user");
  const userObj = JSON.parse(isAuthenticated);

  const [showDeleteDialog, setShowDeleteDialog] =useState(false);
  const [reportList, setReportList] = useState([]);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [owner,setOwner] = useState(userObj.UserId);

  const [memberList,setMemberList] = useState([]);
  const [fileList,setFileList] = useState([]);
  
  const [newMembers,setNewMembers]=useState([]);
  const [newFiles,setNewFiles] = useState([]);

  const [selectedValues, setSelectedValues] = useState({
    ProjectStartDate: "",
    ProjectEndDate:"",
    Duration:1,
    ProjectName:"",
    companyId: "",
    ProjectGroupId: "",
    StatusId:"",
    ProjectPrepare: "",
    UserId: owner,
    ProjectDescription:"",
    Archive: "N",
  });

  const [projectNameValid,setProjectNameValid]=useState(false);
  const [companyValid,setCompanyValid]=useState(false);
  const [statusValid,setStatusValid] = useState(false);
  const [projectGroupValid,setProjectGroupValid]=useState(false);
  const [prepareValid,setPrepareValid] = useState(false);


  const formValid = projectNameValid&&companyValid&&statusValid&&projectGroupValid&&prepareValid
 


  const getCurrentDate = () => {
    const todayDate = new Date();
    const year = todayDate.getFullYear(); // Évszám
    const month = todayDate.getMonth() + 1; // Hónap (0-tól indexelődik, ezért +1)
    const day = todayDate.getDate(); // Nap
    // Formázás: "év-hónap-nap" (pl. "2023-10-06")
    const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${
      day < 10 ? "0" : ""
    }${day}`;
    return formattedDate;
  };

  const sortReports = (reports) => {
    const notArchiveReports = reports.sort(
      (a, b) =>
        new Date(a.ProjectStartDate).getTime() - new Date(b.ProjectStartDate).getTime()
    );   
    return [...notArchiveReports];
  };

  const getReports = async () => {
    try {
      const requestData = {
        tables: [
          "project",
          "company",
          "web_user",
          "statusitems",
          "project_group_listitems"
          
        ],
        joins: [
          "project.companyId = company.companyId",
          "project.UserId = web_user.UserId",
          "project.StatusId = statusitems.ItemId",
          "project.ProjectGroupId = project_group_listitems.ItemId",
        ],
        conditions: ["project.Archive = 'N'"],
        selectedColumns: [
          "project.*",
          "company.*",
          "web_user.*", // web_user mezői
          "statusitems.ItemId AS StatusId",
          "statusitems.ItemName AS StatusName",
          "project_group_listitems.ItemName AS groupItemName",
          "project_group_listitems.ItemId AS ProjectGroupId"
        ],
      };
  
      console.log("requestData", requestData);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getJoinedData`,
        requestData
      );
      console.log("Response Data result",response.data)
      setReportList(sortReports(response.data));
    
    } catch (error) {
      console.error("Error fetching data",error);
    }
  };


  const getResposiblePersons = async () => {
    try {
      const requestData = {
        tables: [
          "project_member",
          "project",
          "web_user",      
        ],
        joins: [
          "project_member.ProjectId = project.ProjectId",
          "project_member.UserId = web_user.UserId",
        ],
        conditions: [`project_member.ProjectId = ${selectedValues.ProjectId}`],
        selectedColumns: [
          "project.*",
          "project.*",
          "web_user.*"
        ],
      };
  
      console.log("requestData", requestData);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getJoinedData`,
        requestData
      );
      console.log("extendedResult memberList", response.data);
      setMemberList(response.data);
    } catch (error) {
      console.error(error);
    }

  };


  
  const listFilesForSimpleTask = async () => {
    try{
    const requestData = {
      tables: [
        "project_attachment",  
      ],
      joins: [],
      conditions: [`ProjectId = ${selectedValues.ProjectId}`],
      selectedColumns: [
        "project_attachment.*",
      ],
    };

    console.log("Attachments", requestData);
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/getJoinedData`,
      requestData
    );
    setFileList(response.data);
  } catch (error) {
    console.error(error);
  }
  };




 const handleAddTaskMembers = async (taskMembers,insertId) => {
  const tableName = "project_resp_personlist";
  const id= "RespPersonProjectId"
  try {
    for (const item of taskMembers) {
      const insertObject = {
        UserId: item.UserId,
        ProjectId: item.ProjectId??insertId,
        RoleName: item.RoleName,
      };
      console.log("insertObject", insertObject);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/addItem`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ tableName, id, data: insertObject }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("result", result);
      } else {
        console.error("Hiba történt a szerverrel való kommunikáció során.");
      }
    }
  } catch (error) {
    console.error("Hiba történt:", error);
  }
  setNewMembers([]);
};


const handleFileUpload = async (files, id) => {
  try {
    for (const item of files) {
      item.append("ProjectId",id); 
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/upload-endpoint`, {
          method: "POST",
          body: item,
        });

        if (response.ok) {
          const data = await response.json();
          console.log("Sikeres feltöltés:", data);
        } else {
          console.error("Hiba történt a feltöltés során:", response.statusText);
        }
      } catch (error) {
        console.error("Hiba történt a fetch során:", error);
      }
    }
  } catch (error) {
    console.error("Hiba történt:", error);
  }
  setNewFiles([]);
  //setFileList([]);
};




  const updateObject = async () => {
    console.log("kiválasztott update object",selectedValues)
  // const sTime = selectedValues.StartTime.hour()+":"+ selectedValues.StartTime.minute()
  //  const eTime = selectedValues.EndTime.hour()+":"+ selectedValues.EndTime.minute()
  //  console.log("teszt adatok",startDate,sTime)
    const ProjectStartDate = formatDate(selectedValues.ProjectStartDate);
    const ProjectEndDate = formatDate(selectedValues.ProjectEndDate);
    
    const updateData = {
      tableName: "project",
      updates: {
        ProjectStartDate,
        ProjectEndDate,
        ProjectName: selectedValues.ProjectName,
        ProjectPrepare: selectedValues?.ProjectPrepare,
        ProjectDescription: selectedValues.ProjectDescription,
        UserId: selectedValues.UserId,
        ProjectGroupId: selectedValues.ProjectGroupId,
        StatusId: selectedValues.StatusId,
        Archive: "N",
      },
      whereClause: {
        condition: `ProjectId = ${selectedValues?.ProjectId}`,
      },
    };
    console.log("Information data", updateData);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/updateTable`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updateData),
        }
      );

      if (response.ok) {
        console.log("Status change successfully.");
      } else {
        console.error("Error changing status:", response.statusText);
      }
    } catch (error) {
      console.error("Error changing status:", error);
    }
    getReports();
  };

  const handleCopyObject = async () => {
   // const sTime = selectedValues.StartTime.hour()+":"+ selectedValues.StartTime.minute()
   // const eTime = selectedValues.EndTime.hour()+":"+ selectedValues.EndTime.minute()
   // console.log("teszt adatok",startDate,sTime)
    const ProjectStartDate = formatDate(selectedValues.ProjectStartDate);
    const ProjectEndDate = formatDate(selectedValues.ProjectEndDate);

    const insertObject = {
      ProjectStartDate: ProjectStartDate,
      ProjectEndDate: ProjectEndDate,
      ProjectName:selectedValues.ProjectName + "(Másolat)",
      ProjectDescription: selectedValues.ProjectDescription,
      ProjectPrepare: selectedValues.ProjectPrepare,
      Archive: "N",
      CompanyId: selectedValues.companyId,
      UserId: selectedValues.UserId,
      ProjectGroupId: selectedValues.ProjectGroupId,
      StatusId: selectedValues.StatusId,
    };

    console.log("insertObject", insertObject);
    console.log("selectedValues", selectedValues);
    try {
      // Az adatok, amiket elküldünk a Node.js szervernek

      const tableName = "project";
      const id= "ProjectId"

      // Fetch hívás a Node.js szerver felé
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/addItem`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ tableName,id, data: insertObject }), // Az adatok JSON formátumban elküldve
        }
      );

      if (response.ok) {
        const result = await response.json(); // A szerver válaszának feldolgozása
        console.log("copy result", result.insertedData);
        getReports();
       // setSelectedRow(result.insertedData);
        //setSelectedValues(result.insertedData);

        setSelectedValues({ ...result.insertedData, 
          ProjectStartDate: result.insertedData.ProjectStartDate.split("T")[0],
           ProjectEndDate: result.insertedData.ProjectEndDate.split("T")[0], 
           StartTime: dayjs(result.insertedData.ProjectStartDate) , 
          EndTime: dayjs(result.insertedData.ProjectEndDate)
          })

       
        //  setData(result);
      } else {
        console.error("Hiba történt a szerverrel való kommunikáció során.");
      }
    } catch (error) {
      console.error("Hiba történt:", error);
    }

  
    
  };


  const handleInsertObject = async () => {
    console.log("teszt idők",selectedValues.StartTime,selectedValues.EndTime)

  //  const sTime = selectedValues.StartTime.hour()+":"+ selectedValues.StartTime.minute()
  //  const eTime = selectedValues.EndTime.hour()+":"+ selectedValues.EndTime.minute()

   // const sTime = selectedValues.StartTime.hour()+":"+ selectedValues.StartTime.minute()
   // const eTime = selectedValues.EndTime.hour()+":"+ selectedValues.EndTime.minute()
 
    const ProjectStartDate = formatDate(selectedValues.ProjectStartDate);
    const ProjectEndDate = formatDate(selectedValues.ProjectEndDate);
  
    const insertObject = {
      ProjectStartDate,
      ProjectEndDate,
   //   StartTime: sTime,
   //   EndTime: eTime,
      ProjectName: selectedValues.ProjectName,
      ProjectPrepare: selectedValues?.ProjectPrepare,
      ProjectDescription: selectedValues.ProjectDescription,
      ProjectGroupId: selectedValues.ProjectGroupId,
      CompanyId: selectedValues.companyId,
      UserId: selectedValues.UserId,
      StatusId:selectedValues.StatusId,
      Archive: "N",
    };
  
    console.log("new insert object", insertObject);
  
    try {
      const tableName = "project";
      const id= "ProjectId"
      const response = await fetch(`${process.env.REACT_APP_API_URL}/addItem`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ tableName, id, data: insertObject }),
      });
  
      if (response.ok) {


        const result = await response.json();
        console.log("result", result);
        await getReports();
        await handleAddTaskMembers(newMembers,result.insertedId);
        await handleFileUpload(newFiles,result.insertedId)

     
       // setSelectedRow(result.insertedData);
        setSelectedValues({ ...result.insertedData, 
          ProjectStartDate: result.insertedData.ProjectStartDate.split("T")[0],
           ProjectEndDate: result.insertedData.ProjectEndDate.split("T")[0], 
           StartTime: dayjs(result.insertedData.ProjectStartDate) , 
          EndTime: dayjs(result.insertedData.ProjectEndDate)
          })

          
        setProjectNameValid(false);
        setCompanyValid(false);
        setStatusValid(false);
        setProjectGroupValid(false);
        setPrepareValid(false);
       
      } else {
        console.error("Hiba történt a szerverrel való kommunikáció során.");
      }
    } catch (error) {
      console.error("Hiba történt:", error);
    }

  };

  
  dayjs.extend(utc);

  const formatDate = (date) => {
    const [year, month, day] = date.split('-');
  //  const [hour, minute] = time.split(':');
  
    const formattedDate = dayjs()
      .year(year)
      .month(month - 1)
      .date(day)
    //  .hour(hour)
    //  .minute(minute)
      .format(); // Most a helyi időzóna és formátum lesz alkalmazva
  
    return formattedDate;
  };


  const handleRemoveElement = async () => {
    const updateData = {
      tableName: "project",
      updates: {
        Archive: 'Y'
      },
      whereClause: {
        condition: `ProjectId = ${selectedValues?.ProjectId}`,
      },
    };
    console.log("Information data", updateData);
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/updateTable`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updateData),
        }
      );
  
      if (response.ok) {
        console.log("Status change successfully.");
      } else {
        console.error("Error changing status:", response.statusText);
      }
    } catch (error) {
      console.error("Error changing status:", error);
    }
  
    // Elmentett kijelölés eltávolítása
    setShowDeleteDialog(false);
  
    // Az aktuális kijelölt elem indexének megtalálása
    const currentIndex = reportList.findIndex((rep) => rep.ProjectId === selectedValues?.ProjectId);
  
    if (currentIndex !== -1) {
      if (currentIndex === reportList.length - 1) {
        // Ha az aktuális kijelölt elem az utolsó elem
        if (currentIndex > 0) {
          // Ha van előtte elem, akkor az lesz a kijelölt elem
          //setSelectedRow(reportList[currentIndex - 1]);
          const prevReport = reportList[currentIndex - 1];
          setSelectedValues({ 
            ...prevReport, 
            ProjectStartDate: prevReport.ProjectStartDate.split("T")[0],
            ProjectEndDate: prevReport.ProjectEndDate.split("T")[0], 
            StartTime: dayjs(prevReport.ProjectStartDate), 
            EndTime: dayjs(prevReport.ProjectEndDate)
          });
        } else {
          // Ha nincs előtte elem, akkor a kijelölést és az értékeket null-ra állítjuk
         // setSelectedRow(null);
         // setSelectedValues(null);
        }
      } else {
        const nextReport = reportList[currentIndex + 1];
        setSelectedValues({ 
          ...nextReport, 
          ProjectStartDate: nextReport.ProjectStartDate.split("T")[0],
          ProjectEndDate: nextReport.ProjectEndDate.split("T")[0], 
          StartTime: dayjs(nextReport.ProjectStartDate), 
          EndTime: dayjs(nextReport.ProjectEndDate)
        });
      }
    } else {
      //setSelectedRow(null);
      //setSelectedValues(null);
    }
    getReports();

  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const isAuthenticated = localStorage.getItem("user");
    const userObj = JSON.parse(isAuthenticated);
    setOwner(userObj.UserId)
    getReports().then(() => {
      setIsLoading(false); // Az adatok betöltése befejeződött
    });

   setProjectNameValid(false);
    setCompanyValid(false);
    setStatusValid(false);
    setProjectGroupValid(false);
    setPrepareValid(false);
  }, []);


  useEffect(() => {
   // getResposiblePersons();
    //listFilesForSimpleTask();

    
  }, [selectedValues]);
 
  const handleSelect = (selectedOption, name, selectedValidityName) => {
    setSelectedValues({
      ...selectedValues,
      [name]: selectedOption.value,
    });
    if (selectedOption.value !== 0) {
      selectedValidityName(true);
    } else {
      selectedValidityName(false);
      // Esetleg hibaüzenet megjelenítése vagy más kezelés
    }
  };


  const onSubmit = (e) => {
    e.preventDefault();
  };


  const formFields = [

    {
        Fieldtype: "textAreaInput",
        id: "ProjectName",
        label: "Projekt neve",
        type: "text",
        disabled: viewField,
        value: selectedValues.ProjectName,
       
        onChange: (e) => {
          setSelectedValues({ ...selectedValues, ProjectName: e.target.value });
          
          setProjectNameValid(e.target.value)
        },
      },
  
      {
        Fieldtype: "select",
        id: "companyId",
        label: "cég",
        value: selectedValues.companyId,
        disabled: viewField,
        table: "company",
        valid: setCompanyValid,
        listType: { id: "companyId", name: "companyName" },
      },
  
   
  /*
      {
        Fieldtype: "select",
        id: "status",
        label: "Státusz",
        conditions:"StatusId=1",
        value: selectedValues.StatusId,
        disabled: viewField,
        table: "statusitems",
        listType: { id: "ItemId", name: "ItemName" },
      },
    */  
      {
        Fieldtype: "select",
        id: "StatusId",
        label: "Státusz",
        value: selectedValues.StatusId,
        table: "statusitems",
        disabled: viewField,
        valid: setStatusValid,
        conditions:"StatusId=1",
        listType: { id: "ItemId", name: "ItemName" },
      },
      

    {
      Fieldtype: "datePicker",
      label: "Kezdő dátum",
      id: "ProjectStartDate",
      value: selectedValues.ProjectStartDate,
      disabled: viewField,
      onChange: (e) => {setSelectedValues({ ...selectedValues, ProjectStartDate: e })},
    },

   

    {
      Fieldtype: "datePicker",
      label: "Befejező dátum",
      id: "ProjectEndDate",
      value: selectedValues.ProjectEndDate,
      disabled: viewField,
      onChange: (e) => {setSelectedValues({ ...selectedValues, ProjectEndDate: e })},
    },
  
    /*
    {
        Fieldtype: "select",
        id: "GroupId",
        label: "Projekt csoport",
        value: selectedValues.GroupId,
        disabled: viewField,
        table: "groupes",
        listType: { id: "GroupId", name: "GroupName" },
      },
*/
      {
        Fieldtype: "select",
        id: "ProjectGroupId",
        label: "Projekt csoport",
        value: selectedValues.ProjectGroupId,
        table: "project_group_listitems",
        valid: setProjectGroupValid,
        disabled: viewField,
        conditions:"ProjectGroupStatusId=3",
        listType: { id: "ItemId", name: "ItemName" },
      },
      

 
   

    {
        Fieldtype: "input",
        id: "ProjectPrepare",
        label: "Készültség",
        type: "number",
        min: 1,
        max: 100,
        disabled: viewField,
        value: selectedValues.ProjectPrepare,
       
        onChange: (e) => {
          setSelectedValues({ ...selectedValues, ProjectPrepare: e.target.value });
          setPrepareValid(e.target.value)
        },
      }, 

      {
        Fieldtype: "select",
        id: "UserId",
        label: "Tulajdonos",
        value: selectedValues.UserId,
        disabled: viewField,
        table: "web_user",
        listType: { id: "UserId", name: "UserName" },
      },
  ];

  return (
    <div className="fixed top-16 inset-0 grid grid-cols-5 grid-rows-[auto_1fr] gap-4 bg-gray-100 p-4">
    <div className="col-span-2 w-full overflow-x-auto">
      <form className="flex flex-col h-full overflow-x-auto bg-white shadow sm:rounded-lg" onSubmit={onSubmit}>
       
      

<FormHeaderAnother disabled={!viewField}
          setViewField={setViewField} 
          setAddField={setAddField} 
            resetValue={()=>setSelectedValues({
              ProjectStartDate:getCurrentDate(),
              ProjectEndDate:getCurrentDate(),
              Duration:1,
              ProjectName:"",
              companyId: "",
              ProjectGroupId: 0,
              StatusId:0,
              ProjectPrepare: "",
              UserId: owner,
              ProjectDescription:"",
              Archive: "N",
            }) }
            setMemberList={setMemberList}
            setEditButtonvisible={setEditButtonvisible} 
            handleCopyObject={handleCopyObject}
            owner={owner}
     
            setShowDeleteDialog={setShowDeleteDialog}
           />
       

        <div className="flex h-full flex-col gap-6 overflow-x-auto border-t border-gray-200 px-3 py-9">
          <div className="grid col-span-1 md:col-span-2 lg:grid-cols-3 gap-4 xl:grid-cols-4">
            {formFields.map((field) => {
              if (field.Fieldtype === "select") {
                return (
                  <SelectListUpgrage
                    key={field.id}
                    onChange={(selectedOption) =>
                      handleSelect(selectedOption, field.id,field.valid)
                    }
                    label={field.label}
                    value={selectedValues[field.id]} 
                    conditions={field.conditions}
                    disabled={field.disabled}
                    id={field.id}
                    table={field.table}
                    listType={field.listType}
                  />
                );
              } 
          
              else if (field.Fieldtype === "checkbox") {
                return (
                  <Checkbox
                    key={field.id}
                    id={field.id}
                    label={field.label}
                    disabled={field.disabled}
                    value={selectedValues[field.id]} 
                    onChange={field.onChange}
                    table={field.table}
                    listType={field.listType}
                  />
                );
              }
            

              else if (field.Fieldtype === "datePicker") {
                return (
                  <DatePicker
                  label={field.label}
                  value={selectedValues[field.id]}  
                  disabled={viewField} 
                  onChange={field.onChange}
                />
                );
              } 

               else if (field.Fieldtype === "input") {
                return (
                  <InputField
                    key={field.id}
                    id={field.id}
                    label={field.label}
                    type={field.type}
                    min={field.min}
                    max={field.max}
                    disabled={field.disabled}
                    value={selectedValues[field.id]}
                    additionalProjectPrepare={field.additionalProjectPrepare}
                    onChange={field.onChange}
                  />
                );
              }
              else if (field.Fieldtype === "textAreaInput") {
                return (
                  <TextAreaInput
                    key={field.id}
                    id={field.id}
                    label={field.label}
                    type={field.type}
                    min={field.min}
                    max={field.max}
                    disabled={field.disabled}
                    value={selectedValues[field.id]} 
                    additionalProjectPrepare={field.additionalProjectPrepare}
                    onChange={field.onChange}
                  />
                );
              }
              return null; // Kihagyhatod a nem támogatott mezőket
            })}
          </div>

        {/*

            <FormTabs 
        //Azonosító, hogy melyik form-hoz adjuk hozzá az elemet
        ProjectId={selectedValues?.ProjectId} 
        disabled={viewField}

        //További felelősökhoz tartozó részek
        memberList={memberList}
        setMemberList={setMemberList}
        newMembers = {newMembers}
        setNewMembers={setNewMembers}

        // Drag and Drop tartozó formrészek
        fileList={fileList}
        setFileList={setFileList}
        newFiles={newFiles}
        setNewFiles={setNewFiles} 

        //Leírás formrészhez szükséges mezők
        formId={"ProjectDescription"}
        value={selectedValues.ProjectDescription}
        onChange={(e) => {
          setSelectedValues({
            ...selectedValues,
            ProjectDescription: e.target.value,
          })
          }} 
      />

         */}
  
                

          {!editButtonVisible && (
            <div className="flex justify-center gap-3">
              <Button
                type="button"
                children="Rögzítés"
                buttonType="add"
                onClick={() => {
                  setViewField(true);
                  setEditButtonvisible(true);
                  updateObject();
                  handleAddTaskMembers(newMembers,0);
                  handleFileUpload(newFiles,selectedValues.ProjectId);
                  getReports();
                }}
              />

              <Button
                type="button"
                children="Mégse"
                buttonType="delete"
                onClick={() => {
                  setViewField(true);
                  setEditButtonvisible(true);
                  setNewMembers([]);
                  setNewFiles([]);
                }}
              />
            </div>
          )}

          {!addField && (
            <div className="flex justify-center gap-3">
              <Button
                type="button"
                children="Rögzítés"
                disabled={!formValid}
                buttonType="add"
                onClick={async() => {
                  setViewField(true);
                  setAddField(true);
                  await handleInsertObject();
                  getReports();
             
                }}
              />

              <Button
                type="button"
                children="Mégse"
                buttonType="delete"
                onClick={() => {
                  setViewField(true);
                  setAddField(true);
                  setNewMembers([]);
                  setNewFiles([]);
                }}
              />
            </div>
          )}

          
        </div>
      </form>
    
      </div>
  <DeleteDialog open={showDeleteDialog}  setOpen={()=>setShowDeleteDialog(false)} handleRemoveElement={handleRemoveElement} />
  
      {isLoading ? (
        <p>Betöltés folyamatban...</p> // Betöltési üzenet, amíg az adatok nem érkeznek meg
      ) : (
        <ProjectReports
          reports={reportList}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          viewField={viewField}
        />
      )}
 
</div>
  );
};