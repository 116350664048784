import { useEffect,useState } from "react";
import ProjectInformation from "./ProjectInformation";
import { AddProjectForm } from "./AddProjectForm";

export default function ProjectList() {

    const [project,setProjects]=useState([]);
    const [projectId,setProjectId]=useState(null);

    const [showAddDialog, setShowAddDialog] = useState(false);



      const fetchData = async () => {
        try {
          const requestBody = {
            table: "project", // itt add meg az adatbázis nevet
            filterColName: "Archive" // itt add meg az oszlop nevet
          };
        
          const response = await fetch(`${process.env.REACT_APP_API_URL}/getList`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(requestBody)
          });
        
          const data = await response.json();
          console.log("project data", data);
          if (response.ok) {
            console.log("project data", data);
            setProjects(data);
          } else {
            console.log("Error response");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
        };
      
    
        


      useEffect(() => {
        fetchData();
      }, [setProjects]);

      
      useEffect(() => {
        fetchData();
      }, []);
    
      return (
        <>

       
        <div className="grid grid-cols-4  ">
        <div className="mx-5 mb-2 col-span-4">
              <button
                type="button"
                onClick={() => {
                  setShowAddDialog(true);
                }}
                className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Hozzáadás
              </button>
        </div>
        <div className="col-span-1 overflow-y-auto px-3">
  {project && project.length > 0 ? (
    project.map((p) => (
      <div
        key={p.ProjectId}
        onClick={() => setProjectId(p.ProjectId)}
        className="flex flex-col items-center h-16 justify-center text-center bg-white hover:bg-gray-200 border border-gray-200 rounded-md py-3 cursor-pointer p-4"
      >
        <p className="text-d font-semibold text-gray-800 text-sm">
          {p.ProjectName}
        </p>
      </div>
    ))
  ) : (
    <p>Nincs elérhető projekt.</p>
  )}
</div>
        <div className="col-span-3">
          <ProjectInformation projectId={projectId} fetchData={fetchData} />
        </div>

        <AddProjectForm
  show={showAddDialog}
  onHide={() => setShowAddDialog(false)}
  setProjects={setProjects}
  project={project} 
  fetchData={fetchData}
/>
      </div>
     
       
      </>
      );
    
}
