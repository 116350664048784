
  import { useState,useEffect } from "react"
  import axios from "axios"
import TodoCard from "./TodoCard";
import SelectListUpgrage from "../ui/SelectListUpgrade";
  export default function MyTodoTable() {
    const [mytodo,setMyTodo] = useState([]);

    const isAuthenticated = localStorage.getItem("user");
    const userObj = JSON.parse(isAuthenticated);
 

    const [selectedStatusId,setSelectedStatusId]=useState(null)

    const getMyTodoList = async () => {
        try {
          let condition=""
          if(selectedStatusId===null || selectedStatusId===0){
            condition=`simple_task.Archive = 'N' and web_user.UserId=${userObj.UserId}`
          }
          else{
            condition=`simple_task.Archive = 'N' and web_user.UserId=${userObj.UserId} and simple_task.StatusId=${selectedStatusId}`
          }
              const requestData = {
                tables: [
                  "simple_task",
                  "project",
                  "web_user",
                  "web_user AS responsible_user", // Alias létrehozása itt
                  "machines",
                  "simple_task_types",
                  "statusitems"
                  
                ],
                joins: [
                  "simple_task.ProjectId = project.ProjectId",
                  "simple_task.UserId = web_user.UserId",
                  "simple_task.ResposiblePersonId = responsible_user.UserId", // Új feltétel hozzáadva
                  "simple_task.MachineId = machines.MachineId",
                  "simple_task.SimpleTaskTypeId = simple_task_types.SimpleTaskTypeId",
                  "simple_task.StatusId = statusitems.ItemId"
                ],
                conditions: [condition],
                selectedColumns: [
                  "simple_task.*",
                  "project.*",
                  "web_user.*", // web_user mezői
                  "responsible_user.UserName AS ResponsiblePersonName", // responsible_user neve Alias-ként
                  "machines.*",
                  "simple_task_types.*",
                  "statusitems.*"
                ],
              };
      
          console.log("requestData", requestData);
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/getJoinedData`,
            requestData
          );
          console.log("Response Data result",response.data)
          setMyTodo(response.data);
        
        } catch (error) {
          console.error("Error fetching data",error);
        }
      };


    useEffect(()=>{
        getMyTodoList();
    },[])
    useEffect(()=>{
      getMyTodoList();
  },[selectedStatusId])

    return (
      <div className="fixed top-16 inset-0  gap-4 bg-gray-100 p-4 h-full overflow-x-auto">
      <div className=" shadow-md p-4 rounded-md">

      <div className="flex justify-between pb-6">
  <button className="flex-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-xl mx-1">
    Saját
  </button>
  <button className="flex-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-xl mx-1">
    Telephely
  </button>
  <button className="flex-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-xl mx-1">
    Céges
  </button>
</div>

        <div className="grid grid-cols-2">

          <div className="col">
          <SelectListUpgrage
        key={selectedStatusId}
        onChange={(e)=>{setSelectedStatusId(e.value); }}
        label={"Státusz"}
        value={selectedStatusId} 
        conditions={"StatusId=2"}
        disabled={false}
        id={"selectedStatus"}
        table={"statusitems"}
        listType={{ id: "ItemId", name: "ItemName" }}
        />
          </div>

          <div className="col">
          <SelectListUpgrage
        key={selectedStatusId}
        onChange={(e)=>{setSelectedStatusId(e.value); }}
        label={"Prioritás"}
        value={selectedStatusId} 
        conditions={"StatusId=2"}
        disabled={false}
        id={"selectedStatus"}
        table={"statusitems"}
        listType={{ id: "ItemId", name: "ItemName" }}
        />
          </div>
     
          
        </div>
      
  <div className="sm:flex sm:justify-between">
   
   
  </div>
  <div className="mt-4 space-y-4 ">
    {mytodo.map((person) => (
     <TodoCard
     SimpleTaskName={person.SimpleTaskName}
     SimpleTaskTypeName={person.SimpleTaskTypeName}
     ProjectName={person.ProjectName}
     name={person.name}
     ProjectDescription={person.ProjectDescription}
     ResponsiblePersonName={person.ResponsiblePersonName}
     EndDate={person.EndDate}
     ItemId = {person.ItemId}
     />
    ))}
  </div>
</div>
</div>
    )
  }
  