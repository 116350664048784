import { useEffect,useState } from "react"
import AddProjectMember from "./AddProjectMember";
import axios from "axios";
  import DeleteDialog from "../ui/DeleteDialog";
  export default function ProjectTable(props) {

    const [member,setMember]=useState([]);
    const [showDeleteDialog,setShowDeleteDialog]=useState(false);
    const [removeId,setRemoveId]=useState(null);
    const [addModalShow,setAddModalShow]=useState(false);
    const [id,setId] = useState(null)

    const fetchData = async () => {
        console.log("Get resposible person list",props)
        try {
          const requestData = {
            tables: [
              "simple_task_resp_personlist",
              "simple_task",
              "web_user",      
            ],
            joins: [
              "simple_task_resp_personlist.SimpleTaskId = simple_task.SimpleTaskId",
              "simple_task_resp_personlist.UserId = web_user.UserId",
            ],
            conditions: [`simple_task_resp_personlist.SimpleTaskId = ${props.SimpleTaskId}`],
            selectedColumns: [
              "simple_task_resp_personlist.*",
              "simple_task.*",
              "web_user.*"
            ],
          };
      
          console.log("requestData", requestData);
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/getJoinedData`,
            requestData
          );
          console.log("extendedResult", response.data);
          setMember(response.data);
        } catch (error) {
          console.error(error);
        }
      //  props.fetchData();
      };
    

      
  const removeMember = async (id) => {
    console.log("Id",id)
    const deleteConditions = [{ RespPersonSimpleTaskId: id }];

    const logicalOperator = "AND"; // Vagy "OR", amit használni szeretnél

    // Elkészítjük a HTTP kérés testét
    const requestBody = {
      tableName: "simple_task_resp_personlist",
      conditions: deleteConditions,
      logicalOperator: logicalOperator,
    };

    try {
      // Fetch hívás a Node.js szerver felé
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/deleteItems`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody), // Az adatok JSON formátumban elküldve
        }
      );

      if (response.ok) {
        const result = await response.json(); // A szerver válaszának feldolgozása
        console.log("result", result);
        //  setData(result);
      } else {
        console.error("Hiba történt a szerverrel való kommunikáció során.");
      }
    } catch (error) {
      console.error("Hiba történt:", error);
    }
    props.setMemberList(props.memberList.filter((item)=>item.RespPersonSimpleTaskId !== id));
    setShowDeleteDialog(false);
  };

      
      useEffect(() => {
        console.log("useffect run")
        fetchData();
      }, [props]);

 
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">Tagok</h1>
            <p className="mt-2 text-sm text-gray-700">
              Ebben a listában adja hozzá a további szükséges tagokat.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              disabled={props.disabled}
              onClick={()=>{setAddModalShow(true);setId(props.projectId?props.projectId:props.id)}}
              className="block rounded-md bg-indigo-600 px-3 disabled:cursor-not-allowed disabled:opacity-50 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Hozzáadása
            </button>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Név
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Szerep
                      </th>
                     
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
        {props.memberList.length > 0 ? (
          props.memberList.map((person) => (
            <tr key={person.RespPersonSimpleTaskId}>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {person.UserName}
              </td>

              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {person.RoleName}
              </td>
              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                <a href="#" className="text-indigo-600 hover:text-indigo-900">
                <button className='disabled:cursor-not-allowed disabled:opacity-50' disabled={props.disabled} onClick={(e) => { e.stopPropagation(); setRemoveId(person.RespPersonSimpleTaskId); setShowDeleteDialog(true); } }>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="100%"
                              height="100%"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                             
                                className={
                        props.disabled
                          ? "feather feather-x h-5 w-5  rounded-full hover:text-yellow-400"
                          : " feather feather-x h-5 w-5 cursor-pointer rounded-full hover:text-yellow-400"
                      }
                            >
                              <line x1="18" y1="6" x2="6" y2="18"></line>
                              <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                          </button>
                </a>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="3" className="text-center py-4 text-sm text-gray-500">
              Nincsenek adatok.
            </td>
          </tr>
        )}
      </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <DeleteDialog open={showDeleteDialog}  setOpen={()=>setShowDeleteDialog(false)} handleRemoveElement={()=>removeMember(removeId)} />

        <AddProjectMember projectId={id}  show={addModalShow} fetchData={fetchData} memberList={props.memberList}
      setMemberList={props.setMemberList}  newMembers = {props.newMembers}
      setNewMembers={props.setNewMembers}
       onHide={()=>setAddModalShow(false)} SimpleTaskId={props.SimpleTaskId} />
      </div>
    )
  }