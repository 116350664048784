
  
  import { useState } from "react";
import DragAndDrop from "../ui/DragAndDrop";
import ProjectTable from "./ProjectTable";


  export default function ProjectTabs(props) {
    
    const [showJobInfo, setShowJobInfo] = useState(false);
    const [showMaterial, setShowMaterial] = useState(false);
    const [showHelped, setShowHelped] = useState(true);

  const tabs = [
    { id: 0, name: "Gyártás" },
    { id: 1, name: "Anyag" },
    { id: 2, name: "Segédlet" },
  ];

  const showCurrentPage = (name) => {
    if (name === "Gyártás") {
      setShowHelped(false);
      setShowMaterial(false);
      return setShowJobInfo(true);
    }
    if (name === "Anyag") {
      setShowJobInfo(false);
      setShowHelped(false);
      return setShowMaterial(true);
    }
    if (name === "Segédlet") {
      setShowJobInfo(false);
      setShowMaterial(false);
      return setShowHelped(true);
    }
  };
  const handleChange = (event) => {
    //setCurrent(event.target.value);
  };

  

    return (
        <div className="my-4 ">
        <div className="sm:hidden">
          <label htmlFor="current-tab" className="sr-only">
            Válassz egy menüpontot
          </label>
          <select
            id="current-tab"
            name="current-tab"
            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
           // onChange={handleChange}
           // onClick={() => showCurrentPage(current)}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="-mb-px flex space-x-8 py-6">
          <a
              onClick={() => {
                setShowMaterial(false);
                setShowJobInfo(false);
                setShowHelped(true);
              }}
              className={`${
                showHelped
                  ? "border-indigo-500 text-indigo-600"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
              } cursor-pointer whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium`}
           //   onClick={() => handleToggleVisible(index)}

              aria-current={showHelped ? "page" : undefined}
            >
              Leírás
            </a>

         

            <a
              onClick={() => {
                setShowMaterial(true);
                setShowJobInfo(false);
                setShowHelped(false);
              }}
              className={`${
                showMaterial
                  ? "border-indigo-500 text-indigo-600"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
              } cursor-pointer whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium`}
           //   onClick={() => handleToggleVisible(index)}

              aria-current={showMaterial ? "page" : undefined}
            >
              Dokumentumok
            </a>

            <a
              onClick={() => {
                setShowJobInfo(true);
                setShowHelped(false);
                setShowMaterial(false);
              }}
              className={`${
                showJobInfo
                  ? "border-indigo-500 text-indigo-600"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
              } cursor-pointer whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium`}
            //  onClick={() => handleToggleVisible(index)}
              aria-current={showJobInfo ? "page" : undefined}
            >
              További felelősök
            </a>
          

          
          </nav>

{showMaterial&&   <DragAndDrop SimpleTaskId={props.SimpleTaskId} disabled={props.disabled} fileList={props.fileList} setFileList={props.setFileList}  newFiles={props.newFiles} setNewFiles={props.setNewFiles}/> }
{showJobInfo&& <ProjectTable projectId={props.projectId} SimpleTaskId={props.SimpleTaskId} disabled={props.disabled}  memberList={props.memberList}
      setMemberList={props.setMemberList} 
      newMembers = {props.newMembers}
      setNewMembers={props.setNewMembers}
       /> }

{showHelped&&<div className="col-span-full relative ">
              <label htmlFor="about" className="absolute -top-2 left-2 inline-block max-w-full truncate bg-white px-1 text-xs font-medium text-gray-900">
                Leírás
              </label>
              <div className="mt-2">
                <textarea
                  disabled={props.disabled}
                  rows={3}
                  className="h-36 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 disabled:cursor-not-allowed disabled:bg-stone-200 disabled:opacity-50 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  name={props.formId}
                  id={props.formId}
                  value={props.value}
                  onChange={props.onChange}
                />
              </div>
              <p className="mt-2 leading-6 text-sm font-medium text-gray-900">Adja meg a projekthez szükséges leírást.</p>
            </div>
            }
        
        </div>
      </div>

    )
  }