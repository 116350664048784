import { BrowserRouter, Routes, Route } from "react-router-dom";
import NoPage from "./components/NoPage/NoPage";

import Index from "./components";
import Layout from "./components/Navbar/Layout";
import ProjectIndex from "./components/Project/ProjectIndex";
import { CompanyForm } from "./components/Company/CompanyForm";
import { StatusForm } from "./components/Status/StatusForm";
import Auth from "./components/Auth/Auth";
import ProtectedRoute from "./components/ProtectedRoute";
import GantComponent from "./components/Scheduler/GantComponent";
import RoleIndex from "./components/Roles/RoleIndex";
import UserIndex from "./components/User/UserIndex";
import ProductionIndex from "./components/Production/ProductionIndex";
import { SimpleTaskForm } from "./components/SimpleTask/SimpleTaskForm";
import { Form } from "./components/Form/Form";
//import { ProjectForm } from "./components/Project/ProjectForm";
import { ProjectForm } from "./components/Projekt/ProjectForm";
import { ProjectGroupForm } from "./components/ProjectGroupListitems/ProjectGroupForm";
import { CategoryForm } from "./components/Categories/CategoryForm";
import { UserForm } from "./components/Users/UserForm";
import { MachineForm } from "./components/Machines/MachineForm";
import MyTodoTable from "./components/MyTodo/MyTodoTable";
function App() {
  return (
   
<BrowserRouter>
  <Routes>
    <Route path="/" element={<Auth />} /> {/* Login oldal */}
    <Route
      path="/app"
      element={<ProtectedRoute element={<Layout />} />}
    >
      <Route index element={<MyTodoTable />} />
      <Route path="project" element={<ProjectForm />} />
      <Route path="projectTwo" element={<ProjectIndex />} />
      <Route path="company" element={<CompanyForm />} />
      <Route path="status" element={<StatusForm />} />
      <Route path="Categories" element={<CategoryForm tableId={"taskTypeId"} mainTableName={"task_type"}  listitemsTable={"task_type_listitems"} />} />
      <Route path="projectGroup" element={<ProjectGroupForm />} />
      <Route path="user" element={<UserForm />} />
      <Route path="roles" element={<RoleIndex />} />

      <Route path="scheduler" element={<GantComponent />} />
      <Route path="machine" element={<MachineForm />} />
      <Route path="simpleTask" element={<SimpleTaskForm />} />
      <Route path="createField" element={<Form />} />
      <Route path="mytodo" element={<MyTodoTable />} />
      <Route path="production" element={<ProductionIndex />} />
      <Route path="*" element={<NoPage />} />
    </Route>
  </Routes>
</BrowserRouter>

  );
}

export default App;
