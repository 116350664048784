import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
//import ItemCodeList from "./ItemCodeList";



export function AddProduction(props) {
  
  const [Production,setProduction]=useState([]);

  const [machineList,setMachineList]=useState([]);
  const [webUsers,setWebUsers]=useState([]);
  const [saveButtonVisible,setSaveButtonVisible] = useState(false);
  const [id,setId]=useState(null);




  const [selectedValues, setSelectedValues] = useState({
    Production: "",
    MachineId:"",
    startHour:"",
    startMinute:"",
    endHour:"",

  });


  const [UserData, setUserData] = useState({
    ProductionId: "",
    ItemName: "",
    ItemCode: "",
    PlannedQuantity: "",
    PlannedTime: "",
   // ProductionId: "",

  });


  const [ItemNameValid,setItemNameValid]=useState(false);

  const [projectIdValid,setProjectIdValid]=useState(false);

  const [ItemCodeValid,setItemCodeValid]=useState(false);
  const [PlannedQuantityValid,setPlannedQuantityValid]=useState(false);


 

  const formValid = ItemNameValid&&projectIdValid&&ItemCodeValid&&PlannedQuantityValid;
  
  const getStatusList = async () => {
    console.log("props",props)
    try {
        const requestBody = {
          table: "status", // itt add meg az adatbázis nevet
          filterColName: "Archive" // itt add meg az oszlop nevet
        };
      
        const response = await fetch(`${process.env.REACT_APP_API_URL}/getList`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(requestBody)
        });
      
        const data = await response.json();
        console.log("project data", data);
        if (response.ok) {
          console.log("project data", data);
          setProduction(data);
        } else {
          console.log("Error response");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
  };


  const getMachines = async () => {
    console.log("props",props)
    try {
        const requestBody = {
          table: "MACHINES", // itt add meg az adatbázis nevet
          filterColName: "Archive" // itt add meg az oszlop nevet
        };
      
        const response = await fetch(`${process.env.REACT_APP_API_URL}/getList`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(requestBody)
        });
      
        const data = await response.json();
        console.log("machine data", data);
        if (response.ok) {
          console.log("machine data", data);
          setMachineList(data);
        } else {
          console.log("Error response");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
  };


   const getWebUserList = async () => {
    console.log("props",props)
    try {
        const requestBody = {
          table: "web_user", // itt add meg az adatbázis nevet
          filterColName: "Archive" // itt add meg az oszlop nevet
        };
      
        const response = await fetch(`${process.env.REACT_APP_API_URL}/getList`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(requestBody)
        });
      
        const data = await response.json();
        console.log("project data", data);
        if (response.ok) {
          console.log("project data", data);
          setWebUsers(data);
        } else {
          console.log("Error response");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
  };


  useEffect(() => {
    setSaveButtonVisible(false);
    getStatusList();
    getMachines();
    getWebUserList();
   
  }, []);

 

  const handleAddProject = async () => {
   

    const projectDataToSend = {
  ...UserData,
  StatusId: selectedValues.Production || "",
  MachineId: selectedValues.MachineId || ""
};

    console.log("Data to send",projectDataToSend);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/addProduction`,
        projectDataToSend
      );
  
      console.log("Production added successfully:", response.data.ProductionId);
      setId(response.data.ProductionId)
  
      // Extract the ProductionId from the response data
      const ProductionId = response.data.ProductionId;
  
      // Frissítsd a projektek állapotát, majd hívd meg az onProjectAdded függvényt
      // Assuming UserData needs to be changed to the new Production object
      props.setProduction([...props.Production, response.data]);
  
      setPlannedQuantityValid(false);
    
      setProjectIdValid(false);
      setItemCodeValid(false);
      setItemNameValid(false);
  


      // Close the dialog
      //props.onHide();
      // Handle success
    } catch (error) {
      console.error("Error adding Production:", error);
      // Handle error
    }
  
    props.fetchData();
    setSaveButtonVisible(true);
    // További kód a projekt hozzáadásához
  };


  const handleSaveProject = async () => {

    props.onHide();

   
    setUserData({
        ProductionId:"",
        ItemName: "",
        ItemCode: "",
        PlannedTime: "",
        PlannedQuantity: "",
    })
    setSelectedValues({
      Production: "",
      MachineId:"",
     
    })
props.fetchData()
setSaveButtonVisible(false);
//setSaveButtonVisible(true);
    // További kód a projekt hozzáadásához
    setId(null);
  };

  return (
  
    <>
      <Transition.Root show={props.show} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={props.onHide}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 ">
                  <div className="">
                    <div className="flex mb-5">
                      <div className=" flex -my-3  h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12.75l6 6 9-13.5"
                          />
                        </svg>
                      </div>
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 mx-3  text-gray-900"
                      >
                        Gyártás hozzáadása
                      </Dialog.Title>
                    </div>

                    <div className=" text-center sm:ml-4 my-2  sm:text-left">
                    <div className="relative basis-64 mx-2 my-2">
                          <label
                            htmlFor="ItemName"
                            className="absolute -top-2 left-2 z-10 inline-block max-w-full truncate bg-white px-1 text-xs font-medium text-gray-900"
                          >
                            Cikknév
                          </label>
                          <div className="relative rounded-md shadow-sm">
                            <input
                              type="text"
                              name="ItemName"
                              id="ItemName"
                              required
                              value={UserData.ItemName}
                              onChange={(e) =>{
                                setUserData({
                                  ...UserData,
                                  ItemName: e.target.value,
                                });
                                setItemNameValid(e.target.validity.valid)

                              }
                               
                              }
                              className="block w-full rounded-md border-0 py-1.5 pr-8 text-sm leading-6 text-gray-900 ring-1 ring-inset ring-gray-300 [appearance:textfield] placeholder:text-gray-400 invalid:ring-red-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 focus:invalid:ring-red-500 disabled:cursor-not-allowed disabled:opacity-50 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                              aria-describedby="gross-weight-unit"
                            />
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                              <span
                                className="text-sm text-gray-500"
                                id="gross-weight-unit"
                              ></span>
                            </div>
                          </div>
                        </div>

                        
                      <div className="mt-2 flex">
                        
                      <div className="relative basis-64 mx-2 my-2">
                          <label
                            htmlFor="ItemCode"
                            className="absolute -top-2 left-2 z-10 inline-block max-w-full truncate bg-white px-1 text-xs font-medium text-gray-900"
                          >
                            Cikkszám
                          </label>
                          <div className="relative rounded-md shadow-sm">
                            <input
                              type="text"
                              name="ItemCode"
                              id="ItemCode"
                              required
                              value={UserData.ItemCode}
                              onChange={(e) =>{
                                setUserData({
                                  ...UserData,
                                  ItemCode: e.target.value,
                                });
                                setItemCodeValid(e.target.validity.valid)

                              }
                               
                              }
                              className="block w-full rounded-md border-0 py-1.5 pr-8 text-sm leading-6 text-gray-900 ring-1 ring-inset ring-gray-300 [appearance:textfield] placeholder:text-gray-400 invalid:ring-red-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 focus:invalid:ring-red-500 disabled:cursor-not-allowed disabled:opacity-50 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                              aria-describedby="gross-weight-unit"
                            />
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                              <span
                                className="text-sm text-gray-500"
                                id="gross-weight-unit"
                              ></span>
                            </div>
                          </div>
                        </div>

                   

                           <div className="relative basis-64 mx-2 my-2">
  <label htmlFor="status-select" className="absolute -top-2 left-2 z-10 inline-block max-w-full truncate bg-white px-1 text-xs font-medium text-gray-900">
    Státusz
  </label>
  <select
    id="status-select"
    name="status"
    required
    className="block w-full rounded-md border-0 py-1.5 pr-8 text-sm leading-6 text-gray-900 ring-1 ring-inset ring-gray-300 [appearance:textfield] placeholder:text-gray-400 invalid:ring-red-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 focus:invalid:ring-red-500 disabled:cursor-not-allowed disabled:opacity-50 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
    onChange={(e) =>{
      setSelectedValues({ ...selectedValues, Production: e.target.value });
      setProjectIdValid(e.target.validity.valid);
    }
        
      }
      value={selectedValues.Production}
  >
    <option value=""></option>
    {Production && Production.length > 0 && (
  Production.map((w) => (
    <option key={w.StatusId} value={w.StatusId}>
      {w.StatusName}
    </option>
  ))
)}
  </select>
</div>
          

<div className="relative basis-64 mx-2 my-2">
                          <label
                            htmlFor="PlannedTime"
                            className="absolute -top-2 left-2 z-10 inline-block max-w-full truncate bg-white px-1 text-xs font-medium text-gray-900"
                          >
                            Utalványozott idő
                          </label>
                          <div className="relative rounded-md shadow-sm">
                            <input
                              type="text"
                              name="PlannedTime"
                              id="PlannedTime"
                              required
                              value={UserData.PlannedTime}
                              onChange={(e) =>{
                                setUserData({
                                  ...UserData,
                                  PlannedTime: e.target.value,
                                });
                               // setPlannedQuantityValid(e.target.validity.valid)

                              }
                               
                              }
                              className="block w-full rounded-md border-0 py-1.5 pr-8 text-sm leading-6 text-gray-900 ring-1 ring-inset ring-gray-300 [appearance:textfield] placeholder:text-gray-400 invalid:ring-red-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 focus:invalid:ring-red-500 disabled:cursor-not-allowed disabled:opacity-50 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                              aria-describedby="gross-weight-unit"
                            />
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                              <span
                                className="text-sm text-gray-500"
                                id="gross-weight-unit"
                              ></span>
                            </div>
                          </div>
                          
                        </div>

                      

                        <div className="relative basis-64 mx-2 my-2">
                          <label
                            htmlFor="PlannedQuantity"
                            className="absolute -top-2 left-2 z-10 inline-block max-w-full truncate bg-white px-1 text-xs font-medium text-gray-900"
                          >
                            Tervezett mennyiség
                          </label>
                          <div className="relative rounded-md shadow-sm">
                            <input
                              type="text"
                              name="PlannedQuantity"
                              id="PlannedQuantity"
                              required
                              value={UserData.PlannedQuantity}
                              onChange={(e) =>{
                                setUserData({
                                  ...UserData,
                                  PlannedQuantity: e.target.value,
                                });
                                setPlannedQuantityValid(e.target.validity.valid)

                              }
                               
                              }
                              className="block w-full rounded-md border-0 py-1.5 pr-8 text-sm leading-6 text-gray-900 ring-1 ring-inset ring-gray-300 [appearance:textfield] placeholder:text-gray-400 invalid:ring-red-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 focus:invalid:ring-red-500 disabled:cursor-not-allowed disabled:opacity-50 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                              aria-describedby="gross-weight-unit"
                            />
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                              <span
                                className="text-sm text-gray-500"
                                id="gross-weight-unit"
                              ></span>
                            </div>
                          </div>
                          
                        </div>
                      </div>
                   

                      
                    </div>


<div className="flex sm:ml-4 my-2">
<div className="relative basis-64 mx-2 my-2">
  <label htmlFor="status-select" className="absolute -top-2 left-2 z-10 inline-block max-w-full truncate bg-white px-1 text-xs font-medium text-gray-900">
    Gép kiválasztása
  </label>
  <select
    id="status-select"
    name="status"
    required
    className="block w-full rounded-md border-0 py-1.5 pr-8 text-sm leading-6 text-gray-900 ring-1 ring-inset ring-gray-300 [appearance:textfield] placeholder:text-gray-400 invalid:ring-red-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 focus:invalid:ring-red-500 disabled:cursor-not-allowed disabled:opacity-50 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
    onChange={(e) =>{
      setSelectedValues({ ...selectedValues, MachineId: e.target.value });
   //   setProjectIdValid(e.target.validity.valid);
    }
        
      }
      value={selectedValues.MachineId}
  >
    <option value=""></option>
    {machineList && machineList.length > 0 && (
  machineList.map((w) => (
    <option key={w.MachineId} value={w.MachineId}>
      {w.MachineName}
    </option>
  ))
)}
  </select>
</div>

<div className="relative basis-64 mx-2 my-2">
                          <label
                            htmlFor="ProductionId"
                            className="absolute -top-2 left-2 z-10 inline-block max-w-full truncate bg-white px-1 text-xs font-medium text-gray-900"
                          >
                            Gyártás azonosítója
                          </label>
                          <div className="relative rounded-md shadow-sm">
                            <input
                              type="text"
                              name="ProductionId"
                              id="ProductionId"
                              required
                              value={UserData.ProductionId}
                              onChange={(e) =>{
                                setUserData({
                                  ...UserData,
                                  ProductionId: e.target.value,
                                });
                              //  setItemNameValid(e.target.validity.valid)

                              }
                               
                              }
                              className="block w-full rounded-md border-0 py-1.5 pr-8 text-sm leading-6 text-gray-900 ring-1 ring-inset ring-gray-300 [appearance:textfield] placeholder:text-gray-400 invalid:ring-red-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 focus:invalid:ring-red-500 disabled:cursor-not-allowed disabled:opacity-50 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                              aria-describedby="gross-weight-unit"
                            />
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                              <span
                                className="text-sm text-gray-500"
                                id="gross-weight-unit"
                              ></span>
                            </div>
                          </div>
                        </div>
</div>
   

                    <div className=" text-center sm:ml-4 my-2  sm:text-left">
                    
                   
                      
                      
                    </div>

                    
{/*    <ItemCodeList ProductionId={id}  buttonVisible={!saveButtonVisible} member={member} setMember={setMember} /> */}
                 


                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  disabled={!formValid}
                  className="inline-flex w-full justify-center  disabled:cursor-not-allowed disabled:opacity-50 rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-400 sm:ml-3 sm:w-auto"
                 onClick={handleAddProject}
                >
                  Mentés
                </button>

                  <button
  type="button"
  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
  onClick={() => {
    props.onHide();
    setSaveButtonVisible(false);
    setId(null);
  }}
>
  Mégse
</button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
      
    </Transition.Root>

    </>
  );
}
