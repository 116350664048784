
import { useEffect,useState } from "react";
import ProductionInformation from "./ProductionInformation";
import { AddProduction } from "./AddProduction";

export default function ProductionList() {

    const [Production,setProduction]=useState([]);
    const [ProductionId,setProductionId]=useState(null);

    const [showAddDialog, setShowAddDialog] = useState(false);


 
      const fetchData = async () => {
        try {
          const requestBody = {
            table: "PRODUCTION", // itt add meg az adatbázis nevet
            filterColName: "Archive" // itt add meg az oszlop nevet
          };
        
          const response = await fetch(`${process.env.REACT_APP_API_URL}/getList`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(requestBody)
          });
        
          const data = await response.json();
          console.log("Production data", data);
          if (response.ok) {
            console.log("Production data", data);
            setProduction(data);
          } else {
            console.log("Error response");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
        };
      

      
      useEffect(() => {
        fetchData();
      }, []);

     /*  
      useEffect(() => {
        fetchData();
      }, [Production]); 
      */
    
      return (
        <div className="px-2 sm:px-6 lg:px-8 bg-gray-100">

<div className="mx-5 mb-2">
              <button
                type="button"
                onClick={() => {
                  setShowAddDialog(true);
                }}
                className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Hozzáadás
              </button>
        </div>
        <div className="grid grid-cols-4">
            
        <div className="col-span-1 h-screen overflow-y-auto px-3">
  {Production && Production.length > 0 ? (
    Production.map((p) => (
      <div
        key={p.ProductionId}
        onClick={() => setProductionId(p.ProductionId)}
        className="flex flex-col items-center h-16 justify-center text-center bg-white hover:bg-gray-200 border border-gray-200 rounded-md py-3 cursor-pointer p-4"
      >
        <p className="text-d font-semibold text-gray-800 text-sm">
          {p.ProductionId} - {p.ItemName}
        </p>
      </div>
    ))
  ) : (
    <p>Nincs elérhető egyszerű feladat.</p>
  )}
</div>
        <div className="col-span-3">
         <ProductionInformation ProductionId={ProductionId}  Production={Production}  setProduction={setProduction}   fetchData={fetchData}  />   
        </div>
 <AddProduction
  show={showAddDialog}
  onHide={() => setShowAddDialog(false)}
  setProduction={setProduction}
  Production={Production} 
  fetchData={fetchData}
/>


        
      </div>
        </div>
      
      
      );
    
}


  