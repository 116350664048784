
import { useEffect,useState } from "react";
import ProjectTable from "./ProjectTable";
import ProjectTabs from "./ProjectTabs";
import { EditProjectDialog } from "./EditProjectDialog";
//import { AddProjectForm } from "./AddProjectForm";
import ConfirmWindow from "../ui/ConfirmWindow";
export default function ProjectInformation(props) {

    const [showAddDialog, setShowAddDialog] = useState(false);

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [project,setProjects]=useState([]);
    const fetchData = async () => {
        console.log("props",props)
        try {
          // const docNumString = localStorage.getItem("docNum");
          // const inputNumber =docNumString !== null ? parseInt(docNumString, 10) : 0;
    
          // console.log("downtime input", inputNumber);
          let response = await fetch(
            `${process.env.REACT_APP_API_URL}/getProjectInformation?ProjectId=${props.projectId}`
          );
          const data = await response.json();
    
          if (response) {
            console.log("Project information data", data);
            setProjects(data[0]);
          }
          else{
            console.log("Error response")
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
    
    
    
      useEffect(() => {
        fetchData();
      }, [props.projectId]);

   

    
      return (
        < >
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-4">
              <p className="mt-1 text-sm text-gray-500"></p>
            </div>
            <div className="ml-8 mt-4 flex-shrink-0">

              
            <div className="flex">
              <div className="px-3">
              <button
                 type="button"
                 onClick={() => {
                   setShowDeleteDialog(true);
                 }}
                 className="relative inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
               >
                 Törlés
               </button>
              </div>
             
                 <div>
                   <button
                 type="button"
                 onClick={() => {
                  setShowAddDialog(true);
                 }}
                 className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
               >
                 Szerkesztés
               </button>
                 </div>
               </div>

           
            </div>
          </div>
        </div>
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-4">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Projekt neve
              </h3>
              <p className="mt-1 text-sm text-gray-500">
              {project && project?.ProjectName}
              </p>
            </div>
            <div className="ml-4 mt-4 flex-shrink-0">
              <div>
                <h3 className="text-base font-semibold leading-6 text-center text-gray-900">
                  Projekt csoportja
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                {project && project?.ProjectGroup}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-4">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Leírás
              </h3>
              <p className="mt-1 text-sm text-gray-500">
              {project && project?.ProjectDescription}
              </p>
            </div>
            <div className="ml-4 mt-4 flex-shrink-0">
              <div>
                <h3 className="text-base font-semibold leading-6 text-center text-gray-900">
                  Készültség
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                {project && project?.ProjectPrepare}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-4">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Project kezdete
              </h3>
              <p className="mt-1 text-sm text-gray-500">
              
              {project && project.ProjectStart && (project.ProjectStart.split("T")[0])}
              </p>
            </div>
            <div className="ml-4 mt-4 flex-shrink-0">
              <div>
                <h3 className="text-base font-semibold text-center leading-6 text-gray-900">
                  Projekt vége
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                {project && project.ProjectEnd && (project.ProjectEnd.split("T")[0])}
                </p>
              </div>
            </div>
          </div>
        </div>
{
    /*
     <AddProjectForm
  show={showAddDialog}
  onHide={() => setShowAddDialog(false)}
  setProjects={setProjects}
  project={project} // Biztosítsd, hogy a projects állapot át legyen adva
/>
    */
}
<div className="px-6">
<ProjectTabs  projectId={props.projectId} />
</div>


    <EditProjectDialog project={project} show={showAddDialog} onHide={()=>setShowAddDialog(false)} />
    <ConfirmWindow show={showDeleteDialog} onClose={()=>setShowDeleteDialog(false)} tableName={"project"} filterCol={"ProjectId"} itemName={project?.ProjectName} statusId={props.projectId} fetchData={props.fetchData}/>
      </>
      );
}