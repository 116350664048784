import { FormEvent, useState, useEffect } from "react";

import "flatpickr/dist/flatpickr.min.css"
import DatePicker from "../ui/DatePicker";
import FormTabs from "./FormTabs";
import axios from "axios";
import CompanyReports from "./CompanyReports";
import SelectInput from "../ui/SelectInput";
import InputField from "../ui/InputField";
import Checkbox from "../ui/Checkbox";
import { Button } from "../ui/Button";
import TextAreaInput from "../ui/TextAreaInput";
import TimeSelector from "../ui/TimeSelector";

import TimePickerValue from "../ui/TimePickerValue";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import FormHeader from "../ui/FormHeader";
import FormHeaderAnother from "../ui/FormHeaderAnother";
//import { PDFViewer } from "@react-pdf/renderer";
import DeleteDialog from "../ui/DeleteDialog";

export const CompanyForm = (props) => {
  const isAuthenticated = localStorage.getItem("user");
  const userObj = JSON.parse(isAuthenticated);

  const [showDeleteDialog, setShowDeleteDialog] =useState(false);
  const [reportList, setReportList] = useState([]);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [owner,setOwner] = useState(userObj.UserId);

  const [memberList,setMemberList] = useState([]);
  const [fileList,setFileList] = useState([]);
  
  const [newMembers,setNewMembers]=useState([]);
  const [newFiles,setNewFiles] = useState([]);

  const [selectedValues, setSelectedValues] = useState({
    companyId: "",
    companyName:"",
    billPostcode:"",
    billCity:"",
    postCode:"",
    billAddress:"",
    city:"",
    street:"",
    companyDescription:"",
  });



  const getCurrentDate = () => {
    const todayDate = new Date();
    const year = todayDate.getFullYear(); // Évszám
    const month = todayDate.getMonth() + 1; // Hónap (0-tól indexelődik, ezért +1)
    const day = todayDate.getDate(); // Nap
    // Formázás: "év-hónap-nap" (pl. "2023-10-06")
    const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${
      day < 10 ? "0" : ""
    }${day}`;
    return formattedDate;
  };

  const sortReports = (reports) => {
    const notArchiveReports = reports.sort(
      (a, b) =>
        new Date(a.StartDate).getTime() - new Date(b.StartDate).getTime()
    );   
    return [...notArchiveReports];
  };

  const getReports = async () => {
    try {
      const requestData = {
        tables: [
          "company"
        ],
        joins: [
        ],
        conditions: ["company.Archive = 'N'"],
        selectedColumns: [
          "company.*",
        ],
      };
  
      console.log("requestData", requestData);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getJoinedData`,
        requestData
      );
      setReportList(sortReports(response.data));
    
    } catch (error) {
      console.error(error);
    }
  };




 const handleAddTaskMembers = async (taskMembers,insertId) => {
  const tableName = "simple_task_resp_personlist";
  const id= "RespPersoncompanyId"
  try {
    for (const item of taskMembers) {
      const insertObject = {
        UserId: item.UserId,
        companyId: item.companyId??insertId,
        RoleName: item.RoleName,
      };
      console.log("insertObject", insertObject);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/addItem`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ tableName, id, data: insertObject }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("result", result);
      } else {
        console.error("Hiba történt a szerverrel való kommunikáció során.");
      }
    }
  } catch (error) {
    console.error("Hiba történt:", error);
  }
  setNewMembers([]);
};


const handleFileUpload = async (files, id) => {
  try {
    for (const item of files) {
      item.append("companyId",id); 
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/upload-endpoint`, {
          method: "POST",
          body: item,
        });

        if (response.ok) {
          const data = await response.json();
          console.log("Sikeres feltöltés:", data);
        } else {
          console.error("Hiba történt a feltöltés során:", response.statusText);
        }
      } catch (error) {
        console.error("Hiba történt a fetch során:", error);
      }
    }
  } catch (error) {
    console.error("Hiba történt:", error);
  }
  setNewFiles([]);
  //setFileList([]);
};




  const updateObject = async () => {
    console.log("kiválasztott update object",selectedValues)


    const updateData = {
      tableName: "company",
      updates: {
      companyName:selectedValues.companyName,
      billPostcode:selectedValues.billPostcode,
      billCity:selectedValues.billCity,
      postCode:selectedValues.postCode,
      billAddress: selectedValues.billAddress,
      city:selectedValues.city,
      street:selectedValues.street,
      companyDescription:selectedValues.companyDescription,
      Archive: "N",
      },
      whereClause: {
        condition: `companyId = ${selectedValues?.companyId}`,
      },
    };
    console.log("Information data", updateData);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/updateTable`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updateData),
        }
      );

      if (response.ok) {
        console.log("Status change successfully.");
      } else {
        console.error("Error changing status:", response.statusText);
      }
    } catch (error) {
      console.error("Error changing status:", error);
    }
    getReports();
  };

  const handleCopyObject = async () => {

   
    const insertObject = {
      companyName:selectedValues.companyName,
      billPostcode:selectedValues.billPostcode,
      billCity:selectedValues.billCity,
      postCode:selectedValues.postCode,
      billAddress: selectedValues.billAddress,
      city:selectedValues.city,
      street:selectedValues.street,
      companyDescription:selectedValues.companyDescription,
      Archive: "N",
    };

    console.log("insertObject", insertObject);
    console.log("selectedValues", selectedValues);
    try {
      // Az adatok, amiket elküldünk a Node.js szervernek

      const tableName = "company";
      const id= "companyId"

      // Fetch hívás a Node.js szerver felé
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/addItem`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ tableName,id, data: insertObject }), // Az adatok JSON formátumban elküldve
        }
      );

      if (response.ok) {
        const result = await response.json(); // A szerver válaszának feldolgozása
        console.log("copy result", result.insertedData);
        getReports();
       // setSelectedRow(result.insertedData);
        //setSelectedValues(result.insertedData);

        setSelectedValues({ ...result.insertedData, 
         // StartDate: result.insertedData.StartDate.split("T")[0],
         //  EndDate: result.insertedData.EndDate.split("T")[0], 
         //  StartTime: dayjs(result.insertedData.StartDate) , 
         // EndTime: dayjs(result.insertedData.EndDate)
          })

       
        //  setData(result);
      } else {
        console.error("Hiba történt a szerverrel való kommunikáció során.");
      }
    } catch (error) {
      console.error("Hiba történt:", error);
    }

  
    
  };


  const handleInsertObject = async () => {
    console.log("teszt ",selectedValues)



  
    const insertObject = {
      companyName:selectedValues.companyName,
      billPostcode:selectedValues.billPostcode,
      billCity:selectedValues.billCity,
      postCode:selectedValues.postCode,
      billAddress: selectedValues.billAddress,
      city:selectedValues.city,
      street:selectedValues.street,
      companyDescription:selectedValues.companyDescription,
      Archive: "N",
    };
  
    console.log("new insert object", insertObject);
  
    try {
      const tableName = "company";
      const id= "companyId"
      const response = await fetch(`${process.env.REACT_APP_API_URL}/addItem`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ tableName, id, data: insertObject }),
      });
  
      if (response.ok) {
        const result = await response.json();
        console.log("result", result);
        await getReports();
        await handleAddTaskMembers(newMembers,result.insertedId);
        await handleFileUpload(newFiles,result.insertedId)

     
       // setSelectedRow(result.insertedData);
        setSelectedValues({ ...result.insertedData, 
          //StartDate: result.insertedData.StartDate.split("T")[0],
          // EndDate: result.insertedData.EndDate.split("T")[0], 
          // StartTime: dayjs(result.insertedData.StartDate) , 
         // EndTime: dayjs(result.insertedData.EndDate)
          })
       
      } else {
        console.error("Hiba történt a szerverrel való kommunikáció során.");
      }
    } catch (error) {
      console.error("Hiba történt:", error);
    }

  };

  
  dayjs.extend(utc);

  const formatDate = (date, time) => {
    const [year, month, day] = date.split('-');
    const [hour, minute] = time.split(':');
  
    const formattedDate = dayjs()
      .year(year)
      .month(month - 1)
      .date(day)
      .hour(hour)
      .minute(minute)
      .format(); // Most a helyi időzóna és formátum lesz alkalmazva
  
    return formattedDate;
  };


  const handleRemoveElement = async () => {
    const updateData = {
      tableName: "company",
      updates: {
        Archive: 'Y'
      },
      whereClause: {
        condition: `companyId = ${selectedValues?.companyId}`,
      },
    };
    console.log("Information data", updateData);
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/updateTable`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updateData),
        }
      );
  
      if (response.ok) {
        console.log("Status change successfully.");
      } else {
        console.error("Error changing status:", response.statusText);
      }
    } catch (error) {
      console.error("Error changing status:", error);
    }
  
    // Elmentett kijelölés eltávolítása
    setShowDeleteDialog(false);
  
    // Az aktuális kijelölt elem indexének megtalálása
    const currentIndex = reportList.findIndex((rep) => rep.companyId === selectedValues?.companyId);
  
    if (currentIndex !== -1) {
      if (currentIndex === reportList.length - 1) {
        // Ha az aktuális kijelölt elem az utolsó elem
        if (currentIndex > 0) {
          // Ha van előtte elem, akkor az lesz a kijelölt elem
          //setSelectedRow(reportList[currentIndex - 1]);
          const prevReport = reportList[currentIndex - 1];
          setSelectedValues({ 
            ...prevReport, 
            StartDate: prevReport.StartDate.split("T")[0],
            EndDate: prevReport.EndDate.split("T")[0], 
            StartTime: dayjs(prevReport.StartDate), 
            EndTime: dayjs(prevReport.EndDate)
          });
        } else {
          // Ha nincs előtte elem, akkor a kijelölést és az értékeket null-ra állítjuk
         // setSelectedRow(null);
         // setSelectedValues(null);
        }
      } else {
        const nextReport = reportList[currentIndex + 1];
        setSelectedValues({ 
          ...nextReport, 
       //   StartDate: nextReport.StartDate.split("T")[0],
       //   EndDate: nextReport.EndDate.split("T")[0], 
       //   StartTime: dayjs(nextReport.StartDate), 
       //   EndTime: dayjs(nextReport.EndDate)
        });
      }
    } else {
      //setSelectedRow(null);
      //setSelectedValues(null);
    }
    getReports();

  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const isAuthenticated = localStorage.getItem("user");
    const userObj = JSON.parse(isAuthenticated);
    setOwner(userObj.UserId)
    getReports().then(() => {
      setIsLoading(false); // Az adatok betöltése befejeződött
    });
  }, []);


  useEffect(() => {
   // getResposiblePersons();
   // listFilesForSimpleTask();
  }, [selectedValues]);
 
  const handleSelect = (selectedOption, name) => {
    setSelectedValues({
      ...selectedValues,
      [name]: selectedOption.value,
    });
  };


  const onSubmit = (e) => {
    e.preventDefault();
  };



  const formFieldsNumberThree = [
    {
        Fieldtype: "textAreaInput",
        id: "companyName",
        label: "Cég neve",
        type: "text",
        disabled: viewField,
        value: selectedValues.companyName,
       
        onChange: (e) => {
          setSelectedValues({ ...selectedValues, companyName: e.target.value });
          //setItemNameValid(e.target.validity.valid)
        },
      },
   
  ];



  const formFields = [
 

      
    {
      Fieldtype: "input",
      id: "postCode",
      label: "Irányítószám",
      type: "number",
      disabled: viewField,
      value: selectedValues.postCode,
     
      onChange: (e) => {
     
        setSelectedValues({
          ...selectedValues,
          postCode: e.target.value,
        });
      },
    },

    {
        Fieldtype: "input",
        id: "city",
        label: "Helység",
        type: "text",
        disabled: viewField,
        value: selectedValues.city,
       
        onChange: (e) => {
      
          setSelectedValues({
            ...selectedValues,
            city: e.target.value,

          });
        },
      },


      
    {
        Fieldtype: "input",
        id: "street",
        label: "cím", // Ez kettő széles kell hogy legyen ! 
        type: "text",
        disabled: viewField,
        value: selectedValues.street,
       
        onChange: (e) => {
          setSelectedValues({
            ...selectedValues,
            street: e.target.value,
          });
        },
      },
  ];


  
  
  const formFieldsNumberTwo = [
      
    {
      Fieldtype: "input",
      id: "billPostcode",
      label: "Irányítószám",
      type: "number",
      disabled: viewField,
      value: selectedValues.billPostcode,
      onChange: (e) => {
        setSelectedValues({
          ...selectedValues,
          billPostcode: e.target.value,
        });
      },
    },
    
 
      
    {
        Fieldtype: "input",
        id: "billCity",
        label: "Helység",
        type: "text",
        disabled: viewField,
        value: selectedValues.billCity,
       
        onChange: (e) => {
          setSelectedValues({
            ...selectedValues,
            billCity: e.target.value,
          });
        },
      },

  
      
    {
        Fieldtype: "input",
        id: "billAddress",
        label: "cím", 
        type: "text",
        disabled: viewField,
        value: selectedValues.billAddress,
       
        onChange: (e) => {
          setSelectedValues({
            ...selectedValues,
            billAddress: e.target.value,
          });
        },
      },

  ];



  const formFieldsNumberFour = [
      
    {
      Fieldtype: "input",
      id: "Duration",
      label: "Adószám",
      type: "number",
      disabled: viewField,
      value: selectedValues.Duration,
     
      onChange: (e) => {
        const startDate = dayjs(selectedValues.StartTime);
        const newEndDate = startDate.add(e.target.value, 'hour');
        console.log("newEndDate",newEndDate)
        setSelectedValues({
          ...selectedValues,
          Duration: e.target.value,
          EndTime: dayjs(newEndDate), // Az EndDate-nek dátum objektumnak kell lennie
        });
      },
    },
  ];
  

  return (
    <div className="fixed top-16 inset-0 grid grid-cols-5 grid-rows-[auto_1fr] gap-4 bg-gray-100 p-4">
    <div className="col-span-2 w-full overflow-x-auto">
      <form className="flex flex-col h-full overflow-x-auto bg-white shadow sm:rounded-lg" onSubmit={onSubmit}>
       
    

<FormHeaderAnother disabled={!viewField}
          setViewField={setViewField} 
          setAddField={setAddField} 
            resetValue={()=>setSelectedValues({
              companyId: "",
              companyName:"",
              billPostcode:"",
              billCity:"",
              postCode:"",
              billAddress:"",
              city:"",
              street:"",
              companyDescription:"",
              Archive: "N",
            }) }
            setMemberList={setMemberList}
            setEditButtonvisible={setEditButtonvisible} 
            handleCopyObject={handleCopyObject}
            owner={owner}
     
            setShowDeleteDialog={setShowDeleteDialog}
           />
       

        <div className="flex h-full flex-col gap-6 overflow-x-auto border-t border-gray-200 px-3 py-9">
        
        

        <div className="grid col-span-1 md:col-span-2 lg:grid-cols-3 gap-4 xl:grid-cols-4">
        
        {formFieldsNumberThree.map((field) => {
          if (field.Fieldtype === "select") {
            return (
              <SelectInput
                key={field.id}
                onChange={(selectedOption) =>
                  handleSelect(selectedOption, field.id)
                }
                label={field.label}
                value={selectedValues[field.id]} 
                disabled={field.disabled}
                id={field.id}
                table={field.table}
                listType={field.listType}
              />
            );
          } else if (field.Fieldtype === "checkbox") {
            return (
              <Checkbox
                key={field.id}
                id={field.id}
                label={field.label}
                disabled={field.disabled}
                value={selectedValues[field.id]} 
                onChange={field.onChange}
                table={field.table}
                listType={field.listType}
              />
            );
          }
          else if (field.Fieldtype === "timePicker") {
            return (
              <TimePickerValue 
              label={field.label}
              value={selectedValues[field.id]} 
              disabled={viewField} 
              onChange={field.onChange} 
              />
            );
          } 

          else if (field.Fieldtype === "datePicker") {
            return (
              <DatePicker
              label={field.label}
              value={selectedValues[field.id]}  
              disabled={viewField} 
              onChange={field.onChange}
            />
            );
          } 

           else if (field.Fieldtype === "input") {
            return (
              <InputField
                key={field.id}
                id={field.id}
                label={field.label}
                type={field.type}
                min={field.min}
                max={field.max}
                disabled={field.disabled}
                value={selectedValues[field.id]}
                additionalPreparedness={field.additionalPreparedness}
                onChange={field.onChange}
              />
            );
          }
          else if (field.Fieldtype === "textAreaInput") {
            return (
              <TextAreaInput
                key={field.id}
                id={field.id}
                label={field.label}
                type={field.type}
                min={field.min}
                max={field.max}
                disabled={field.disabled}
                value={selectedValues[field.id]} 
                additionalPreparedness={field.additionalPreparedness}
                onChange={(e) => {
                  setSelectedValues({
                    ...selectedValues,
                    [field.id]: e.target.value, 
                  });
                }}
              />
            );
          }
          return null; // Kihagyhatod a nem támogatott mezőket
        })}
      </div>

        Levelezési címe
          <div className="grid col-span-1 md:col-span-2 lg:grid-cols-3 gap-4 xl:grid-cols-4 py-3 border-b">
        
            {formFields.map((field) => {
              if (field.Fieldtype === "select") {
                return (
                  <SelectInput
                    key={field.id}
                    onChange={(selectedOption) =>
                      handleSelect(selectedOption, field.id)
                    }
                    label={field.label}
                    value={selectedValues[field.id]} 
                    disabled={field.disabled}
                    id={field.id}
                    table={field.table}
                    listType={field.listType}
                  />
                );
              } else if (field.Fieldtype === "checkbox") {
                return (
                  <Checkbox
                    key={field.id}
                    id={field.id}
                    label={field.label}
                    disabled={field.disabled}
                    value={selectedValues[field.id]} 
                    onChange={field.onChange}
                    table={field.table}
                    listType={field.listType}
                  />
                );
              }
              else if (field.Fieldtype === "timePicker") {
                return (
                  <TimePickerValue 
                  label={field.label}
                  value={selectedValues[field.id]} 
                  disabled={viewField} 
                  onChange={field.onChange} 
                  />
                );
              } 

              else if (field.Fieldtype === "datePicker") {
                return (
                  <DatePicker
                  label={field.label}
                  value={selectedValues[field.id]}  
                  disabled={viewField} 
                  onChange={field.onChange}
                />
                );
              } 

               else if (field.Fieldtype === "input") {
                return (
                  <InputField
                    key={field.id}
                    id={field.id}
                    label={field.label}
                    type={field.type}
                    min={field.min}
                    max={field.max}
                    disabled={field.disabled}
                    value={selectedValues[field.id]}
                    additionalPreparedness={field.additionalPreparedness}
                    onChange={field.onChange}
                  />
                );
              }
              else if (field.Fieldtype === "textAreaInput") {
                return (
                  <TextAreaInput
                    key={field.id}
                    id={field.id}
                    label={field.label}
                    type={field.type}
                    min={field.min}
                    max={field.max}
                    disabled={field.disabled}
                    value={selectedValues[field.id]} 
                    additionalPreparedness={field.additionalPreparedness}
                    onChange={(e) => {
                      setSelectedValues({
                        ...selectedValues,
                        [field.id]: e.target.value, 
                      });
                    }}
                  />
                );
              }
              return null; // Kihagyhatod a nem támogatott mezőket
            })}
          </div>
          Számlázási címe
          <div className="grid col-span-1 md:col-span-2 lg:grid-cols-3 gap-4 xl:grid-cols-4">
          {formFieldsNumberTwo.map((field) => {
              if (field.Fieldtype === "select") {
                return (
                  <SelectInput
                    key={field.id}
                    onChange={(selectedOption) =>
                      handleSelect(selectedOption, field.id)
                    }
                    label={field.label}
                    value={selectedValues[field.id]} 
                    disabled={field.disabled}
                    id={field.id}
                    table={field.table}
                    listType={field.listType}
                  />
                );
              } else if (field.Fieldtype === "checkbox") {
                return (
                  <Checkbox
                    key={field.id}
                    id={field.id}
                    label={field.label}
                    disabled={field.disabled}
                    value={selectedValues[field.id]} 
                    onChange={field.onChange}
                    table={field.table}
                    listType={field.listType}
                  />
                );
              }
              else if (field.Fieldtype === "timePicker") {
                return (
                  <TimePickerValue 
                  label={field.label}
                  value={selectedValues[field.id]} 
                  disabled={viewField} 
                  onChange={field.onChange} 
                  />
                );
              } 

              else if (field.Fieldtype === "datePicker") {
                return (
                  <DatePicker
                  label={field.label}
                  value={selectedValues[field.id]}  
                  disabled={viewField} 
                  onChange={field.onChange}
                />
                );
              } 

               else if (field.Fieldtype === "input") {
                return (
                  <InputField
                    key={field.id}
                    id={field.id}
                    label={field.label}
                    type={field.type}
                    min={field.min}
                    max={field.max}
                    disabled={field.disabled}
                    value={selectedValues[field.id]}
                    additionalPreparedness={field.additionalPreparedness}
                    onChange={field.onChange}
                  />
                );
              }
              else if (field.Fieldtype === "textAreaInput") {
                return (
                  <TextAreaInput
                    key={field.id}
                    id={field.id}
                    label={field.label}
                    type={field.type}
                    min={field.min}
                    max={field.max}
                    disabled={field.disabled}
                    value={selectedValues[field.id]} 
                    additionalPreparedness={field.additionalPreparedness}
                    onChange={(e) => {
                      setSelectedValues({
                        ...selectedValues,
                        [field.id]: e.target.value, 
                      });
                    }}
                  />
                );
              }
              return null; // Kihagyhatod a nem támogatott mezőket
            })}

    </div>

               
      <FormTabs 
        //Azonosító, hogy melyik form-hoz adjuk hozzá az elemet
        SimpleTaskId={selectedValues?.SimpleTaskId} 
        disabled={viewField}

        //További felelősökhoz tartozó részek
        memberList={memberList}
        setMemberList={setMemberList}
        newMembers = {newMembers}
        setNewMembers={setNewMembers}

        // Drag and Drop tartozó formrészek
        fileList={fileList}
        setFileList={setFileList}
        newFiles={newFiles}
        setNewFiles={setNewFiles} 

        //Leírás formrészhez szükséges mezők
        formId={"SimpleTaskDescription"}
        value={selectedValues.SimpleTaskDescription}
        onChange={(e) => {
          setSelectedValues({
            ...selectedValues,
            SimpleTaskDescription: e.target.value,
          })
          }} 
      />
                

          {!editButtonVisible && (
            <div className="flex justify-center gap-3">
              <Button
                type="button"
                children="Rögzítés"
                buttonType="add"
                onClick={() => {
                  setViewField(true);
                  setEditButtonvisible(true);
                  updateObject();
                  handleAddTaskMembers(newMembers,0);
                  handleFileUpload(newFiles,selectedValues.companyId);
                  getReports();
                }}
              />

              <Button
                type="button"
                children="Mégse"
                buttonType="delete"
                onClick={() => {
                  setViewField(true);
                  setEditButtonvisible(true);
                  setNewMembers([]);
                  setNewFiles([]);
                }}
              />
            </div>
          )}

          {!addField && (
            <div className="flex justify-center gap-3">
              <Button
                type="button"
                children="Rögzítés"
                buttonType="add"
                onClick={async() => {
                  setViewField(true);
                  setAddField(true);
                  await handleInsertObject();
                  getReports();
             
                }}
              />

              <Button
                type="button"
                children="Mégse"
                buttonType="delete"
                onClick={() => {
                  setViewField(true);
                  setAddField(true);
                  setNewMembers([]);
                  setNewFiles([]);
                }}
              />
            </div>
          )}

          
        </div>
      </form>
    
      </div>
  <DeleteDialog open={showDeleteDialog}  setOpen={()=>setShowDeleteDialog(false)} handleRemoveElement={handleRemoveElement} />
  
      {isLoading ? (
        <p>Betöltés folyamatban...</p> // Betöltési üzenet, amíg az adatok nem érkeznek meg
      ) : (
        <CompanyReports
          reports={reportList}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          viewField={viewField}
        />
      )}
 
</div>
  );
};
