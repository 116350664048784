import { useEffect, useState } from "react";
import axios from "axios";


export default function Checkbox(props) {
  const [list, setList] = useState([]);

  const fetchData = async () => {
    try {
      const requestData = {
        tables: [props.table],
        joins: [],
        conditions: [],
        selectedColumns: ["*"],
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getJoinedData`,
        requestData
      );
      // console.log("checkbox lista",response.data); // Itt kezelheti a választ

      const formattedData = response.data.map((item) => ({
        label: item[props.listType.name],
        value: item[props.listType.id],
      }));
      console.log("checkbox lista", formattedData);
      setList(formattedData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="relative col-span-1 md:col-span-2 lg:col-span-3 basis-64 border-b py-3 xl:col-span-4">
      <span
        htmlFor={props.id}
        className="absolute -top-2 left-2 inline-block max-w-full truncate bg-white px-1 text-xs font-medium text-gray-900"
      >
        {props.label}
      </span>
      <div className="space-y-2">
        {list &&
          list.map((item) => (
            <label key={item.value} className="inline-flex items-center">
              <input
                type="radio"
                id={item.value}
                name={props.id}
                value={item.value}
                disabled={props.disabled}
                checked={item.value === props.value}
                onChange={() => props.onChange(item.value)}
                className="form-radio h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:opacity-50"
              />
              <span className="ml-2 w-28 text-sm text-gray-700">
                {" "}
                {item.label}
              </span>
            </label>
          ))}
      </div>
    </div>
  );
}
