import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

import ProjectTabs from "./ProjectTabs";

import { useState,useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; 
import axios from "axios";

export function AddProjectForm(props) {

    const [projectData, setProjectData] = useState({
        ProjectName: "",
        CompanyId: "",
        WorkerId: "",
        ProjectGroup: "",
        ProjectStart: "",
        ProjectEnd: "",
        ProjectPrepare: "",
        descripion: "",
        StatusId: "",
      });

    const [status,setStatus]=useState([]);
    const [company,setCompany]=useState([]);
    const [worker,setWorker]=useState([]);
    const [btnVisibe,setBtnVisibe]=useState(true);
    const [id,setId]=useState(null)
   
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedEndDate,setSelectedEndDate]= useState("");


    const [projectNameValid,setProjectNameValid]=useState(false);
    const [companyValid,setCompanyValid]=useState(false);
    const [projectGroupValid,setProjectGroupValid]=useState(false);
    const [statusIdValid,setStatusIdValid]=useState(false);
    const [workerIdValid,setWorkerIdValid]=useState(false);

    const formValid= projectNameValid&& companyValid&&projectGroupValid&&statusIdValid&&workerIdValid;

console.log("projects props definition",props.project)

    const [selectedValues, setSelectedValues] = useState({
        Status: "",
        Company: "",
        Worker: "",
      });
    

      const handleAddProject = async () => {
        const projectDataToSend = {
          ...projectData,
          CompanyId: selectedValues.Company || "",
          WorkerId: selectedValues.Worker || "",
          StatusId: selectedValues.Status || "",
          ProjectStart: selectedDate || "",
          ProjectEnd: selectedEndDate || "",
          // További projekt adatok
        };
      
        console.log("projectDataToSend", projectDataToSend);
      
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/addProject`,
            projectDataToSend
          );
      
          console.log("Project added successfully:", response.data);
          setId(response.data.ProjectId)
          // Frissítsd a projektek állapotát, majd hívd meg az onProjectAdded függvényt
          props.setProjects([...props.project, projectDataToSend]);
          setBtnVisibe(false)


          setProjectNameValid(false);
          setProjectGroupValid(false);
          setCompanyValid(false);
          setStatusIdValid(false);
          setWorkerIdValid(false);
          // Close the dialog
             // props.onHide();
          // Handle success
        } catch (error) {
          console.error("Error adding project:", error);
          // Handle error
        }

        props.fetchData();
      
        // További kód a projekt hozzáadásához
      };
    
      
      
      const handleSaveProject = async () => {

        props.onHide();
    
       
        setProjectData({
          ProjectName: "",
          CompanyId: "",
          WorkerId: "",
          ProjectGroup: "",
          ProjectStart: "",
          ProjectEnd: "",
          ProjectPrepare: "",
          descripion: "",
          StatusId: "",
        })

        setSelectedValues({
          Status: "",
        Company: "",
        Worker: "",
        })
    props.fetchData()
    setBtnVisibe(false);
    //setSaveButtonVisible(true);
        // További kód a projekt hozzáadásához
        setId(null);
      }; 
      

    const fetchData = async () => {
        console.log("props",props)
        try {
          const requestBody = {
            table: "status", // itt add meg az adatbázis nevet
            filterColName: "Archive" // itt add meg az oszlop nevet
          };
        
          const response = await fetch(`${process.env.REACT_APP_API_URL}/getList`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(requestBody)
          });
        
          const data = await response.json();
          console.log("status data", data);
          if (response.ok) {
            console.log("status data", data);
            setStatus(data);
          } else {
            console.log("Error response");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
    

      const getCompanies = async () => {
        console.log("props",props)
        try {
          const requestBody = {
            table: "company", // itt add meg az adatbázis nevet
            filterColName: "Archive" // itt add meg az oszlop nevet
          };
        
          const response = await fetch(`${process.env.REACT_APP_API_URL}/getList`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(requestBody)
          });
        
          const data = await response.json();
          console.log("project data", data);
          if (response.ok) {
            console.log("project data", data);
            setCompany(data);
          } else {
            console.log("Error response");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      
      const getWorkers = async () => {
        console.log("props",props)
        try {
          // const docNumString = localStorage.getItem("docNum");
          // const inputNumber =docNumString !== null ? parseInt(docNumString, 10) : 0;
    
          // console.log("downtime input", inputNumber);
          let response = await fetch(
            `${process.env.REACT_APP_API_URL}/getWorker`
          );
          const data = await response.json();
    
          if (response) {
            console.log("Status information data", data);
            setWorker(data);
          }
          else{
            console.log("Error response")
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
    
    
      useEffect(() => {
        fetchData();
        getCompanies();
        getWorkers();

        setProjectNameValid(false);
        setProjectGroupValid(false);
        setCompanyValid(false);
        setStatusIdValid(false);
        setWorkerIdValid(false);
      }, []);



  return (
    <Transition.Root show={props.show} as={Fragment}>
    <Dialog as="div" className="relative z-50" onClose={props.onHide}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </Transition.Child>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 ">
                <div className="">
                  <div className="flex mb-5">

                  <div className=" flex -my-3  h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
</svg>

                  </div>
                  <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 mx-3  text-gray-900"
                    >
                       Projekt hozzáadása 
                    </Dialog.Title>
                  </div>
                 
                  <div className=" text-center sm:ml-4 my-2  sm:text-left">
                  
                
                    <div className="mt-2 flex">
                     



                    <div className="relative basis-64 mx-2 my-2">
    <label
      htmlFor="ProjectName"
      className="absolute -top-2 left-2 z-10 inline-block max-w-full truncate bg-white px-1 text-xs font-medium text-gray-900"
    >
     Név
    </label>
    <div className="relative rounded-md shadow-sm">
    <input
  type="text"
  name="ProjectName"
  id="ProjectName"
  required
  value={projectData.ProjectName}
  onChange={(e) =>{
    setProjectData({ ...projectData, ProjectName: e.target.value });
    setProjectNameValid(e.target.validity.valid);
     }
  }
  className="block w-full rounded-md border-0 py-1.5 pr-8 text-sm leading-6 text-gray-900 ring-1 ring-inset ring-gray-300 [appearance:textfield] placeholder:text-gray-400 invalid:ring-red-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 focus:invalid:ring-red-500 disabled:cursor-not-allowed disabled:opacity-50 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
  aria-describedby="gross-weight-unit"
/>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
        <span
          className="text-sm text-gray-500"
          id="gross-weight-unit"
        >
          
        </span>
      </div>
    </div>
  </div>


                     <div className="relative basis-64 mx-2 my-2">
  <label htmlFor="status-select" className="absolute -top-2 left-2 z-10 inline-block max-w-full truncate bg-white px-1 text-xs font-medium text-gray-900">
    Cég:
  </label>
  <select
    id="status-select"
    name="status"
    required
    className="block w-full rounded-md border-0 py-1.5 pr-8 text-sm leading-6 text-gray-900 ring-1 ring-inset ring-gray-300 [appearance:textfield] placeholder:text-gray-400 invalid:ring-red-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 focus:invalid:ring-red-500 disabled:cursor-not-allowed disabled:opacity-50 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
    onChange={(e) =>{
      setSelectedValues({ ...selectedValues, Company: e.target.value });
      setCompanyValid(e.target.validity.valid);
    }
       
      }
      value={selectedValues.Company}
  >
    <option value=""></option>
    {company && company.length > 0  &&
      company.map((w) => (
        <option key={w.companyId} value={w.companyId}>
          {w.companyName }
        </option>
      ))}
  </select>
</div>
                     


<div className="relative basis-64 mx-2 my-2">
  <label htmlFor="status-select" className="absolute -top-2 left-2 z-10 inline-block max-w-full truncate bg-white px-1 text-xs font-medium text-gray-900">
    Tulajdonos:
  </label>
  <select
    id="status-select"
    name="status"
    required
    className="block w-full rounded-md border-0 py-1.5 pr-8 text-sm leading-6 text-gray-900 ring-1 ring-inset ring-gray-300 [appearance:textfield] placeholder:text-gray-400 invalid:ring-red-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 focus:invalid:ring-red-500 disabled:cursor-not-allowed disabled:opacity-50 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
    onChange={(e) =>{
      setSelectedValues({ ...selectedValues, Worker: e.target.value });
      setWorkerIdValid(e.target.validity.valid);
    }
      
      }
      value={selectedValues.Worker}
  >
    <option value=""></option>
    {worker && worker.length > 0 &&
      worker.map((w) => (
        <option key={w.WorkerId} value={w.WorkerId }>
          {w.WorkerFirstName } {w.WorkerLastName }
        </option>
      ))}
  </select>
</div>

                     


                     
                    <div className="relative basis-64 mx-2 my-2">
    <label
      htmlFor="ProjectGroup"
      className="absolute -top-2 left-2 z-10 inline-block max-w-full truncate bg-white px-1 text-xs font-medium text-gray-900"
    >
     Project Csoport
    </label>
    <div className="relative rounded-md shadow-sm">
    <input
  type="text"
  name="ProjectGroup"
  id="ProjectGroup"
  required
  value={projectData.ProjectGroup}
  onChange={(e) =>{
    setProjectData({ ...projectData, ProjectGroup: e.target.value });
    setProjectGroupValid(e.target.validity.valid)
  }
    
  }
  className="block w-full rounded-md border-0 py-1.5 pr-8 text-sm leading-6 text-gray-900 ring-1 ring-inset ring-gray-300 [appearance:textfield] placeholder:text-gray-400 invalid:ring-red-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 focus:invalid:ring-red-500 disabled:cursor-not-allowed disabled:opacity-50 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
  aria-describedby="gross-weight-unit"
/>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
        <span
          className="text-sm text-gray-500"
          id="gross-weight-unit"
        >
          
        </span>
      </div>
    </div>
  </div>


                  
                    </div>
                    <div className="mt-2 flex">
                   
                    <div className="relative basis-64 mx-2 my-2">
  <label htmlFor="status-select" className="absolute -top-2 left-2 z-10 inline-block max-w-full truncate bg-white px-1 text-xs font-medium text-gray-900">
    Státusz:
  </label>
  <select
    id="status-select"
    name="status"
    required
    className="block w-full rounded-md border-0 py-1.5 pr-8 text-sm leading-6 text-gray-900 ring-1 ring-inset ring-gray-300 [appearance:textfield] placeholder:text-gray-400 invalid:ring-red-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 focus:invalid:ring-red-500 disabled:cursor-not-allowed disabled:opacity-50 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
    onChange={(e) =>{
      setSelectedValues({ ...selectedValues, Status: e.target.value });
      setStatusIdValid(e.target.validity.valid);
    }
      }
      value={selectedValues.Status}
  >
    <option value=""></option>
    {status && status.length > 0  &&
      status.map((w) => (
        <option key={w.StatusId} value={w.StatusId}>
          {w.StatusName}
        </option>
      ))}
  </select>
</div>
                    


<div className="relative basis-64 mx-2 my-2">
  <label
    htmlFor="datepicker"
    className="absolute -top-2 left-2 z-10 inline-block max-w-full truncate bg-white px-1 text-xs font-medium text-gray-900"
  >
    Project kezdete
  </label>
  <div className="relative rounded-md shadow-sm">
    <DatePicker
      selected={selectedDate}
      onChange={(date) => setSelectedDate(date)}
      dateFormat="yyyy-MM-dd"
      className="block w-full rounded-md border-0 py-1.5 pr-8 text-sm leading-6 cursor-pointer text-gray-900 ring-1 ring-inset ring-gray-300 appearance-none placeholder-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
      popperModifiers={{
        offset: {
          enabled: true,
          offset: "10px, 5px", // Custom offset values
        },
      }}
   />
    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 mx-7">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
</svg>
    </div>
  </div>
</div>

                     

<div className="relative basis-64 mx-2 my-2">
  <label
    htmlFor="datepicker"
    className="absolute -top-2 left-2 z-10 inline-block max-w-full truncate bg-white px-1 text-xs font-medium text-gray-900"
  >
    Project vége
  </label>
  <div className="relative rounded-md shadow-sm">
    <DatePicker
      selected={selectedEndDate}
      onChange={(date) => setSelectedEndDate(date)}
      dateFormat="yyyy-MM-dd"
      className="block w-full rounded-md border-0 py-1.5 pr-8 text-sm leading-6 cursor-pointer text-gray-900 ring-1 ring-inset ring-gray-300 appearance-none placeholder-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
      popperModifiers={{
        offset: {
          enabled: true,
          offset: "10px, 5px", // Custom offset values
        },
      }}
   />
    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 mx-7">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
</svg>
    </div>
  </div>
</div>




<div className="relative basis-64 mx-2 my-2">
  <label
    htmlFor="ProjectPrepare"
    className="absolute -top-2 left-2 z-10 inline-block max-w-full truncate bg-white px-1 text-xs font-medium text-gray-900"
  >
    Készültség
  </label>
  <div className="relative rounded-md shadow-sm">
    <input
      type="range"
      name="ProjectPrepare"
      id="ProjectPrepare"
      value={projectData.ProjectPrepare}
      onChange={(e) =>
        setProjectData({ ...projectData, ProjectPrepare: e.target.value })
      }
      min="0"
      max="100"
      step="1"
      className="block w-full rounded-md border-0 py-1.5 pr-8 text-sm leading-6 text-gray-900 ring-1 ring-inset ring-gray-300 [appearance:textfield] placeholder:text-gray-400 invalid:ring-red-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 focus:invalid:ring-red-500 disabled:cursor-not-allowed disabled:opacity-50"
      aria-describedby="gross-weight-unit"
    />
    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
      <span className="text-sm text-gray-500" id="gross-weight-unit">
        {projectData.ProjectPrepare}%
      </span>
    </div>
  </div>
  <div className="mt-2 text-center text-sm text-gray-500">
    {projectData.ProjectPrepare}%
  </div>
</div>
                  
                    </div>

                
                   

                    <div className="relative basis-64 mx-2 my-2">
    <label
      htmlFor="descripion"
      className="absolute -top-2 left-2 z-10 inline-block max-w-full truncate bg-white px-1 text-xs font-medium text-gray-900"
    >
     Leírás
    </label>
    <div className="relative rounded-md shadow-sm">
    <input
  type="text"
  name="descripion"
  id="descripion"
  value={projectData.descripion}
  onChange={(e) =>
    setProjectData({ ...projectData, descripion: e.target.value })
  }
  className="block w-full rounded-md border-0 py-6 pr-8 text-sm leading-6 text-gray-900 ring-1 ring-inset ring-gray-300 [appearance:textfield] placeholder:text-gray-400 invalid:ring-red-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 focus:invalid:ring-red-500 disabled:cursor-not-allowed disabled:opacity-50 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
  aria-describedby="gross-weight-unit"
/>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
        <span
          className="text-sm text-gray-500"
          id="gross-weight-unit"
        >
          
        </span>
      </div>
    </div>
  </div>

  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                disabled={!formValid}
                  type="button"
                  className="inline-flex w-full disabled:cursor-not-allowed disabled:opacity-50 justify-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-400 sm:ml-3 sm:w-auto"
                 onClick={handleAddProject}
                >
                  Rögzítés
                </button>

          
              
              </div>
                     
                  
             
               
                  </div>

      <ProjectTabs  id={id} btnVisible={btnVisibe} setBtnVisible={setBtnVisibe} projectId={props.project.projectId}/>
      
               
                  
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                disabled={btnVisibe}
                  type="button"
                  className="inline-flex w-full justify-center disabled:cursor-not-allowed disabled:opacity-50 rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-400 sm:ml-3 sm:w-auto"
                 onClick={handleSaveProject}
                >
                  Mentés
                </button>

          
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  onClick={props.onHide}
                >
                  Mégse
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
  );
}