
import React, { useState } from "react";
import Modal from "react-modal";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { useEffect } from "react";
const RoleModify = (props) => {
  const [permission, setPermission] = useState(props.hasPermission);


  useEffect(() => {
    console.log("props",props)
    setPermission(props.hasPermission)
  }, [props]);


  const handleChangeRole = async () => {
    console.log("Check the property",props)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/updateSubMenuPermission?RoleId=${props.roleId}&SubMenuId=${props.selectedSubMenu}&HasPermission=${permission}`,
        { method: "PUT" }
      );
      console.log("Role added successfully:", response);
      // Frissítsd a projektek állapotát, majd hívd meg az onProjectAdded függvényt
      //  props.setCompany([...props.company, companyData]);

        console.log("props.RoleSubMenuPermissions és props.selectedSubMenu", props.selectedSubMenu , props.RoleSubMenuPermissions)
      const updatedSubMenuList = props.RoleSubMenuPermissions.map((permission) =>
      permission.SubMenuId === props.selectedSubMenu
        ? { ...permission, hasPermission: permission }
        : permission
    );
    console.log("new list",updatedSubMenuList)
    props.setRoleSubMenuPermissions(updatedSubMenuList);

    props.setRefreshPage(!props.refreshPage)

      // Close the dialog
      props.onHide();
      // Handle success
    } catch (error) {
      console.error("Error adding project:", error);
      // Handle error
    }

    // További kód a projekt hozzáadásához
  };

  console.log("props", props);

  return (
    <Transition.Root show={props.show} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={props.onHide}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="flex mb-5">
                    <div className=" flex -my-3  h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                    </div>
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 mx-3  text-gray-900"
                    >
                      Jogosultság modosítás
                    </Dialog.Title>
                  </div>

                  <div className=" text-center sm:ml-4 my-2  sm:text-left">
                    <div className="mt-2 flex">
                      <div className="relative basis-64 mx-2 my-2">
                        <label
                          htmlFor="companyName"
                          className="absolute -top-2 left-2 z-10 inline-block max-w-full truncate bg-white px-1 text-xs font-medium text-gray-900"
                        >
                          Beállítás jogosultság
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      jogosultság
                    </label>
                    <div className="mt-2">
                      <select
                        value={permission}
                        onChange={(e) => setPermission(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      >
                        <option value={true}>Jogosult</option>
                        <option value={false}>Nem jogosult</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-400 sm:ml-3 sm:w-auto"
                    onClick={handleChangeRole}
                  >
                    Mentés
                  </button>

                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={props.onHide}
                  >
                    Mégse
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default RoleModify;

