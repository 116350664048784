import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import SelectInput from "../ui/SelectInput";
import { useState,useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css"; 
import axios from "axios";
import DeleteDialog from "../ui/DeleteDialog";
export default function AddProjectMember(props){
  
    const [memberData, setMemberData] = useState({
        UserId: "",
        UserName:"",
        RoleName: "",
      });



        
    

      

      const handleAddProject = async () => {
     
        const insertObject = {
          UserId: memberData.UserId,
          RoleName: memberData.RoleName,
          UserName:memberData.UserName,
          SimpleTaskId: props.SimpleTaskId
        };
    {/*
  console.log("insertObject", insertObject);
        try {
          // Az adatok, amiket elküldünk a Node.js szervernek
    
          const tableName = "simple_task_resp_personlist";
    
          // Fetch hívás a Node.js szerver felé
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/addItem`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ tableName, data: insertObject }), // Az adatok JSON formátumban elküldve
            }
          );
    
          if (response.ok) {
            const result = await response.json(); // A szerver válaszának feldolgozása
            console.log("result", result);
            //  setData(result);
          } else {
            console.error("Hiba történt a szerverrel való kommunikáció során.");
          }
        } catch (error) {
          console.error("Hiba történt:", error);
        }
       */}
        console.log("teszt info",props.memberList, memberData)
        props.setMemberList([...props.memberList, insertObject]);

        props.setNewMembers([...props.newMembers,insertObject])
      //  props.setMemberList([...props.member, insertObject]);
      //  props.fetchData();
      props.onHide();
        // További kód a projekt hozzáadásához
      };
    

    return(
         <Transition.Root show={props.show} as={Fragment}>
    <Dialog as="div" className="relative z-50" onClose={props.onHide}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </Transition.Child>

      <div className="fixed inset-0 z-10 overflow-y-auto ">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="relative transform  rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl ">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 ">
                <div className="">
                  <div className="flex mb-5">

                  <div className=" flex -my-3  h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
</svg>

                  </div>
                  <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 mx-3  text-gray-900"
                    >
                       Projekt tag felvitele 
                    </Dialog.Title>
                  </div>
                 
                  <div className=" text-center sm:ml-4 my-2  sm:text-left">
                  
                
                    <div className="mt-2 flex">
                     

                    <SelectInput
                    key={memberData.UserId}
                    onChange={(e) =>
                      setMemberData({...memberData, UserId:e.value, UserName: e.label })
                    }
                    label={"További felelős"}
                    value={memberData.UserId} // Javítva: selectedValues[field.id]
                    disabled={false}
                    id={memberData.UserId}
                    table={"web_user"}
                    listType={{ id: "UserId", name: "UserName" }}
                  />


                   



  <div className="relative basis-64 mx-2 my-2">
    <label
      htmlFor="RoleName"
      className="absolute -top-2 left-2 z-10 inline-block max-w-full truncate bg-white px-1 text-xs font-medium text-gray-900"
    >
     Szerepkör
    </label>
    <div className="relative rounded-md shadow-sm">
    <input
  type="text"
  name="RoleName"
  id="RoleName"
  value={memberData.RoleName}
  onChange={(e) =>
    setMemberData({ ...memberData, RoleName: e.target.value })
  }
  className="block w-full rounded-md border-0 py-1.5 pr-8 text-sm leading-6 text-gray-900 ring-1 ring-inset ring-gray-300 [appearance:textfield] placeholder:text-gray-400 invalid:ring-red-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 focus:invalid:ring-red-500 disabled:cursor-not-allowed disabled:opacity-50 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
  aria-describedby="gross-weight-unit"
/>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
        <span
          className="text-sm text-gray-500"
          id="gross-weight-unit"
        >
          
        </span>
      </div>
    </div>
  </div>



                  
                    </div>
                 
                  </div>


                  
                </div>
              </div>
              <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 justify-bottom">
                <button
                  type="button"
                  className="inline-flex w-full justify-bottom rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-400 sm:ml-3 sm:w-auto"
                 onClick={handleAddProject}
                >
                  Tag hozzáadás
                </button>

          
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-bottom rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  onClick={props.onHide}
                >
                  Mégse
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
    )
}