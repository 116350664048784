
import { useEffect,useState } from "react";
//import { EditProduction } from "./EditProduction";
import ConfirmWindow from "../ui/ConfirmWindow";
//import ResposiblePersonList from "./ResposiblePersonList";
//import { AddProjectForm } from "./AddProjectForm";
export default function ProductionInformation(props) {


    const [showEditDialog,setShowEditDialog]= useState(false);
    const [showDialog,setShowDialog]=useState(false);

    const [Production,setProduction]=useState([]);


    function formatTime(time) {
      const [hour, minute] = time.split(':');
      const formattedHour = hour.length === 1 ? `0${hour}` : hour;
      const formattedMinute = minute.length === 1 ? `0${minute}` : minute;
      return `${formattedHour}:${formattedMinute}`;
    }

    
    const fetchData = async () => {
        console.log("props",props)
        try {
          // const docNumString = localStorage.getItem("docNum");
          // const inputNumber =docNumString !== null ? parseInt(docNumString, 10) : 0;
    
          // console.log("downtime input", inputNumber);
          let response = await fetch(
            `${process.env.REACT_APP_API_URL}/getProductionInformation?ProductionId=${props.ProductionId}`
          );
          const data = await response.json();
    
          if (response) {
           // console.log("Project information data", data);
            setProduction(data[0]);
          }
          else{
            console.log("Error response")
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
    
     // console.log("Production id",props.ProductionId)
    
      useEffect(() => {
        console.log('props.ProductionId useEffect run',props.Production)
      //  console.log("Production id",props.ProductionId)
        fetchData();
      }, [props.ProductionId]);

      useEffect(() => {
        console.log('setProduction useEffect run',props.Production)
        //console.log("Production id",props.ProductionId)
        fetchData();
      }, [setProduction]);

   

    
      return (
        < >
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-4">
              <p className="mt-1 text-sm text-gray-500"></p>
            </div>
            <div className="ml-8 mt-4 flex-shrink-0">

            <div className="flex">
              <div className="px-3">
              <button
                 type="button"
                 onClick={() => {
                   setShowDialog(true);
                 }}
                 className="relative inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
               >
                 Törlés
               </button>
              </div>
             
                 <div>
                 <button
                type="button"
                onClick={() => {
                  setShowEditDialog(true);
                }}
                className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Szerkesztés
              </button>
                 </div>
               </div>

             
            </div>
          </div>
        </div>
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-4">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Feladat azonosítója
              </h3>
              <p className="mt-1 text-sm text-gray-500">
              {Production && Production?.ProductionId}
              </p>
            </div>
            <div className="ml-4 mt-4 flex-shrink-0">
              <div>
                <h3 className="text-base font-semibold leading-6 text-center text-gray-900">
                  Cikknév
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                {Production && Production?.ItemName}
                </p>
              </div>
            </div>

          
          </div>
        </div>


        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
          <div className=" flex flex-wrap items-center justify-between sm:flex-nowrap">
            
          <div>
                <h3 className="text-base font-semibold leading-6 text-center text-gray-900">
                  Cikkszám
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                {Production && Production?.ItemCode}
                </p>
              </div>

              <div>
                <h3 className="text-base font-semibold leading-6 text-center text-gray-900">
                  Gép megnevezése
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                {Production && Production?.MachineName}
                </p>
              </div>
              
          </div>
        </div>

        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
          <div className=" flex flex-wrap items-center justify-between sm:flex-nowrap">
            
          <div>
                <h3 className="text-base font-semibold leading-6 text-center text-gray-900">
                  Tervezett mennyiség
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                {Production && Production?.PlannedQuantity}
                </p>
              </div>

              <div>
                <h3 className="text-base font-semibold leading-6 text-center text-gray-900">
                  Utalványozott idő
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                {Production && Production?.PlannedTime}
                </p>
              </div>



              
          </div>
        </div>


        

        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
          <div className=" flex flex-wrap items-center justify-between sm:flex-nowrap">
            
          <div>
                <h3 className="text-base font-semibold leading-6 text-center text-gray-900">
                  Gyártás státusza
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                {Production && Production.StatusName}
                </p>
              </div>

            

              
              
          </div>
        </div>

        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
          <div className=" flex flex-wrap items-center justify-between sm:flex-nowrap">
            
         

              
          </div>
        </div>
    {/*  <ResposiblePersonList ProductionId={Production?.ProductionId} /> */}  
     {/** <EditProduction ProductionData={Production} show={showEditDialog} onHide={()=>setShowEditDialog(false)} Production={props.Production} setProduction={setProduction} />*/} 
      <ConfirmWindow show={showDialog} onClose={()=>setShowDialog(false)} tableName={"Production"} filterCol={"ProductionId"} itemName={Production?.ProductionName} statusId={props.ProductionId} fetchData={props.fetchData}/>
    
      </>
      );
}