import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";


export function AddRole(props) {
  
  const [Role,setRole]=useState([]);




  const [RoleData, setRoleData] = useState({
    RoleName: "",
  });


  
  const getRoles = async () => {
    console.log("props",props)
    try {
      // const docNumString = localStorage.getItem("docNum");
      // const inputNumber =docNumString !== null ? parseInt(docNumString, 10) : 0;

      // console.log("downtime input", inputNumber);
      let response = await fetch(
        `${process.env.REACT_APP_API_URL}/getRole`
      );
      const data = await response.json();

      if (response) {
        console.log("Role information data", data);
        setRole(data);
      }
      else{
        console.log("Error response")
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  useEffect(() => {
    getRoles();
    
  }, []);

  const handleAddProject = async () => {

 

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/addRole`,
        RoleData
      );

      console.log("Project added successfully:", response.data);
      // Frissítsd a projektek állapotát, majd hívd meg az onProjectAdded függvényt
      props.setRole([...props.Role, RoleData]);

      // Close the dialog
      props.onHide();
      // Handle success
    } catch (error) {
      console.error("Error adding project:", error);
      // Handle error
    }

    props.fetchData()

    // További kód a projekt hozzáadásához
  };

  return (
    <Transition.Root show={props.show} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={props.onHide}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 ">
                  <div className="">
                    <div className="flex mb-5">
                      <div className=" flex -my-3  h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12.75l6 6 9-13.5"
                          />
                        </svg>
                      </div>
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 mx-3  text-gray-900"
                      >
                        Jogosultság hozzáadása
                      </Dialog.Title>
                    </div>

                    <div className=" text-center sm:ml-4 my-2  sm:text-left">
                      <div className="mt-2 flex">
                        <div className="relative basis-64 mx-2 my-2">
                          <label
                            htmlFor="RoleName"
                            className="absolute -top-2 left-2 z-10 inline-block max-w-full truncate bg-white px-1 text-xs font-medium text-gray-900"
                          >
                            Jogosultság neve
                          </label>
                          <div className="relative rounded-md shadow-sm">
                            <input
                              type="text"
                              name="RoleName"
                              id="RoleName"
                              value={RoleData.RoleName}
                              onChange={(e) =>
                                setRoleData({
                                  ...RoleData,
                                  RoleName: e.target.value,
                                })
                              }
                              className="block w-full rounded-md border-0 py-1.5 pr-8 text-sm leading-6 text-gray-900 ring-1 ring-inset ring-gray-300 [appearance:textfield] placeholder:text-gray-400 invalid:ring-red-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 focus:invalid:ring-red-500 disabled:cursor-not-allowed disabled:opacity-50 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                              aria-describedby="gross-weight-unit"
                            />
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                              <span
                                className="text-sm text-gray-500"
                                id="gross-weight-unit"
                              ></span>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-400 sm:ml-3 sm:w-auto"
                    onClick={handleAddProject}
                  >
                    Mentés
                  </button>

                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={props.onHide}
                  >
                    Mégse
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
